import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { updateCartList } from "./cart.action";
import { handleFetchError } from "../../util/errorHandler";
import { sameSizeItemsFormat } from "@/util/cart";
import {
  GET_WISHLISTS,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_PAGINATION,
  EMPTY_WISHLIST_CART,
  SELECTED_SIZE
} from "../constants";
import WishlistService, {
  WishlistData,
  WishlistProductBaseData
} from "../../services/wishlistService";

import { AppDispatch } from "../store";

export const getWishList = wishList => ({
  type: GET_WISHLISTS,
  wishList
});

export const setWishListPagination = pagination => ({
  type: SET_WISHLIST_PAGINATION,
  pagination
});

export const setWishListLength = length => ({
  type: SET_WISHLIST_LENGTH,
  length
});

export const emptyWishListAndCart = () => ({ type: EMPTY_WISHLIST_CART });

export const updateWishlistItemSize = ({
  sizeValue,
  productId,
  sizeId,
  sizeType,
  _id,
  sizeTypeKey,
  isMultisize
}: {
  sizeValue: string;
  productId: number;
  sizeId: number;
  sizeType: string;
  _id: string;
  sizeTypeKey: string;
  isMultisize: boolean;
}) => ({
  type: SELECTED_SIZE,
  selectedSizeValue: sizeValue,
  productId,
  sizeId,
  sizeType,
  _id,
  sizeTypeKey,
  isMultisize
});

export const deleteWishListData =
  ({
    productId,
    wishlistItemId,
    page
  }: {
    productId: number;
    wishlistItemId: string;
    page?: number;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      const wishlistData = await WishlistService.removeProductFromWishlist({
        productId,
        wishlistItemId,
        page
      });

      const isPaginationFlow = page !== undefined;
      if (isPaginationFlow)
        dispatch(setWishlistData(wishlistData as WishlistData));

      return wishlistData;
    } catch (error) {
      console.error(error);

      return error;
    }
  };

export const fetchWishlistData =
  ({ page }: { page?: number }) =>
  async (dispatch: AppDispatch) => {
    dispatch(showLoadingIndicator());

    try {
      const wishlistData = await WishlistService.getWishlist({ page });

      dispatch(setWishlistData(wishlistData));

      return wishlistData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const setWishlistData =
  (wishlistData: WishlistData) => (dispatch: AppDispatch) => {
    const { totalProducts, ...pagination } = wishlistData.pagination;

    dispatch(getWishList(wishlistData.wishlist));
    dispatch(setWishListPagination(pagination));
    dispatch(setWishListLength(totalProducts));
  };

export const saveToWishlist =
  ({
    data,
    page,
    loader = true
  }: {
    data: {
      productId: number;
      size: string;
      sizeId: number;
      sizeType: string | number;
      _id?: string;
      sizeTypeKey?: string;
      isMultisize: boolean;
    };
    page?: number;
    loader?: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    if (loader) dispatch(showLoadingIndicator());

    try {
      const wishlistData = await WishlistService.saveToWishlist(data, { page });

      const isPaginationFlow = page !== undefined;
      if (isPaginationFlow)
        dispatch(setWishlistData(wishlistData as WishlistData));
      return wishlistData;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      if (loader) dispatch(hideLoadingIndicator());
    }
  };

export const saveItemToWishlist =
  ({
    cartId,
    page,
    loader = true
  }: {
    cartId: string;
    page?: number;
    loader?: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    if (loader) dispatch(showLoadingIndicator());

    try {
      const wishlistData = await WishlistService.saveItemToWishlist(cartId, {
        page
      });

      const isPaginationFlow = page !== undefined;
      if (isPaginationFlow)
        dispatch(setWishlistData(wishlistData as WishlistData));
      return wishlistData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error;
    } finally {
      if (loader) dispatch(hideLoadingIndicator());
    }
  };

export const moveFromWishlistToCart =
  (wishlistProduct: WishlistProductBaseData) =>
  async (dispatch: AppDispatch, getState) => {
    const sizeFormattedItem = sameSizeItemsFormat(
      wishlistProduct,
      getState().cartReducer.cart
    );
    const wishlistData = await WishlistService.moveToCart(wishlistProduct);
    if (wishlistData) {
      dispatch(updateCartList({ ...sizeFormattedItem, isSelected: true }));
    }

    const isPaginationFlow = wishlistProduct.page !== undefined;
    if (isPaginationFlow)
      dispatch(setWishlistData(wishlistData as WishlistData));

    return wishlistData;
  };
