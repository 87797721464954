import {
  HOME_PAGE_SECTION_DATA,
  SET_STORE_LIST,
  SET_ANALYTICS_DATA,
  SET_PAGE_TYPE
} from "../../constants/page";

type AnalyticData = {
  variants: any[];
  analytics: any | null;
};

type HomepageState = {
  storeId: string;
  countryId: string;
  sections: any[];
};

type PageState = {
  homepageState: HomepageState;
  storeList: any[];
  analyticData: AnalyticData;
  pageType: string;
};

const initialState: PageState = {
  homepageState: {
    storeId: "",
    countryId: "",
    sections: []
  },
  storeList: [],
  analyticData: {
    variants: [],
    analytics: null
  },
  pageType: ""
};

const homePageSectionData = (state: PageState, { sectionName, content }) => ({
  ...state,
  homepageState: {
    ...state.homepageState,
    countryId: content.countryId,
    storeId: content.storeId,
    [sectionName]: content[sectionName]
  }
});

const setStoreList = (state: PageState, { storeList }) => ({
  ...state,
  storeList
});

const setAnalyticsData = (state: PageState, { analyticData }) => ({
  ...state,
  analyticData
});

const setPageType = (state: PageState, { pageType }) => ({
  ...state,
  pageType
});

const pageReducer = (state: PageState = initialState, action): PageState => {
  switch (action.type) {
    case HOME_PAGE_SECTION_DATA:
      return homePageSectionData(state, action);
    case SET_STORE_LIST:
      return setStoreList(state, action);
    case SET_ANALYTICS_DATA:
      return setAnalyticsData(state, action);
    case SET_PAGE_TYPE:
      return setPageType(state, action);

    default:
      return state;
  }
};

export default pageReducer;
