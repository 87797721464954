import React, { FC } from "react";

const BounceLoader: FC<{
  qty: number;
  size: number;
  color: string;
}> = ({ qty, size, color }) => (
  <div className="bounce">
    {Array.from({ length: qty }, (_, i) => (
      <div
        className={`bounce${i}`}
        style={{ width: size, height: size, backgroundColor: color }}
        key={i}
      />
    ))}
  </div>
);

export default BounceLoader;
