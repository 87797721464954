import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "clsx";
import { isMobile } from "@/util";
import { getRedirectionUrl, isLinkStartsFromHTTPS } from "@/util/common";
import BannerIntersectionObserver from "../../banner/bannerIntersectionObserver";
import loaderIcon from "../../../images/loading-icon.svg";
import "./style.scss";

const stylesForVideo = (
  { hover, ...restStyles },
  { hovered, imageWidth, marginForImage, hoverValues }
) => ({
  ...restStyles,
  ...(!isMobile.any() && hovered && hoverValues && hover),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: `0 0 calc(${imageWidth}% - ${marginForImage}px)`
});

const DynamicVideo = ({
  data,
  imageWidth = 100,
  marginForImage = 0,
  styles,
  redirectionPath,
  linkTarget,
  index,
  dataForAnalytic
}) => {
  const [videoLoading, setVideoLoading] = useState(true);
  const videoWrapperRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const hoverValues = styles.hoverValues?.transform;

  return (
    <div
      style={stylesForVideo(styles, {
        hovered,
        imageWidth,
        marginForImage,
        hoverValues
      })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="video-wrapper">
        <div
          style={{
            position: "relative",
            paddingBottom: `${data.ratio}%`
          }}
        >
          <Link
            className={cn("video-container", {
              "disabled-link": !data.redirectionLink
            })}
            to={getRedirectionUrl(
              isLinkStartsFromHTTPS(redirectionPath),
              redirectionPath
            )}
            target={linkTarget}
            ref={videoWrapperRef}
            data-banner-link={data.redirectionLink}
            data-banner-index={index}
          >
            {data.videoUrl && videoLoading && (
              <img src={loaderIcon} alt="loader-icon" className="loader-icon" />
            )}
            <BannerIntersectionObserver data={dataForAnalytic}>
              <video
                autoPlay
                loop
                muted
                playsInline
                width="100%"
                onLoadedData={() => {
                  setVideoLoading(false);
                  videoWrapperRef.current.style.background = "none";
                }}
              >
                <source src={data.videoUrl} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </BannerIntersectionObserver>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DynamicVideo;
