import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  STORE_TOKEN,
  SAVE_USER_DETAILS,
  SET_ADDRESS_BOOK,
  SET_DEFAULT_ADDRESS,
  SHOW_PREVIEW,
  SET_PREV_PATH,
  STORE_TOKEN_CHECKOUT_GUEST,
  RESET_TOKEN_CHECKOUT_GUEST,
  SET_EMAIL
} from "../constants";

type AuthState = {
  userName: string;
  customerId: string;
  userLoggedIn: boolean;
  accessToken: string;
  accessGuestToken: string;
  addressBook: any[];
  showPreview: boolean;
  BFLUser: boolean;
  email: string;
  prevPath?: string;
  countryId?: string;
  firstname: string;
  lastname?: string;
  isMobileVerified?: boolean;
  phone?: string;
};

const initialState: AuthState = {
  userName: "",
  firstname: "",
  customerId: "",
  userLoggedIn: false,
  accessToken: "",
  accessGuestToken: "",
  addressBook: [],
  showPreview: false,
  BFLUser: false,
  email: ""
};

const saveUserDetails = (state: AuthState, { data }) => ({
  ...state,
  userName: data.message,
  accessToken: data.token || data.accessToken,
  customerId: data.customerId,
  userLoggedIn: true,
  prevPath: "",
  BFLUser: data.BFLUser
});

const storeToken = (state: AuthState, { token }) => ({
  ...state,
  accessToken: token,
  userLoggedIn: true
});
const storeTokenCheckoutGuest = (state: AuthState, { token }) => ({
  ...state,
  accessGuestToken: token,
  userLoggedIn: false
});
const resetTokenCheckoutGuest = (state: AuthState) => ({
  ...state,
  accessGuestToken: ""
});

const setPreviewMode = (state: AuthState, { value }) => ({
  ...state,
  showPreview: value
});

const setUserInfo = (
  state: AuthState,
  { userProfile: { fname, ...rest } }
) => ({
  ...state,
  userName: fname,
  ...rest
});

const setAddressBook = (state: AuthState, { addressBook = [] }) => ({
  ...state,
  addressBook
});

const setPrevPath = (state: AuthState, { prevPath }) => ({
  ...state,
  prevPath
});

const setDefaultAddress = (state: AuthState, { name, value, index }) => ({
  ...state,
  addressBook: [
    ...state.addressBook.slice(0, index),
    {
      ...state.addressBook[index],
      [name]: value
    },
    ...state.addressBook.slice(index + 1)
  ]
});

const setEmail = (state: AuthState, action) => ({
  ...state,
  email: action.payload
});

const userProfileReducer = (
  state: AuthState = initialState,
  action
): AuthState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return saveUserDetails(state, action);
    case STORE_TOKEN:
      return storeToken(state, action);
    case STORE_TOKEN_CHECKOUT_GUEST:
      return storeTokenCheckoutGuest(state, action);
    case RESET_TOKEN_CHECKOUT_GUEST:
      return resetTokenCheckoutGuest(state);
    case SAVE_USER_DETAILS:
      return setUserInfo(state, action);
    case SET_ADDRESS_BOOK:
      return setAddressBook(state, action);
    case SET_DEFAULT_ADDRESS:
      return setDefaultAddress(state, action);
    case SHOW_PREVIEW:
      return setPreviewMode(state, action);
    case SET_PREV_PATH:
      return setPrevPath(state, action);
    case SET_EMAIL:
      return setEmail(state, action);
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default userProfileReducer;
