import React, { CSSProperties, FC, useEffect, useState } from "react";
import cn from "clsx";
import moment from "moment";
import CustomCountdown from "@/components/customCountdown";
import CountdownBanner from "./countdownBanner";
import { useLanguage, useLongLanguage } from "@/hooks";
import { CountDownContentType } from "../types";

type CountdownWrapperProps = {
  content: CountDownContentType;
  imageListLength: number;
  removeCountdown: (id: string) => void;
};

const CountdownWrapper: FC<CountdownWrapperProps> = ({
  content,
  imageListLength,
  removeCountdown
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const language = useLongLanguage();
  const languageShort = useLanguage();
  const { styles, date, styleType } = content;
  const countdownStyles = {
    ...styles,
    width: imageListLength <= 2 ? `${100 / imageListLength}%` : "50%",
    justifyContent: content.countdownOption
  };
  const isAfterCountdown = moment().isAfter(content.date);
  const currentImageContent = isAfterCountdown
    ? content.afterCountDown
    : content.beforeCountDown;
  const absoluteStyles = currentImageContent[language]?.absoluteStyles;
  const hasAbsoluteStyles = absoluteStyles?.top > 0 || absoluteStyles?.left > 0;
  const hasImageData = currentImageContent?.[language]?.imageURL;

  const countdownHeading = content[languageShort];

  const absoluteStylesProperties: CSSProperties = hasAbsoluteStyles && {
    position: "absolute",
    zIndex: 100,
    top: `${absoluteStyles.top}%`,
    left: `${absoluteStyles.left}%`
  };

  const headingStyles: CSSProperties = content.headingColor && {
    color: content.headingColor
  };

  useEffect(() => {
    const countdownOver = moment().isAfter(content.date);
    setIsCountdownOver(countdownOver);
  }, []);

  const onCountdownComplete = () => {
    if (imageListLength !== 1) {
      removeCountdown(content.id);
    }
    setIsCountdownOver(true);
  };

  const countdownContent = hasAbsoluteStyles ? (
    <div style={absoluteStylesProperties}>
      <div
        className={cn("countdown-heading-container", {
          [countdownHeading?.headingPosition]:
            !!countdownHeading?.headingPosition
        })}
      >
        {countdownHeading?.heading && !isCountdownOver && (
          <p className="countdown-heading" style={headingStyles}>
            {countdownHeading.heading}
          </p>
        )}
        <CustomCountdown
          date={date}
          type={styleType}
          onCountdownComplete={onCountdownComplete}
          content={content}
        />
      </div>
    </div>
  ) : (
    <div
      className={cn("countdown-heading-container", {
        [countdownHeading?.headingPosition]: !!countdownHeading?.headingPosition
      })}
    >
      {countdownHeading?.heading && !isCountdownOver && (
        <p className="countdown-heading" style={headingStyles}>
          {countdownHeading.heading}
        </p>
      )}
      <CustomCountdown
        date={date}
        type={styleType}
        onCountdownComplete={onCountdownComplete}
        content={content}
      />
    </div>
  );

  return (
    <div
      className="catalog_banner_section"
      style={countdownStyles}
      itemID={`countdown-${content.id}`}
    >
      <div
        id="bfl-countdown"
        className="bfl-countdown"
        data-date={date}
        data-type="0"
      >
        {imageLoading && hasImageData ? null : countdownContent}
        <CountdownBanner
          content={content}
          isCountdownOver={isCountdownOver}
          setImageLoading={setImageLoading}
          imageLoading={imageLoading}
        />
      </div>
    </div>
  );
};

export default CountdownWrapper;
