import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import cn from "clsx";
import "./style.scss";

type BreadcrumbProps = {
  id: string;
  name: string;
  onClick?: () => void;
  url?: string;
};

type BreadcrumbListProps = {
  breadcrumbs: BreadcrumbProps[];
  delimiter?: React.ReactNode;
  SwiperComponent?: typeof Fragment;
  swiperParams?: {};
};

const BreadcrumbList: FC<BreadcrumbListProps> = ({
  breadcrumbs,
  delimiter = null,
  SwiperComponent = Fragment,
  swiperParams = null
}) => (
  <ul className="breadcrumb-list">
    <SwiperComponent {...swiperParams}>
      {breadcrumbs?.map((breadcrumb, index) => {
        const notClickable = index === breadcrumbs.length - 1;
        return (
          <li key={breadcrumb.id || breadcrumb.name} className="content">
            {!!index && delimiter}
            <Link
              to={breadcrumb.url}
              className={cn("breadcrumb-item-content", {
                "no-click": notClickable
              })}
              {...(!notClickable && {
                onClick: breadcrumb.onClick
              })}
            >
              {breadcrumb.name}
            </Link>
          </li>
        );
      })}
    </SwiperComponent>
  </ul>
);

export default BreadcrumbList;
