import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import cn from "clsx";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { isArabic } from "@/util";
import { Product } from "@/services/productService";
import PlpAnalytics from "@/services/analytics/main/plp";
import { LANGUAGE_SHORT } from "@/constants";
import QuicklookModal from "@/components/quicklookModal";
import ListItem from "../listItem";
import { useLongLanguage } from "@/hooks";
import { Impressions, MetaData } from "../types";
import "./style.scss";

const ListItemWrapper: FC<{
  products: Product[];
  listHeading?: string;
  meta: MetaData;
}> = ({ products, listHeading, meta }) => {
  const [productImpressions, setProductImpressions] = useState<Impressions>({});
  const [recordsPassed, setRecordsPassed] = useState(0);
  const language = useLongLanguage();
  const listingRef = useRef(null);
  const [showQuicklookModal, setShowQuicklookModal] = useState(false);
  const [selectedQuicklookIndex, setSelectedQuicklookIndex] = useState<
    null | number
  >(null);

  const toggleQuicklook = (
    toggleState: boolean,
    selectedIndex: number | null,
    event?: MouseEvent
  ) => {
    setSelectedQuicklookIndex(selectedIndex);
    setShowQuicklookModal(toggleState);

    if (event) event.stopPropagation();
  };

  const handelSwiperButtonClick = (value: any) => {
    setSelectedQuicklookIndex(value);
    if (products[value]) {
      PlpAnalytics.select({
        item: { ...products[value], position: value },
        listHeading
      });
    }
  };

  useEffect(() => {
    const handleListingObservation = debounce(() => {
      const listRef = listingRef.current;
      if (!isEmpty(products) && listRef) {
        const { width: containerWidth } = listRef.getBoundingClientRect();
        const { width: elementWidth, height: elementHeight } =
          listRef.children[0].getBoundingClientRect();
        const itemPerRow = Math.floor(containerWidth / elementWidth);

        const _scrolledItem =
          Math.floor(window.pageYOffset / elementHeight) * itemPerRow;

        const startIndex = _scrolledItem || 0;
        const lastIndex = _scrolledItem + itemPerRow - 1 || 0;
        if (lastIndex < products.length && startIndex <= recordsPassed) {
          let newImpressions: Impressions = {};
          for (let i = startIndex; i <= lastIndex; i++) {
            if (
              products[i] &&
              products[i].id &&
              !productImpressions.hasOwnProperty(products[i].id)
            ) {
              newImpressions[products[i].id] = {
                ...products[i],
                position: i + 1
              };
            }
          }
          if (
            !isEmpty(newImpressions) &&
            !isEqual(productImpressions, newImpressions)
          ) {
            const impressionsByIndex = Object.values(newImpressions).toSorted(
              (a, b) => a.position - b.position
            );
            PlpAnalytics.impressions({
              items: impressionsByIndex,
              listHeading: meta?.heading1?.defaultValue
            });

            setProductImpressions(newImpressions);
          }
        }
        setRecordsPassed(
          _scrolledItem + itemPerRow > products.length
            ? products.length
            : _scrolledItem + itemPerRow
        );
      }
    }, 200);
    window.addEventListener("scroll", handleListingObservation);

    return () => {
      window.removeEventListener("scroll", handleListingObservation);
    };
  }, [products, productImpressions, recordsPassed]);

  const productItemList = useMemo(
    () =>
      products?.map((item: Product, index: number) => (
        <ListItem
          item={item}
          key={`list-item-${index}`}
          position={index}
          listHeading={listHeading}
          toggleQuicklook={toggleQuicklook}
        />
      )),
    [products]
  );

  return (
    <div className="container">
      <ul
        className={cn("list_item_wrapper", {
          arabic: isArabic(language)
        })}
        ref={listingRef}
      >
        {productItemList}
      </ul>
      {showQuicklookModal && (
        <QuicklookModal
          showQuicklookModal={showQuicklookModal}
          toggleQuicklook={toggleQuicklook}
          selectedIndex={selectedQuicklookIndex}
          products={products}
          handelSwiperButtonClick={handelSwiperButtonClick}
        />
      )}
    </div>
  );
};

export default ListItemWrapper;
