export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";
export const CHANGE_CHECKOUT_DATA = "CHANGE_CHECKOUT_DATA";
export const CLEAR_CHECKOUT_DATA = "CLEAR_CHECKOUT_DATA";
export const SET_ADDRESS_DATA = "SET_ADDRESS_DATA";
export const REMOVE_APPLIED_VOUCHER = "REMOVE_APPLIED_VOUCHER";
export const CLEAR_GUEST_EMAIL = "CLEAR_GUEST_EMAIL";
export const SET_CHECKOUT_ORDER = "SET_CHECKOUT_ORDER";
export const POPULATE_VOUCHER_CODES = "POPULATE_VOUCHER_CODES";
export const CHANGE_MAP_ADDRESS_PHONE = "CHANGE_MAP_ADDRESS_PHONE";
export const SET_STANDARD_DELIVERY_ADDRESS = "SET_STANDARD_DELIVERY_ADDRESS";
export const SET_MAP_DELIVERY_ADDRESS = "SET_MAP_DELIVERY_ADDRESS";
export const RESET_CARD_LIST = "RESET_CARD_LIST";
export const SET_CHECKOUT_REQUEST_DATA = "SET_CHECKOUT_REQUEST_DATA";
export const SET_INSTORE_DELIVERY_ADDRESS = "SET_INSTORE_DELIVERY_ADDRESS";

export const SET_CHECKOUT_COM_FORM_VALIDATION_STATUS =
  "checkout/SET_CHECKOUT_COM_FORM_VALIDATION_STATUS";
export const SET_CREDIT_CARDS = "checkout/SET_CREDIT_CARDS";
export const RESET_CREDIT_CARDS = "checkout/RESET_CREDIT_CARDS";
export const SET_SELECTED_CARD_CVV = "checkout/SET_SELECTED_CARD_CVV";
export const CLEAR_SELECTED_CARD_CVV = "checkout/CLEAR_SELECTED_CARD_CVV";
export const SET_BIN_CODE = "SET_BIN_CODE";
export const CLEAR_BIN_CODE = "CLEAR_BIN_CODE";
export const SHOW_CHECKOUT_SUMMARY_LOADER = "SHOW_CHECKOUT_SUMMARY_LOADER";
export const HIDE_CHECKOUT_SUMMARY_LOADER = "HIDE_CHECKOUT_SUMMARY_LOADER";
export const SET_SUMMARY_RECALCULATION_IN_PROGRESS =
  "SET_SUMMARY_RECALCULATION_IN_PROGRESS";
export const SET_PAYMENT_STATUS_RESPONSE = "SET_PAYMENT_STATUS_RESPONSE";
