import { useCallback, useRef } from "react";

const useBodyScrollability = () => {
  const styleNodeRef = useRef<HTMLStyleElement | null>(null);

  const disableBodyScroll = useCallback(() => {
    if (styleNodeRef.current) return;

    const node = document.createElement("style");
    node.setAttribute("type", "text/css");
    node.textContent =
      "html, body { height: 100% ! important ; overflow: hidden ! important ; }";

    styleNodeRef.current = node;
    document.head.prepend(node);
  }, []);

  const enableBodyScroll = useCallback(() => {
    if (styleNodeRef.current && document.head.contains(styleNodeRef.current)) {
      document.head.removeChild(styleNodeRef.current);
      styleNodeRef.current = null;
    }
  }, []);

  return { disableBodyScroll, enableBodyScroll };
};

export default useBodyScrollability;
