import {
  SET_LISA_MINIMIZED,
  SET_LISA_PLAYER_IS_ACTIVE
} from "../constants/lisa";

type LisaState = {
  minimized: boolean;
  library: any | null;
  playerIsActive: boolean;
};

const initialState: LisaState = {
  minimized: false,
  library: null,
  playerIsActive: false
};

const setLisaMinimized = (state: LisaState, action) => ({
  ...state,
  minimized: action.payload
});

const setLisaPlayerIsActive = (state: LisaState, action) => ({
  ...state,
  playerIsActive: action.payload
});

const lisaReducer = (state: LisaState = initialState, action): LisaState => {
  switch (action.type) {
    case SET_LISA_MINIMIZED:
      return setLisaMinimized(state, action);
    case SET_LISA_PLAYER_IS_ACTIVE:
      return setLisaPlayerIsActive(state, action);
    default:
      return state;
  }
};

export default lisaReducer;
