import qs from "query-string";

import { getPrevModeStorage } from "../util/browserStorage";
import { BEConfig } from "../config/env";

import ApiService from "./apiService";

import { webapiPost } from "../webapis/core";
import { fetchWebApi } from "../webapis/apiResource";

class CatalogService extends ApiService {
  async getPageData(
    { url, countryId, language, storeId, limit, cookies }: any,
    config: any = {}
  ) {
    const prevMode = getPrevModeStorage(cookies);
    const additionalQueriesForScheduler = prevMode?.replace("true", "") || "";
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.getPageHandle}`;

    const response = await webapiPost(
      this.getAccessToken(cookies),
      `${baseUrl}?${qs.stringify({
        countryId,
        language,
        storeId,
        limit
      })}${additionalQueriesForScheduler}`,
      {
        url
      },
      this.getVisitorId(),
      null,
      null,
      prevMode,
      config.ipcountry
    ).request;

    return response.data;
  }

  async getCarouselData(
    { url, countryId, language, storeId, limit, cookies, isFlashSale }: any,
    config: any = {}
  ) {
    const prevMode = getPrevModeStorage(cookies);
    const additionalQueriesForScheduler = prevMode?.replace("true", "") || "";
    const baseUrl = `${this.getBaseUrl()}${
      BEConfig.catalogApi.productsCarousel
    }`;

    const response = await webapiPost(
      this.getAccessToken(cookies),
      `${baseUrl}?${qs.stringify({
        countryId,
        language,
        storeId,
        limit
      })}${additionalQueriesForScheduler}`,
      {
        ...(isFlashSale ? { flashSaleSlug: url } : { url })
      },
      this.getVisitorId(),
      null,
      null,
      prevMode,
      config.ipcountry
    ).request;
    return response.data;
  }

  async getSaleCarouselData({ slug, dataType, countryId, language, storeId }) {
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.getPageHandle}`;

    const response = await webapiPost(
      this.getAccessToken(),
      `${baseUrl}?${qs.stringify({
        countryId,
        language,
        storeId
      })}`,
      {
        slug,
        dataType
      },
      this.getVisitorId()
    ).request;
    return response.data;
  }

  async getFooterContent() {
    const response = await fetchWebApi(
      null,
      `${this.getBaseUrl()}${BEConfig.catalogApi.footerHandle}/v2`, // TODO: remove "/v2" after changes in api
      null,
      null,
      null,
      null,
      null,
      getPrevModeStorage()
    );

    return response.data;
  }

  getBaseUrl() {
    const { protocol, baseURL, port, versionInfo } = BEConfig.catalogApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new CatalogService();

export default instance;
