import React, { memo, useState } from "react";
import loaderIcon from "@/images/loading-icon.svg";

const BannerImageComponent = ({
  imageURL,
  onImageLoad,
  loader = <img src={loaderIcon} alt="loader-icon" className="loader-icon" />
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <>
      {imageURL && imageLoading && loader}
      <div className="lazy-load-wrapper">
        <img
          src={imageURL}
          alt="banner"
          className="banner-image"
          onLoad={() => {
            onImageLoad && onImageLoad();
            setImageLoading(false);
          }}
        />
      </div>
    </>
  );
};

export default memo(BannerImageComponent);
