import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import map from "lodash/map";
import { useAppSelector } from "@/redux/store";
import DynamicBannersWrapper from "@/components/dynamic/dynamicBanner";

const FooterWrapResp = ({ hideInMobile, footerSpace }) => {
  const { t } = useTranslation("footer");
  const footerContent = useAppSelector(state => state.footerReducer.content);
  const [isToggle, setIsToggle] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsToggle(!isToggle);
    setTimeout(() => {
      element?.current?.scrollIntoView({ behavior: "smooth" });
    }, 780);
  };

  return (
    <div
      className={cn("footer_wrap_m", {
        footer_open: isToggle,
        footerSpace: footerSpace,
        "d-none": hideInMobile
      })}
      ref={element}
    >
      <h4 className="main_wrap_head" onClick={handleToggle}>
        {t("moreAboutBrandsForLess")}
      </h4>
      <div className="footer_wrap_content">
        {map(footerContent, (section, key) => (
          <DynamicBannersWrapper
            section={section}
            key={key}
            bannerPageTitle={section.sectionTitle}
          />
        ))}
      </div>
    </div>
  );
};

export default FooterWrapResp;
