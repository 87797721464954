import React, { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { AnalyticService } from "@/services";
import { getDeviceType } from "@/util";
import {
  selectAuthReducer,
  selectDeviceType,
  selectPageAnalitic
} from "@/util/selectors";
import { useLongLanguage } from "@/hooks";

const BannerClickAnalyticHandler: FC<{
  bannerModuleName: string;
  rowModuleName: string;
  countrySlug: string;
  pageName: string;
  pageUrl: string;
  children: ReactNode;
}> = ({
  bannerModuleName,
  rowModuleName,
  countrySlug,
  pageName,
  pageUrl,
  children
}) => {
  const deviceTypeSSR = useSelector(state => selectDeviceType(state));
  const { userLoggedIn, customerId } = useSelector(selectAuthReducer);
  const analyticData = useSelector(selectPageAnalitic);
  const deviceType = deviceTypeSSR || getDeviceType();
  const language = useLongLanguage();

  const onBannerClick = e => {
    const nearestLinkElement = e.target.closest("a");
    const dataset = nearestLinkElement && nearestLinkElement.dataset;
    const redirectionLink = dataset?.bannerLink;
    const index = dataset?.bannerIndex;
    const bannerUrl = e.target.currentSrc;

    if (redirectionLink) {
      if (analyticData?.analytics?.variant) {
        AnalyticService.banner.pushStormlyBannerClick(
          {
            rowModuleName: rowModuleName?.toUpperCase(),
            index: +index + 1,
            bannerModuleName: bannerModuleName?.toUpperCase(),
            country: countrySlug.toUpperCase(),
            pageName,
            imageUrl: bannerUrl,
            device: deviceType?.toUpperCase(),
            language,
            pageUrl
          },
          analyticData.analytics.variant,
          userLoggedIn && customerId
        );
      }
    }
  };

  return <div onClick={onBannerClick}>{children}</div>;
};

export default BannerClickAnalyticHandler;
