import React, { FC, Fragment, HTMLAttributes } from "react";
import Countdown from "react-countdown";
import "./style.scss";
import {
  CountDownContentType,
  CountdownType
} from "../dynamic/dynamicSingleBanner/types";

type CustomCountdownProps = {
  date: string | number | Date;
  type: CountdownType;
  onCountdownComplete?: () => void;
  content: CountDownContentType;
  children?: string;
  textStyles?: HTMLAttributes<HTMLSpanElement> | React.CSSProperties;
};

const CustomCountdown: FC<CustomCountdownProps> = ({
  date,
  type,
  children,
  onCountdownComplete,
  content,
  textStyles
}) => {
  const renderType0 = ({ days, hours, minutes, seconds }: any) => {
    const countdownArray = [
      { value: days, label: "days" },
      { value: hours, label: "hrs" },
      { value: minutes, label: "min" },
      { value: seconds, label: "sec" }
    ];

    return (
      <div
        className="countdown-type-0"
        style={{ color: content.color ?? "white" }}
      >
        {countdownArray.map(({ value, label }, index) => {
          if (value === "00" && label === "days") return null;
          return (
            <div key={index} className="countdown-box">
              <span className="countdown-number">{value}</span>
              {index < 3 && (
                <span
                  className="countdown-dots"
                  style={{ color: content?.backgroundColor }}
                >
                  :
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderType1 = ({ days, hours, minutes, seconds }: any) => {
    const countdownArray = [
      { value: days, label: "days" },
      { value: hours, label: "hrs" },
      { value: minutes, label: "min" },
      { value: seconds, label: "sec" }
    ];

    return (
      <div className="countdown-type-1">
        {countdownArray.map(({ value, label }, index) => {
          if (value === "00" && label === "days") return null;
          return (
            <div className="countdown-box">
              <div key={index}>
                {value.split("").map(number => (
                  <span
                    className="countdown-number"
                    style={{
                      color: content?.color ?? "white",
                      backgroundColor: content.backgroundColor
                    }}
                  >
                    {number}
                  </span>
                ))}
                {index < 3 && (
                  <span
                    className="countdown-dots"
                    style={{ color: content?.backgroundColor }}
                  >
                    :
                  </span>
                )}
              </div>
              <span
                style={{ color: content.color ?? "white" }}
                className="countdown-label"
              >
                {label}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderType2 = ({ days, hours, minutes, seconds }: any) => {
    const countdownArray = [
      { value: days, label: "Days" },
      { value: hours, label: "Hours" },
      { value: minutes, label: "Minutes" },
      { value: seconds, label: "Seconds" }
    ];

    return (
      <div className="countdown-type-2">
        {countdownArray.map(({ value, label }, index) => {
          if (value === "00" && label === "Days") return null;
          return (
            <div
              key={index}
              className="countdown-box"
              style={{ backgroundColor: content.backgroundColor }}
            >
              <span
                style={{ color: content.color ?? "white" }}
                className="countdown-value"
              >
                {value}
              </span>
              <span
                style={{ color: content.color ?? "white" }}
                className="countdown-label"
              >
                {label}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderType3 = ({ days, hours, minutes, seconds }: any) => {
    const countdownArray = [
      { value: days, label: "days" },
      { value: hours, label: "hrs" },
      { value: minutes, label: "min" },
      { value: seconds, label: "sec" }
    ];
    return (
      <div
        className="countdown-type-3"
        style={{
          backgroundColor: content?.backgroundColor,
          color: content?.color ?? "white"
        }}
      >
        {countdownArray.map(({ value, label }, index) => {
          if (value === "00" && label === "days") return null;
          return (
            <div
              key={index}
              className="countdown-box"
              style={{
                borderRight: index < 3 ? `1px solid ${content?.color}` : "none"
              }}
            >
              <span className="countdown-number">{value}</span>
              <span className="countdown-label">{label}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderType4 = ({ days, hours, minutes, seconds }: any) => {
    const countdownArray = [
      { value: days, label: "d" },
      { value: hours, label: "h" },
      { value: minutes, label: "m" },
      { value: seconds, label: "s" }
    ];
    return (
      <div className="countdown-type-4">
        {countdownArray.map(({ value, label }, index) => {
          if (value === "00" && label === "d") return null;
          return (
            <Fragment key={index}>
              <div
                className="countdown-box"
                style={{
                  backgroundColor: content?.backgroundColor,
                  color: content?.color
                }}
              >
                <span
                  className="countdown-value"
                  style={{ color: content?.color ?? "white" }}
                >
                  {value}
                </span>
                <span
                  className="countdown-label"
                  style={{ color: content?.color ?? "white" }}
                >
                  {label}
                </span>
              </div>
              {index < 3 && <span style={{ color: content?.color }}>:</span>}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const renderType5 = ({ days, hours, minutes, seconds }: any) => {
    const countdownArray = [
      { value: days, label: "DAYS" },
      { value: hours, label: "HRS" },
      { value: minutes, label: "MIN" },
      { value: seconds, label: "SEC" }
    ];

    return (
      <div className="countdown-type-5">
        {countdownArray.map(({ value, label }, index) => {
          if (value === "00" && label === "DAYS") return null;
          return (
            <div className="countdown-box">
              <div key={index} className="dots-container">
                <div className="text-container">
                  <div>
                    {value.split("").map(number => (
                      <span
                        className="countdown-number"
                        style={{
                          color: content?.color ?? "white",
                          backgroundColor: content.backgroundColor
                        }}
                      >
                        {number}
                      </span>
                    ))}
                  </div>
                  <span
                    style={{ color: content.color ?? "white" }}
                    className="countdown-label"
                  >
                    {label}
                  </span>
                </div>
                {index < 3 && (
                  <span
                    className="countdown-dots"
                    style={{ color: content?.backgroundColor }}
                  >
                    :
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderer = ({ completed, formatted }: any) => {
    if (completed) return null;

    let content;
    switch (type) {
      case CountdownType.Type0:
        content = renderType0(formatted);
        break;
      case CountdownType.Type1:
        content = renderType1(formatted);
        break;
      case CountdownType.Type2:
        content = renderType2(formatted);
        break;
      case CountdownType.Type3:
        content = renderType3(formatted);
        break;
      case CountdownType.Type4:
        content = renderType4(formatted);
        break;
      case CountdownType.Type5:
        content = renderType5(formatted);
        break;
      default:
        content = renderType0(formatted);
    }

    return (
      <Fragment>
        {children}
        {content}
      </Fragment>
    );
  };

  return (
    <Countdown
      date={date}
      renderer={renderer}
      onComplete={onCountdownComplete}
    />
  );
};

export default CustomCountdown;
