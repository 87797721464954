import { HANDLE_GRID_CHANGE, SET_INITIAL_STATE } from "../constants";
import { setViewGrid } from "@/util";

export const handleGridChange = (gridValue: number) => {
  setViewGrid(gridValue);

  return {
    type: HANDLE_GRID_CHANGE,
    gridValue
  };
};

export const setInitialState = () => ({ type: SET_INITIAL_STATE });
