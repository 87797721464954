import {
  SELECT_QUESTION_LIST,
  CHANGE_QUESTION_LIST,
  REMOVE_ORDER_IMG,
  SELECT_ORDER_ID,
  SENDUS_HANDLE_TEXT_CHANGE,
  REQUIRED_EN,
  GET_SENDUS_QUESITONS,
  SENDUS_IMG_HASH,
  SENDUS_RESET_FORM,
  SENDUS_SUBMITFORM_MODAL
} from "../constants";

import validator from "validator";

type ErrorDetail = {
  valid: boolean;
  validWhen: boolean;
  check: string;
  message: string;
};

type Errors = {
  name: ErrorDetail;
  phone: ErrorDetail;
  message: ErrorDetail;
  email: ErrorDetail;
};

type TicketComment = {
  body: any;
  uploads: any[];
};

type UserForm = {
  orderId: string;
  name: string;
  email: string;
  phone: string;
  dialCode: string;
  areaCode: string;
  message: string;
  customerId: string;
  uploadFiles: any[];
};

type Ticket = {
  subject: string;
  comment: TicketComment;
  userForm: UserForm;
};

type SendUsEmailState = {
  questionListId: string;
  questionTxt: string;
  reasonListId: string;
  reasonTxt: string;
  selectedOrderId: string;
  isFormSubmit: boolean;
  errors: Errors;
  ticket: Ticket;
  content: any;
};

const initialState: SendUsEmailState = {
  questionListId: "",
  questionTxt: "",
  reasonListId: "",
  reasonTxt: "",
  selectedOrderId: "",
  isFormSubmit: false,
  errors: {
    name: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    phone: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    message: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    email: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    }
  },
  ticket: {
    subject: "",
    comment: {
      body: "",
      uploads: []
    },
    userForm: {
      orderId: "",
      name: "",
      email: "",
      phone: "",
      dialCode: "",
      areaCode: "",
      message: "",
      customerId: "",
      uploadFiles: []
    }
  },
  content: {}
};

const selectQuestionList = (
  state: SendUsEmailState,
  { id, groupName, innerText }
) => {
  if (groupName === "question") {
    return {
      ...state,
      questionListId: id,
      questionTxt: innerText,
      ticket: {
        ...state.ticket,
        subject: innerText
      }
    };
  }
  if (groupName === "reason") {
    return {
      ...state,
      reasonListId: id,
      reasonTxt: innerText,
      ticket: {
        ...state.ticket,
        comment: {
          ...state.ticket.comment,
          body: innerText
        }
      }
    };
  }
};

const changeQuestionList = (state: SendUsEmailState, { groupName }) => {
  if (groupName === "question") {
    return {
      ...state,
      questionListId: "",
      reasonListId: "",
      questionTxt: "",
      reasonTxt: ""
    };
  }
  if (groupName === "reason") {
    return {
      ...state,
      reasonListId: "",
      reasonTxt: ""
    };
  }
};

const handleTextChange = (state: SendUsEmailState, { name, value, hash }) => {
  if (name === "uploadFiles") {
    if (!!value) {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          userForm: {
            ...state.ticket.userForm,
            uploadFiles: [
              ...state.ticket.userForm.uploadFiles,
              {
                filename: value.name,
                hash: hash
              }
            ]
          }
        }
      };
    }
  } else {
    return {
      ...state,
      ticket: {
        ...state.ticket,
        userForm: {
          ...state.ticket.userForm,
          [name]: value
        }
      },
      errors: {
        ...state.errors,
        [name]: {
          ...state.errors[name],
          touched: true,
          valid:
            state.errors[name] && state.errors[name].check
              ? validator[state.errors[name].check](value) ===
                state.errors[name].validWhen
              : true
        }
      }
    };
  }
};

const selectOrderId = (state: SendUsEmailState, { orderId }) => ({
  ...state,
  selectedOrderId: orderId,
  ticket: {
    ...state.ticket,
    userForm: {
      ...state.ticket.userForm,
      orderId
    }
  }
});

const removeOrderImg = (state: SendUsEmailState, { selectImg }) => ({
  ...state,
  ticket: {
    ...state.ticket,
    comment: {
      ...state.ticket.comment,
      uploads: state.ticket.comment.uploads.filter(item => item !== selectImg)
    },
    userForm: {
      ...state.ticket.userForm,
      uploadFiles: state.ticket.userForm.uploadFiles.filter(
        item => item.hash !== selectImg
      )
    }
  }
});

const setQuestionList = (state: SendUsEmailState, { content }) => ({
  ...state,
  content
});

const setUploadsImg = (state: SendUsEmailState, { hash }) => ({
  ...state,
  ticket: {
    ...state.ticket,
    comment: {
      ...state.ticket.comment,
      uploads: [...state.ticket.comment.uploads, hash]
    }
  }
});

const resetForm = (state: SendUsEmailState) => ({ ...initialState });

const hideModal = (state: SendUsEmailState, { value }) => ({
  ...state,
  isFormSubmit: value
});

const sendUsEmailReducer = (
  state: SendUsEmailState = initialState,
  action
): SendUsEmailState => {
  switch (action.type) {
    case SELECT_QUESTION_LIST:
      return selectQuestionList(state, action);
    case CHANGE_QUESTION_LIST:
      return changeQuestionList(state, action);
    case SENDUS_HANDLE_TEXT_CHANGE:
      return handleTextChange(state, action);
    case REMOVE_ORDER_IMG:
      return removeOrderImg(state, action);
    case SELECT_ORDER_ID:
      return selectOrderId(state, action);
    case GET_SENDUS_QUESITONS:
      return setQuestionList(state, action);
    case SENDUS_IMG_HASH:
      return setUploadsImg(state, action);
    case SENDUS_RESET_FORM:
      return resetForm(state);
    case SENDUS_SUBMITFORM_MODAL:
      return hideModal(state, action);
    default:
      return state;
  }
};

export default sendUsEmailReducer;
