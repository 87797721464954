import get from "lodash/get";
import { ContextStorage } from "@/libs/contextStorage";
import {
  selectLanguageSlug,
  selectLisaMinimized,
  selectRouteSlug,
  selectWishListBaseData
} from "../selectors";
import { getDataAsObject } from "../common";
export const getItem = payload => get(payload, ["item", "data"]);

export const getItemId = item => get(item, ["origin", "id"]);

export const getActionString = item => get(item, ["action"]);

export const parseItemDataFromString = stringified =>
  getDataAsObject(stringified);

export const getPositionStyle = (customProps, units = "") => {
  const { position } = customProps;
  if (!position || !position.includes(":")) return null;

  return position
    .replaceAll(" ", "")
    .split(",")
    .reduce((acc, pair) => {
      const [key, value] = pair.split(":");
      acc[key] = value + units;
      return acc;
    }, {});
};

export const getLiveFloatingButtonUrl = (url, routeSlug) =>
  `/${routeSlug}${url?.startsWith("/") ? url : `/${url}`}`;

export const hasStringifiedData = string => string?.includes(":");

export const getItemDetails = data => {
  const actionString = getActionString(data);
  return hasStringifiedData(actionString)
    ? parseItemDataFromString(actionString)
    : { productId: Number(data.id) };
};

export const getWishListBaseDataStore = () =>
  selectWishListBaseData(ContextStorage.store.getState());

export const getRouteSlugStore = () =>
  selectRouteSlug(ContextStorage.store.getState());

export const getLanguageSlugStore = () =>
  selectLanguageSlug(ContextStorage.store.getState());

export const getLisaMinimized = () =>
  selectLisaMinimized(ContextStorage.store.getState());
