import intersectionWith from "lodash/intersectionWith";
import isEmpty from "lodash/isEmpty";
import qs from "query-string";
import { getDataAsObject } from "../common";

export const normalizeCartValues = ({
  item,
  attrNamePstock = "pstock",
  attrNameQuantity = "quantity"
}) => {
  let newPstock = item[attrNamePstock];
  let newQuantity = item[attrNameQuantity];
  if (typeof item[attrNamePstock] !== "number" || item[attrNamePstock] < 0) {
    newPstock = 0;
  }
  if (
    typeof item[attrNameQuantity] !== "number" ||
    item[attrNameQuantity] <= 0
  ) {
    newQuantity = 1;
  }

  const newColorArray =
    item.colors &&
    item.colors.colorSizeOptions &&
    item.colors.colorSizeOptions.length &&
    item.colors.colorSizeOptions.map(item => {
      if (typeof item.sizeStock !== "number" || item.sizeStock < 0) {
        return { ...item, sizeStock: 0 };
      }
      return item;
    });

  return {
    ...item,
    colors: { colorSizeOptions: newColorArray },
    [attrNamePstock]: newPstock,
    [attrNameQuantity]: newQuantity
  };
};

export const sameSizeItemsFormat = (item, cart = []) => {
  const sameItemFromCart = cart.find(
    cartItem =>
      cartItem.sizeId === item.sizeId && cartItem.productId === item.productId
  );

  return {
    ...item,
    size: sameItemFromCart ? sameItemFromCart.size : item.size,
    sizeId: sameItemFromCart ? sameItemFromCart.sizeId : item.sizeId,
    sizeType: sameItemFromCart ? sameItemFromCart.sizeType : item.sizeType
  };
};

export const getSortedDeliveryDetails = (deliveryDetails, selectedDelivery) => {
  if (!deliveryDetails || !selectedDelivery) return deliveryDetails;
  const sorted = deliveryDetails.filter(
    detail => detail.deliveryType.key !== selectedDelivery.deliveryType.key
  );
  return [selectedDelivery, ...sorted];
};

export const getSelectedCartItemsIds = cart =>
  cart.filter(product => product.isSelected).map(item => item._id);

export const getUnavailableItemsIds = cart =>
  cart.filter(product => product.errors).map(item => item._id);
