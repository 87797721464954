export const MY_ACCOUNT_DETAIL = "MY_ACCOUNT_DETAIL";
export const RESET_MY_ACCOUNT_DETAIL = "RESET_MY_ACCOUNT_DETAIL";
export const HANDLE_APP_DOWNLOAD_DETAILS = "HANDLE_APP_DOWNLOAD_DETAILS";
export const SET_MY_ORDERS = "SET_MY_ORDERS";
export const SET_USER_LOYALTY_POINTS = "SET_USER_LOYALTY_POINTS";
export const SET_LOYALTY_POINTS_TIER = "SET_LOYALTY_POINTS_TIER";
export const SET_LOYALTY_TABLE = "SET_LOYALTY_TABLE";
export const SET_LOYALTY_EXPIRY_DATA = "SET_LOYALTY_EXPIRY_DATA";
export const SET_LOYALTY_EARNED_POINTS = "SET_LOYALTY_EARNED_POINTS";
export const SET_LOYALTY_PROGRAM_STATUS = "SET_LOYALTY_PROGRAM_STATUS";
export const SET_IS_CHECKED_LOYALTY_PROGRAM = "SET_IS_CHECKED_LOYALTY_PROGRAM";
export const SET_CANCEL_ORDER = "SET_CANCEL_ORDER";
export const SET_WALLET = "SET_WALLET";
export const UPDATE_WALLET = "UPDATE_WALLET";
export const SET_USER_WALLET_EXPIRY_DATA = "SET_USER_WALLET_EXPIRY_DATA";
export const RESET_LOYALTY_PROGRAM_INFO = "RESET_LOYALTY_PROGRAM_INFO";
export const RESET_CANCEL_ORDER = "RESET_CANCEL_ORDER";
export const ADD_TO_WALLET = "ADD_TO_WALLET";
export const TRACK_ORDER = "TRACK_ORDER";
export const CLEAR_TRACK_ORDER = "CLEAR_TRACK_ORDER";
export const FETCH_SOCIAL_ACCOUNT = "FETCH_SOCIAL_ACCOUNT";
export const TOGGLE_ADD_NEW_CARD_TAB = "TOGGLE_ADD_NEW_CARD_TAB";
export const SET_UPDATE_ERROR = "SET_UPDATE_ERROR";
export const SET_PHONE_UPDATE_FLOW_DATA = "SET_PHONE_UPDATE_FLOW_DATA";
export const SHOW_MY_ORDERS_LOADER = "SHOW_MY_ORDERS_LOADER";
export const HIDE_MY_ORDERS_LOADER = "HIDE_MY_ORDERS_LOADER";
export const SET_IS_LOADING_LOYALTY_POINTS = "SET_IS_LOADING_LOYALTY_POINTS";
export const SET_WALLET_AMOUNT = "SET_WALLET_AMOUNT";
