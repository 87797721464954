import { ENV_CONFIG } from "./env";
export const isProduction = ENV_CONFIG.env === "production";

export const facebookAppId =
  process.env.REACT_APP_FACEBOOK_APP_ID || "2104548639847068";

export const googleAppId =
  process.env.REACT_APP_GOOGLE_APP_ID ||
  "59499981756-igiq8ebqotivaood9na58ohjcd0paq9o.apps.googleusercontent.com";
export const appleId =
  process.env.REACT_APP_APPLE_APP_ID || "service.com.brandsforless";

export const appleRedirectUri =
  process.env.REACT_APP_BASE_URL || "https://qa-com.bfl.dev/en-ae/";

export const appSettings = {
  imageBaseURL: process.env.REACT_APP_IMAGEURL || "https://b.bflcdn.com/",
  quality: process.env.REACT_APP_IMAGEQUALITYINDEX || "100"
};

//Payment info

export const sentry_DSN =
  process.env.REACT_APP_SENTRY_DSN ||
  "https://ccc6910ad2904653afb1669649518653@sentry.io/1877493";

export const cardTypes = {
  master_card: "MasterCard",
  visa_card: "Visa"
};

export const otp_resend_timeout =
  process.env.REACT_APP_OTP_RESENT_TIMEOUT || 60;

export const stormlyKey =
  process.env.REACT_APP_STORMLY_KEY || "deda20b1ac9fb4f1a9f6e1e85b04e7014";
