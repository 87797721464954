type CountDownLanguageType = {
  enabledOn: string[];
  imageURL?: string;
  ratio?: number;
  redirectionLink?: string;
  width?: number;
  absoluteStyles?: {
    top: number;
    left: number;
  };
};

type CountDownContentStylesType = {
  marginBottom: number;
  marginTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
};

type CountDownImageDataType = {
  english: CountDownLanguageType;
  arabic: CountDownLanguageType;
  bannerId?: string;
  id: string;
  type: string;
  styles: CountDownContentStylesType & {
    hover: {
      opacity?: number;
      transform?: string;
      transition?: string;
    };
    hoverValues: { opacity?: number; transform?: number; transition?: number };
  };
};

export enum CountdownType {
  Type0 = 0,
  Type1 = 1,
  Type2 = 2,
  Type3 = 3,
  Type4 = 4,
  Type5 = 5
}

export type CountDownContentType = {
  id?: string;
  styles?: CountDownContentStylesType;
  date?: string | number | Date;
  styleType?: CountdownType;
  countdownOption?: string;
  backgroundColor: string;
  color: string;
  beforeCountDown?: CountDownImageDataType;
  afterCountDown?: CountDownImageDataType;
  headingColor?: string;
  ar?: {
    heading: string;
    headingPosition: string;
  };
  en?: {
    heading: string;
    headingPosition: string;
  };
};
