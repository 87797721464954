import ChatImg from "../../images/chat.svg";
import MessageImg from "../../images/message.svg";
import MessengerImg from "../../images/messenger.svg";
import FacebookImg from "../../images/facebook-logo.svg";
import ShippingImg from "../../images/shipping-and-delivery.png";
import RefundImg from "../../images/refund.svg";
import ProductStockImg from "../../images/product-stock.svg";
import CreditCardImg from "../../images/credit-card .svg";
import OrderImg from "../../images/order.svg";
import TechnicalImg from "../../images/technical.svg";

export const HELP_DATA_TILES = [
  // The button is hidden because we are not able to interact with the new chat.
  // {
  //   imgSource: ChatImg,
  //   data: "chatWithUs",
  //   backgroundColor: "#000",
  //   color: "#fff",
  //   class: "chat-img",
  //   redirectionLink: "",
  //   link: "/send-us-email/",
  //   onClick: () => {
  //     if (window.$zopim) {
  //       window.$zopim.livechat.window.toggle();
  //     } else {
  //       console.error("Cannot run livechat");
  //     }
  //   }
  // },
  {
    imgSource: MessageImg,
    data: "sendUsAnEmail",
    backgroundColor: "#fff",
    color: "#000",
    class: "message-img",
    target: true,
    redirectionLink: "",
    link: "/send-us-email/"
  },
  {
    imgSource: MessengerImg,
    data: "connectOnMessenger",
    backgroundColor: "#fff",
    color: "#000",
    class: "messenger-img",
    redirectionLink: "https://www.messenger.com/t/BrandsForLessUAE"
  },
  {
    imgSource: FacebookImg,
    data: "connectOnFacebook",
    backgroundColor: "#fff",
    color: "#000",
    class: "facebook-img",
    redirectionLink: "https://www.facebook.com/BrandsForLess"
  }
];

export const TILE_DATA = [
  {
    imgSource: ProductStockImg,
    data: "orderIssues",
    link: "/need-help/order-issues/"
  },
  {
    imgSource: RefundImg,
    data: "loyaltyProgram",
    link: "/need-help/loyalty-program/"
  },
  {
    imgSource: CreditCardImg,
    data: "paymentAndVouchers",
    link: "/need-help/payment-and-vouchers/"
  },
  {
    imgSource: ShippingImg,
    data: "trackingShippingAndDelivery",
    link: "/need-help/tracking-shipping-and-delivery/"
  },
  {
    imgSource: OrderImg,
    data: "productAndStock",
    link: "/need-help/product-and-stock/"
  },
  {
    imgSource: TechnicalImg,
    data: "general",
    link: "/need-help/general/"
  }
];

export const NEED_HELP_TOPIC_MAP = {
  "order-issues": "orderIssues",
  "loyalty-program": "loyaltyProgram",
  "payment-and-vouchers": "paymentAndVouchers",
  "tracking-shipping-and-delivery": "trackingShippingAndDelivery",
  "product-and-stock": "productAndStock",
  general: "general"
};
