import React, { FC, useEffect, useMemo, useState } from "react";
import cn from "clsx";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isServer } from "../../../util";
import { useIsRTL } from "../../../hooks";
import { useAppSelector } from "../../../redux/store";
import { handleGridChange } from "../../../redux/actions/productListing.action";
import { selectViewGridValue } from "@/util/selectors";
import "./style.scss";

const checkIsScreen4GridOnly = () =>
  !isServer &&
  window.matchMedia("(min-width: 812px) and (max-width: 1024px)").matches;

const ContentHeader: FC<{
  isCategoryTitle: boolean;
  productSearchTitle: string;
  currentSelectedCategory: string;
  itemsTotal: number;
  headingH1: string;
}> = ({
  isCategoryTitle,
  productSearchTitle,
  currentSelectedCategory,
  itemsTotal,
  headingH1
}) => {
  const { t } = useTranslation("productListing");
  const dispatch = useDispatch();
  const gridValue = useAppSelector(selectViewGridValue);

  const arabic = useIsRTL();

  const [hide6GridOption, setHide6GridOption] = useState(false);
  const headerContent = useMemo(() => {
    if (headingH1) {
      return `${headingH1} ${
        currentSelectedCategory && `: ${currentSelectedCategory}`
      }`;
    } else {
      return !!productSearchTitle && productSearchTitle;
    }
  }, [headingH1, currentSelectedCategory, productSearchTitle]);

  const headerSpanContent = () => {
    if (headingH1) {
      return `${itemsTotal} ${t("items")}`;
    } else {
      return isCategoryTitle
        ? t("chooseCategory")
        : `${itemsTotal} ${t("items")}`;
    }
  };

  const onGridChange = gridValue => dispatch(handleGridChange(gridValue));

  useEffect(() => {
    const handleGridOptions = () => {
      if (checkIsScreen4GridOnly()) {
        onGridChange(4);
        setHide6GridOption(true);
      } else {
        setHide6GridOption(false);
      }
    };

    handleGridOptions();
    window.addEventListener("resize", handleGridOptions);

    return () => {
      window.removeEventListener("resize", handleGridOptions);
    };
  }, []);

  return (
    <div className={cn("content_heading", { arabic })}>
      <div
        className={cn("container padding0", {
          "d-flex justify-content-center": hide6GridOption
        })}
      >
        <h1 className={cn({ "p-0": hide6GridOption })}>
          {headerContent}
          <span className={cn("category_count", { "pt-2": headerContent })}>
            {headerSpanContent()}
          </span>
        </h1>
        <p className={cn("view_grid", { "d-none": hide6GridOption })}>
          <span
            onClick={() => onGridChange(6)}
            className={cn("grid_6", { selected: gridValue === 6 })}
          />
          <span
            onClick={() => onGridChange(4)}
            className={cn("grid_4", { selected: gridValue !== 4 })}
          />
        </p>
      </div>
    </div>
  );
};
export default ContentHeader;
