import validator from "validator";
import {
  MY_ACCOUNT_DETAIL,
  SET_MY_ORDERS,
  SET_USER_LOYALTY_POINTS,
  SET_LOYALTY_TABLE,
  SET_CANCEL_ORDER,
  REQUIRED_EN,
  NUMBER_EN,
  SET_WALLET,
  UPDATE_WALLET,
  RESET_CANCEL_ORDER,
  ADD_TO_WALLET,
  TRACK_ORDER,
  CLEAR_TRACK_ORDER,
  SET_USER_WALLET_EXPIRY_DATA,
  FETCH_SOCIAL_ACCOUNT,
  TOGGLE_ADD_NEW_CARD_TAB,
  HANDLE_APP_DOWNLOAD_DETAILS,
  RESET_MY_ACCOUNT_DETAIL,
  SET_LOYALTY_EXPIRY_DATA,
  SET_LOYALTY_EARNED_POINTS,
  RESET_LOYALTY_PROGRAM_INFO,
  SET_UPDATE_ERROR,
  SET_PHONE_UPDATE_FLOW_DATA,
  SHOW_MY_ORDERS_LOADER,
  HIDE_MY_ORDERS_LOADER,
  SET_IS_LOADING_LOYALTY_POINTS,
  SET_LOYALTY_POINTS_TIER,
  SET_WALLET_AMOUNT
} from "../constants";

type ErrorDetail = {
  valid: boolean;
  validWhen: boolean;
  check: string;
  message: string;
  touched?: boolean;
};

type Info = {
  firstname: string;
  lastname: string;
  gender: string;
  phone: string;
  email: string;
  nationality: number;
  countryId: string;
  birthday: string;
};

type Errors = {
  firstname: ErrorDetail;
  phone: ErrorDetail;
};

type MyDetails = {
  info: Info;
  errors: Errors;
};

type MyOrders = {
  order: any[];
  totalOrders: number;
  totalAmount: number;
};
type OrderItem = {
  productId: string;
  quantity: number;
  colorid: number;
  sizeid: number;
};

type OrderDetail = {
  cancelOrder: {
    orderId: string;
    reason: string;
    email: string;
    comment: string;
    quantity: string;
    orderItems: OrderItem[];
  };
  errors: {
    comment: ErrorDetail;
    reason: ErrorDetail;
    quantity: ErrorDetail;
  };
};

type ExpiryDetailsType = {
  earliestExpiryPoints: number;
  earliestPointExpiryDate: string;
  expiryPointsNextMon: number;
  expiryPointsDateNextMon: string;
};

type LoyaltyStaticsType = {
  earliestExpiryPoints?: number;
  earliestPointExpiryDate?: string;
  expiryPointsNextMon?: number;
  expiryPointsDateNextMon?: string;
  loyaltyDetails: {
    currentBalance: string | number;
    pointNeededForNextLevel: number;
    accuralPoints: number;
    memeberLevel: string;
    usedPointsToConvert: number;
    earnedAmountFromConvert: number;
    cutoffDate: string;
    cardNumber?: string;
  };
  tierToken: string;
};

type LoyaltyPoints = {
  LoyaltyStatics: LoyaltyStaticsType;
  LoyaltyTable: Record<string, any>;
  expiryDetails: ExpiryDetailsType;
  loyaltyEarnedPoints: any[];
};

type AddToWallet = {
  voucherCode: string;
  currencyCode: string;
  apiErrorResponse: string;
};

type MyAccountState = {
  myDetails: MyDetails;
  myDetailsReset: MyDetails;
  appDownloadPhone: {
    info: {
      phone: string;
    };
    errors: {
      phone: ErrorDetail;
    };
  };
  myOrders: MyOrders;
  OrderDetail: OrderDetail;
  loyaltyPoints: LoyaltyPoints;
  wallet: Record<string, any>;
  walletExpiryDetails: Record<string, any>;
  fullWalletHistoryLoaded: boolean;
  addToWallet: AddToWallet;
  socialAccounts: Record<string, any>;
  trackOrder: Record<string, any>;
  addNewCardTab: boolean;
  updateError: Record<string, any>;
  phoneUpdateFlowData: Record<string, any>;
  showMyOrdersLoader: boolean;
  isLoadingLoyaltyPoints: boolean;
  tierToken: string;
};

const initialState: MyAccountState = {
  myDetails: {
    info: {
      firstname: "",
      lastname: "",
      gender: "",
      phone: "",
      email: "",
      nationality: 1,
      countryId: "",
      birthday: ""
    },
    errors: {
      firstname: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      }
    }
  },
  myDetailsReset: {
    info: {
      firstname: "",
      lastname: "",
      gender: "",
      phone: "",
      email: "",
      nationality: 1,
      countryId: "",
      birthday: ""
    },
    errors: {
      firstname: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      }
    }
  },
  appDownloadPhone: {
    info: {
      phone: ""
    },
    errors: {
      phone: {
        valid: true,
        validWhen: false,
        check: "",
        message: NUMBER_EN
      }
    }
  },
  myOrders: {} as MyOrders,
  OrderDetail: {
    cancelOrder: {
      orderId: "",
      reason: "",
      email: "",
      comment: "",
      quantity: "",
      orderItems: [
        {
          productId: "",
          quantity: 0,
          colorid: 0,
          sizeid: 0
        }
      ]
    },
    errors: {
      comment: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      reason: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      quantity: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN,
        touched: false
      }
    }
  },
  loyaltyPoints: {
    LoyaltyStatics: {} as LoyaltyStaticsType,
    LoyaltyTable: {} as Record<string, any>,
    expiryDetails: {} as ExpiryDetailsType,
    loyaltyEarnedPoints: [] as any[]
  },
  wallet: {} as Record<string, any>,
  walletExpiryDetails: {} as Record<string, any>,
  fullWalletHistoryLoaded: false,
  addToWallet: {
    voucherCode: "",
    currencyCode: "",
    apiErrorResponse: ""
  },
  socialAccounts: {} as Record<string, any>,
  trackOrder: {} as Record<string, any>,
  addNewCardTab: false,
  updateError: {} as Record<string, any>,
  phoneUpdateFlowData: {} as Record<string, any>,
  showMyOrdersLoader: false,
  isLoadingLoyaltyPoints: false,
  tierToken: ""
};

const trackOrder = (state: MyAccountState, { apiResponse }) => ({
  ...state,
  trackOrder: apiResponse
});
const clearTrackOrder = (state: MyAccountState) => ({
  ...state,
  trackOrder: {}
});
const myAccountDetails = (state: MyAccountState, { info }) => ({
  ...state,
  myDetails: {
    ...state.myDetails,
    info: {
      ...state.myDetails.info,
      firstname: info.firstname,
      lastname: info.lastname,
      gender: info.gender,
      phone: info.phone,
      email: info.email,
      birthday: info.birthday
    }
  }
});

const resetMyAccountDetails = (state: MyAccountState) => ({
  ...state,
  myDetails: { ...state.myDetailsReset },
  myOrders: initialState.myOrders,
  tierToken: ""
});

const addToWallet = (state: MyAccountState, { name, value }) => ({
  ...state,
  addToWallet: {
    ...state.addToWallet,
    [name]: value
  }
});
const setCancelOrder = (state: MyAccountState, { name, value }) => ({
  ...state,
  OrderDetail: {
    ...state.OrderDetail,
    cancelOrder: {
      ...state.OrderDetail.cancelOrder,
      [name]: value
    },
    errors: {
      ...state.OrderDetail.errors,
      [name]: {
        ...state.OrderDetail.errors[name],
        touched: true,
        valid:
          state.OrderDetail.errors[name] && state.OrderDetail.errors[name].check
            ? validator[state.OrderDetail.errors[name].check](value) ===
              state.OrderDetail.errors[name].validWhen
            : true
      }
    }
  }
});
const resetCancelOrder = (state: MyAccountState) => ({
  ...state,
  OrderDetail: initialState.OrderDetail
});

const setAppDownloadPhoneInfo = (
  state: MyAccountState,
  { name, value, status }
) => ({
  ...state,
  appDownloadPhone: {
    ...state.appDownloadPhone,
    info: {
      ...state.appDownloadPhone.info,
      [name]: value
    },
    errors: {
      ...state.appDownloadPhone.errors,
      [name]: {
        ...state.appDownloadPhone.errors[name],
        valid:
          state.appDownloadPhone.errors[name] &&
          state.appDownloadPhone.errors[name].check
            ? validator[state.appDownloadPhone.errors[name].check](value) ===
                state.appDownloadPhone.errors[name].validWhen &&
              (typeof status !== "undefined" ? status : true)
            : typeof status !== "undefined"
            ? status
            : true
      }
    }
  }
});

const setMyOrders = (state: MyAccountState, { apiResponse }) => ({
  ...state,
  myOrders: { ...apiResponse }
});

const setWallet = (state: MyAccountState, { apiResponse }) => ({
  ...state,
  wallet: apiResponse,
  fullWalletHistoryLoaded: !Boolean(
    apiResponse.walletHistoryDetails && apiResponse.walletHistoryDetails.length
  )
});

const updateWallet = (state: MyAccountState, { apiResponse }) => {
  const { walletHistoryDetails } = apiResponse;

  return {
    ...state,
    wallet: {
      ...state.wallet,
      walletHistoryDetails: [
        ...state.wallet.walletHistoryDetails,
        ...walletHistoryDetails
      ]
    },
    ...(!Boolean(walletHistoryDetails.length) && {
      fullWalletHistoryLoaded: true
    })
  };
};

const setWalletAmount = (state: MyAccountState, { data }) => ({
  ...state,
  wallet: {
    ...state.wallet,
    walletAmount: data
  }
});

const resetLoyaltyProgramInfo = (state: MyAccountState) => ({
  ...state,
  loyaltyPoints: initialState.loyaltyPoints,
  wallet: initialState.wallet,
  fullWalletHistoryLoaded: initialState.fullWalletHistoryLoaded
});

const socialAccounts = (state: MyAccountState, { apiResponse }) => ({
  ...state,
  socialAccounts: apiResponse
});
const setLoyaltyPoints = (state: MyAccountState, { apiResponse }) => ({
  ...state,
  loyaltyPoints: {
    ...state.loyaltyPoints,
    LoyaltyStatics: apiResponse
  }
});
const setLoyaltyTable = (state: MyAccountState, { apiResponse, pageNum }) => {
  if (pageNum > 1) {
    return {
      ...state,
      loyaltyPoints: {
        ...state.loyaltyPoints,
        LoyaltyTable: {
          ...state.loyaltyPoints.LoyaltyTable,
          ...apiResponse,
          loyaltyPoints: [
            ...state.loyaltyPoints.LoyaltyTable.loyaltyPoints,
            ...apiResponse.loyaltyPoints
          ]
        }
      }
    };
  } else {
    return {
      ...state,
      loyaltyPoints: {
        ...state.loyaltyPoints,
        LoyaltyTable: { ...apiResponse }
      }
    };
  }
};

const setWalletExpiryData = (state: MyAccountState, action) => ({
  ...state,
  walletExpiryDetails: action.payload
});

const setLoyaltyExpiryData = (state: MyAccountState, action) => ({
  ...state,
  loyaltyPoints: { ...state.loyaltyPoints, expiryDetails: action.payload }
});

const setLoyaltyEarnedPoints = (state: MyAccountState, action) => ({
  ...state,
  loyaltyPoints: { ...state.loyaltyPoints, loyaltyEarnedPoints: action.payload }
});

const toggleAddNewCardTab = (state: MyAccountState, { value }) => ({
  ...state,
  addNewCardTab: value
});

const setUpdateError = (state: MyAccountState, { payload }) => ({
  ...state,
  updateError: payload
});

const setPhoneUpdateFlowData = (state: MyAccountState, { payload }) => ({
  ...state,
  phoneUpdateFlowData: payload
    ? { ...state.phoneUpdateFlowData, ...payload }
    : initialState.phoneUpdateFlowData
});

const showMyOrdersLoader = (state: MyAccountState) => ({
  ...state,
  showMyOrdersLoader: true
});

const hideMyOrdersLoader = (state: MyAccountState) => ({
  ...state,
  showMyOrdersLoader: false
});
const setIsLoadingLoyaltyPoints = (state: MyAccountState, { value }) => ({
  ...state,
  isLoadingLoyaltyPoints: value
});

const setLoyaltyPointsTierToken = (state: MyAccountState, { tierToken }) => ({
  ...state,
  tierToken
});

const myAccountReducer = (
  state: MyAccountState = initialState,
  action
): MyAccountState => {
  switch (action.type) {
    case MY_ACCOUNT_DETAIL:
      return myAccountDetails(state, action);
    case RESET_MY_ACCOUNT_DETAIL:
      return resetMyAccountDetails(state);
    case HANDLE_APP_DOWNLOAD_DETAILS:
      return setAppDownloadPhoneInfo(state, action);
    case SET_MY_ORDERS:
      return setMyOrders(state, action);
    case SET_USER_LOYALTY_POINTS:
      return setLoyaltyPoints(state, action);
    case SET_LOYALTY_POINTS_TIER:
      return setLoyaltyPointsTierToken(state, action);
    case SET_LOYALTY_TABLE:
      return setLoyaltyTable(state, action);
    case SET_CANCEL_ORDER:
      return setCancelOrder(state, action);
    case SET_WALLET:
      return setWallet(state, action);
    case UPDATE_WALLET:
      return updateWallet(state, action);
    case RESET_LOYALTY_PROGRAM_INFO:
      return resetLoyaltyProgramInfo(state);
    case RESET_CANCEL_ORDER:
      return resetCancelOrder(state);
    case ADD_TO_WALLET:
      return addToWallet(state, action);
    case TRACK_ORDER:
      return trackOrder(state, action);
    case CLEAR_TRACK_ORDER:
      return clearTrackOrder(state);
    case FETCH_SOCIAL_ACCOUNT:
      return socialAccounts(state, action);
    case TOGGLE_ADD_NEW_CARD_TAB:
      return toggleAddNewCardTab(state, action);
    case SET_USER_WALLET_EXPIRY_DATA:
      return setWalletExpiryData(state, action);
    case SET_LOYALTY_EXPIRY_DATA:
      return setLoyaltyExpiryData(state, action);
    case SET_LOYALTY_EARNED_POINTS:
      return setLoyaltyEarnedPoints(state, action);
    case SET_UPDATE_ERROR:
      return setUpdateError(state, action);
    case SET_PHONE_UPDATE_FLOW_DATA:
      return setPhoneUpdateFlowData(state, action);
    case SHOW_MY_ORDERS_LOADER:
      return showMyOrdersLoader(state);
    case HIDE_MY_ORDERS_LOADER:
      return hideMyOrdersLoader(state);
    case SET_IS_LOADING_LOYALTY_POINTS:
      return setIsLoadingLoyaltyPoints(state, action);
    case SET_WALLET_AMOUNT:
      return setWalletAmount(state, action);
    default:
      return state;
  }
};

export default myAccountReducer;
