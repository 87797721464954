import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLongLanguage, useRouteSlug } from "@/hooks/";
import { CountDownContentType } from "../types";
import BannerLoaderImage from "@/components/banner/banner-loader-image";
import LoaderIcon from "../../../../images/loading-icon.svg";

const CountdownBanner: FC<{
  content: CountDownContentType;
  isCountdownOver: boolean;
  imageLoading: boolean;
  setImageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ content, isCountdownOver, imageLoading, setImageLoading }) => {
  const [countDownImageData, setCountDownImageData] = useState(null);
  const language = useLongLanguage();
  const routeSlug = useRouteSlug();
  const [isHovered, setIsHovered] = useState(false);
  const imageLinkWrapperRef = useRef(null);

  useEffect(() => {
    setCountDownImageData(
      isCountdownOver ? content.afterCountDown : content.beforeCountDown
    );
  }, [isCountdownOver]);

  const imageStyles = {
    ...countDownImageData?.styles,
    zIndex: 1,
    cursor: "pointer"
  };
  const imageDataByLanguage = countDownImageData?.[language];
  const elementInitialStyles = {
    ...imageStyles,
    transition: imageStyles?.hover?.transition
  };

  const hoverStyle = isHovered ? { ...imageStyles?.hover } : {};
  const style = { ...elementInitialStyles, ...hoverStyle };

  return imageDataByLanguage?.imageURL ? (
    <Link
      to={`/${routeSlug}${imageDataByLanguage?.redirectionLink}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      id={content.id}
      style={style}
    >
      <div
        className="banner-element"
        style={{ paddingBottom: `${imageDataByLanguage?.ratio}%` }}
        ref={imageLinkWrapperRef}
      >
        <div className="image-wrapper">
          {imageDataByLanguage?.imageURL && imageLoading && (
            <BannerLoaderImage loaderIcon={LoaderIcon} />
          )}
          <img
            src={imageDataByLanguage?.imageURL}
            alt="banner-image"
            className="banner-image"
            onLoad={() => {
              setImageLoading(false);
              imageLinkWrapperRef.current.style.background = "none";
            }}
          />
        </div>
      </div>
    </Link>
  ) : null;
};

export default CountdownBanner;
