import {
  SET_BRANDS_DATA,
  SET_SEARCH_TEXT,
  RESET_BRANDS_DATA
} from "../constants";
import { Brand } from "../../services/brandsService";

type BrandsState = {
  brands: Brand[];
  searchText: string;
};

const initialState: BrandsState = {
  brands: [],
  searchText: ""
};

const setBrandsData = (state: BrandsState, { brands }) => ({
  ...state,
  brands
});

const setSearchText = (state: BrandsState, { text }) => ({
  ...state,
  searchText: text
});

const resetBrandsReducer = () => ({ ...initialState });

const brandsReducer = (
  state: BrandsState = initialState,
  action
): BrandsState => {
  switch (action.type) {
    case SET_BRANDS_DATA:
      return setBrandsData(state, action);
    case SET_SEARCH_TEXT:
      return setSearchText(state, action);
    case RESET_BRANDS_DATA:
      return resetBrandsReducer();
    default:
      return state;
  }
};

export default brandsReducer;
