import React, { FC, CSSProperties } from "react";
import cn from "clsx";
import { BANNER_ROW_TYPE } from "@/constants";
import { isMobile, isServer } from "@/util";
import CountdownWrapper from "@/components/dynamic/dynamicSingleBanner/countdownWrapper";
import DynamicSingleBanner from "@/components/dynamic/dynamicSingleBanner/index";
import { useLongLanguage } from "@/hooks";
import "./style.scss";

const SingleBannerContainer: FC<{
  imageList: any[];
  rowStyles?: CSSProperties;
  rowDataId?: string;
  bannerModuleName?: string;
  rowTitle?: string;
  bannerPageTitle?: string;
}> = ({
  imageList,
  rowStyles,
  rowDataId,
  bannerModuleName,
  rowTitle,
  bannerPageTitle
}) => {
  const language = useLongLanguage();
  const imageListLength = imageList.length;
  const includesCountDown = imageList.some(
    imageData => imageData.type === BANNER_ROW_TYPE.COUNTDOWN
  );

  if (!imageList) {
    console.error("SingleBannerContainer: imageList data is missing");
  }

  const isResp = isMobile.any();

  return (
    <div
      className={cn("banner-row", "d-flex")}
      style={rowStyles}
      id={rowDataId}
    >
      {imageList?.map((imageData, index) => {
        const deviceAvailabilityData =
          imageData[language]?.enabledOn || imageData.enabledOn;

        if (
          deviceAvailabilityData &&
          isResp &&
          !deviceAvailabilityData.includes("resp")
        ) {
          return null;
        }

        if (imageData.type === BANNER_ROW_TYPE.COUNTDOWN) {
          return (
            <CountdownWrapper
              key={index}
              content={imageData}
              imageListLength={imageListLength}
              removeCountdown={countDownId =>
                removeCountdown(rowDataId, countDownId)
              }
            />
          );
        }
        const itemsAmountToKeepCountdownNotLessHalfWidth =
          imageListLength > 2
            ? imageListLength + imageListLength / 2
            : imageListLength;
        return (
          <DynamicSingleBanner
            key={index}
            data={imageData}
            imageListLength={
              includesCountDown
                ? itemsAmountToKeepCountdownNotLessHalfWidth
                : imageListLength
            }
            index={index}
            bannerModuleName={bannerModuleName}
            rowTitle={rowTitle}
            bannerPageTitle={bannerPageTitle}
          />
        );
      })}
    </div>
  );
};

const removeBannerWrapper = id => {
  const rowSection = !isServer && document.getElementById(id);

  if (rowSection) {
    rowSection.classList.remove("d-flex");
    rowSection.classList.add("d-none");
  }
};

const removeCountdown = (id, countDownId) => {
  const rowSection = !isServer && document.getElementById(id);
  const bannerSection =
    !isServer &&
    rowSection &&
    rowSection.querySelector(`#countdown-${countDownId}`);

  if (bannerSection) {
    // @ts-ignore
    bannerSection.style.display = "none";
  }
};

export default SingleBannerContainer;
