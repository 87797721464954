import qs from "query-string";

import ApiService from "./apiService";
import { ContextStorage } from "@/libs/contextStorage";

import { BEConfig } from "../config/env";

import { webapiGet, webapiPost } from "../webapis/core";
import { DEFAULT_STORE_ID, PRODUCT_CAROUSEL_ITEMS_LIMIT } from "../constants";
import {
  hideLoaders,
  hideLoadingIndicator,
  showLoaders,
  showLoadingIndicator
} from "../redux/actions/common.action";
import CatalogService from "@/services/catalogService";
import { handleFetchError } from "../util/errorHandler";

class SaleService extends ApiService {
  async fetchCarouselData({ slug, dataType, countryId, language, storeId }) {
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.getPageHandle}`;
    const limit = PRODUCT_CAROUSEL_ITEMS_LIMIT;

    const endpoint = `${baseUrl}?${qs.stringify({
      countryId,
      language,
      storeId,
      limit
    })}`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      {
        slug,
        dataType
      },
      this.getVisitorId()
    ).request;
    return response.data;
  }

  async fetchDefaultPageData({ slug, gridValue }) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.catalogApi.flashSaleInfo
    }/${slug}?gridValue=${gridValue}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async fetchSpecificTabData({ tabSlug, saleSlug, queries }) {
    const endpoint = qs.stringifyUrl({
      url: `${this.getBaseUrl()}${
        BEConfig.catalogApi.flashSaleProducts
      }/${saleSlug}/${tabSlug}`,
      query: {
        ...queries
      }
    });

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getSaleSpecificTabData = async (tabSlug, saleSlug, queries?) => {
    const dispatch = ContextStorage.store.dispatch;
    dispatch(showLoaders());

    try {
      const response = await this.fetchSpecificTabData({
        tabSlug,
        saleSlug,
        queries
      });

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(hideLoaders());
    }
  };

  getBaseUrl() {
    const { protocol, baseURL, port, versionInfo } = BEConfig.catalogApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new SaleService();

export default instance;
