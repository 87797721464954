export const PRICE_CONVERSION_DECIMAL_VALUE = 2;

export const AVAILABLE_PAYMENT_TYPE = {
  CHECKOUTCOM: "CHECKOUTCOM"
};

export const VOUCHER = "VOUCHER";

export const WALLET = "WALLET";

export const IS_ORDER_CONFIRMED = "IS_ORDER_CONFIRMED";
export const CONFIRMED_ORDER_ID = "CONFIRMED_ORDER_ID";

export const COUNTRIES_WITH_CIVIL_ID = ["om"];

export const MIN_CIVIL_ID_LENGTH = 1;
export const MAX_CIVIL_ID_LENGTH = 10;
