import map from "lodash/map";
import filter from "lodash/filter";

export const getLoyaltyBody = ({
  cart,
  quantity = 1,
  isCart = false
}: {
  // TODO: set correct type after implementing newer shape in loyalty points API
  cart: any;
  quantity?: number;
  isCart?: boolean;
}) => {
  const products = isCart ? filter(cart, item => item.isSelected) : cart;
  const orderItems = map(products, item => ({
    productId: item.id,
    quantity: item.quantity || quantity,
    transactionPrice: item.price?.current
  }));

  return { orderItems };
};
