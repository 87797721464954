import { BEConfig } from "../../config/env";
import { CustomerService } from "../../services";

import {
  putDashboardWebApi,
  postDashboardWebApi
} from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import { getVisitorId } from "../../util";
import { retryCall } from "../../util/common";

import {
  CHANGE_CHECKOUT_DATA,
  SET_ADDRESS_DATA,
  CLEAR_CHECKOUT_DATA,
  SET_CHECKOUT_ORDER,
  POPULATE_VOUCHER_CODES,
  REMOVE_APPLIED_VOUCHER,
  CLEAR_GUEST_EMAIL,
  SET_PAYMENT_DATA,
  CHANGE_MAP_ADDRESS_PHONE,
  SET_STANDARD_DELIVERY_ADDRESS,
  SET_MAP_DELIVERY_ADDRESS,
  RESET_CARD_LIST,
  SET_CHECKOUT_REQUEST_DATA,
  SET_INSTORE_DELIVERY_ADDRESS,
  SET_CHECKOUT_COM_FORM_VALIDATION_STATUS,
  SET_CREDIT_CARDS,
  RESET_CREDIT_CARDS,
  SET_SELECTED_CARD_CVV,
  CLEAR_SELECTED_CARD_CVV,
  CLEAR_BIN_CODE,
  SET_BIN_CODE,
  SHOW_CHECKOUT_SUMMARY_LOADER,
  HIDE_CHECKOUT_SUMMARY_LOADER,
  SET_SUMMARY_RECALCULATION_IN_PROGRESS,
  SET_PAYMENT_STATUS_RESPONSE
} from "../constants";
import { VOUCHER } from "../../constants";

export const setPaymentData = () => ({ type: SET_PAYMENT_DATA });
export const changeCheckoutData = (name, value) => ({
  type: CHANGE_CHECKOUT_DATA,
  name,
  value
});

export const showCheckoutSummaryLoadingIndicator = () => ({
  type: SHOW_CHECKOUT_SUMMARY_LOADER
});

export const hideCheckoutSummaryLoadingIndicator = () => ({
  type: HIDE_CHECKOUT_SUMMARY_LOADER
});

export const clearGuestEmail = () => ({ type: CLEAR_GUEST_EMAIL });

export const clearCheckoutData = () => ({ type: CLEAR_CHECKOUT_DATA });

export const removeAppliedVoucher = () => (dispatch, getState) => {
  const state = getState();
  const { selectedPayment } = state.checkoutReducer.checkoutData;
  dispatch(
    changeCheckoutData(
      "selectedPayment",
      (selectedPayment || []).filter(item => item !== VOUCHER)
    )
  );
  dispatch({ type: REMOVE_APPLIED_VOUCHER });
};

export const setBinCode = binNo => ({ type: SET_BIN_CODE, binNo });

export const clearBinCode = () => ({ type: CLEAR_BIN_CODE });

export const setCheckoutOrder = apiResponse => ({
  type: SET_CHECKOUT_ORDER,
  apiResponse
});

export const setAddress = address => ({ type: SET_ADDRESS_DATA, address });

export const setCheckoutRequestData = checkoutRequestData => ({
  type: SET_CHECKOUT_REQUEST_DATA,
  checkoutRequestData
});

export const resetCardList = () => ({ type: RESET_CARD_LIST });

export const populateVoucherCodes = VoucherCodes => ({
  type: POPULATE_VOUCHER_CODES,
  VoucherCodes
});

export const setSummaryRecalculationInProgress = () => dispatch => {
  dispatch({ type: SET_SUMMARY_RECALCULATION_IN_PROGRESS, payload: true });
  dispatch(showCheckoutSummaryLoadingIndicator());
};
export const resetSummaryRecalculationInProgress = () => dispatch => {
  dispatch({ type: SET_SUMMARY_RECALCULATION_IN_PROGRESS, payload: false });
  dispatch(hideCheckoutSummaryLoadingIndicator());
};

export const updateCheckout = () => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, checkoutHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
  return (dispatch, getState) => {
    const { checkoutData } = getState().checkoutReducer;
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      checkoutData,
      getVisitorId()
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateDbCheckoutData = (name, value) => dispatch => {
  dispatch(changeCheckoutData(name, value));
  // dispatch(updateCheckout());
};

export const applyVoucher = (bodyData, isGuestLogin) => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, applyVoucherHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${applyVoucherHandle}/?guest=${isGuestLogin}`;
  return (dispatch, getState) => {
    const { countryId } = getState().common.settings;
    const updatedBody = { ...bodyData, countryId };
    return postDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      updatedBody,
      getVisitorId()
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(resetSummaryRecalculationInProgress());
      });
  };
};

export const voucherList = bodyData => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, getVouchersCode }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${getVouchersCode}`;
  return (dispatch, getState) => {
    const { countryId } = getState().common.settings;
    const { language } = getState().common;
    const updatedBody = { ...bodyData, countryId };
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      updatedBody,
      getVisitorId(),
      language
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(
            populateVoucherCodes(response && response.data.voucherDetails)
          );
        } else {
          dispatch(populateVoucherCodes([]));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateCheckoutById = (id, body) => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, updateCheckoutById }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${updateCheckoutById}/${id}`;
  return (dispatch, getState) => {
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      body,
      getVisitorId()
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleIntlInputTextChange = (
  name,
  value,
  deliveryType,
  status
) => ({
  type: CHANGE_MAP_ADDRESS_PHONE,
  name,
  value,
  deliveryType,
  status
});
export const setStandardDeliveryAddress = address => ({
  type: SET_STANDARD_DELIVERY_ADDRESS,
  address
});
export const setMapDeliveryAddress = address => ({
  type: SET_MAP_DELIVERY_ADDRESS,
  address
});

export const setInstoreDeliveryAddress = address => ({
  type: SET_INSTORE_DELIVERY_ADDRESS,
  address
});

export const setCheckoutComFormValidationStatus = status => ({
  type: SET_CHECKOUT_COM_FORM_VALIDATION_STATUS,
  payload: status
});

export const setCreditCards = data => ({
  type: SET_CREDIT_CARDS,
  payload: data
});
export const resetCreditCards = () => ({
  type: RESET_CREDIT_CARDS
});
export const loadCreditCards = () => async dispatch => {
  try {
    const data = await CustomerService.getCreditCardList();

    dispatch(setCreditCards(data));
  } catch (err) {
    console.error(err);
  }
};

export const setSelectedCardCvv = cvv => ({
  type: SET_SELECTED_CARD_CVV,
  payload: cvv
});

export const clearSelectedCardCvv = () => ({ type: CLEAR_SELECTED_CARD_CVV });

export const deleteCreditCard = id => async dispatch => {
  try {
    await CustomerService.deleteCreditCard(id);

    dispatch(loadCreditCards());
  } catch (err) {
    console.error(err);
  }
};

export const setPaymentStatusResponse = data => ({
  type: SET_PAYMENT_STATUS_RESPONSE,
  payload: data
});
