import { BEConfig } from "../config/env";
import qs from "query-string";
import { ContextStorage } from "@/libs/contextStorage";

import { selectRouteSlug } from "../util/selectors";
import { webapiPost, webapiPut } from "../webapis/core";
import ApiService from "./apiService";
import { PAYMENT_GATEWAYS, TAMARA_PAYMENT_ID, VOUCHER } from "../constants";

class TamaraService extends ApiService {
  startSession = async ({
    order,
    orderBody,
    user,
    currency,
    countryShort,
    orderRecord,
    shippingAddress,
    language,
    orderItems,
    paymentType,
    deliveryCharges
  }) => {
    const {
      checkoutApi: { tamaraSession }
    } = BEConfig;

    const sessionData = this.getSessionData({
      order,
      orderBody,
      user,
      currency,
      countryShort,
      orderRecord,
      shippingAddress,
      language,
      orderItems,
      paymentType,
      deliveryCharges
    });

    const response = await webapiPost(
      this.getAccessToken(),
      `${this.getBaseUrl()}${tamaraSession}`,
      sessionData,
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  };

  getSessionData({
    order,
    orderBody,
    user,
    currency,
    countryShort,
    orderRecord,
    shippingAddress,
    language,
    orderItems,
    paymentType,
    deliveryCharges
  }) {
    return {
      order_reference_id: String(orderRecord.orderId),
      total_amount: {
        amount: orderBody.transactionOrderTotal,
        currency
      },
      description: "BFL store order",
      country_code: countryShort,
      payment_type: paymentType,
      locale: language === "en" ? "en_US" : "ar_SA",
      items: orderItems.map(item => ({
        reference_id: String(item.id),
        type: "Physical",
        name: item.title.label,
        sku: String(item.id),
        item_url: item.url,
        quantity: item.quantity,
        unit_price: {
          amount: item.price.current,
          currency
        },
        total_amount: {
          amount: (item.quantity * item.price.current).toFixed(2),
          currency
        }
      })),
      consumer: {
        email: user.email || shippingAddress.email,
        first_name: shippingAddress.firstname,
        last_name: shippingAddress.lastname,
        phone_number: `+${shippingAddress.phone}`
      },
      shipping_address: {
        first_name: shippingAddress.firstname,
        last_name: shippingAddress.lastname,
        line1: shippingAddress.addressLine1,
        city: shippingAddress.city,
        country_code: countryShort,
        phone_number: shippingAddress.phone
      },
      tax_amount: {
        amount: orderBody.codFee || 0,
        currency
      },
      shipping_amount: {
        amount: deliveryCharges,
        currency
      },
      // ...(orderBody.paymentMode.includes(VOUCHER) && {
      //   discount: {
      //     name: "Voucher",
      //     amount: {
      //       amount: orderBody.transactionDiscount,
      //       currency: currency
      //     }
      //   }
      // }),
      merchant_url: this.getMerchantUrls(orderRecord, orderBody)
    };
  }

  getMerchantUrls(orderRecord, orderBody) {
    const state = ContextStorage.store.getState();
    const routeSlug = selectRouteSlug(state);
    const originLocation = window.location.origin;
    const { countryId } = state.common.settings;
    const { _id } = state.checkoutReducer.checkoutData;
    const successPageUrl = `${originLocation}/${routeSlug}/order-success/${
      orderRecord?.orderId
    }?${qs.stringify({
      continueAsGuest: orderBody.continueAsGuest || "",
      countryId,
      currencyCode: orderBody.paymentCurrency,
      paymentGateWay: PAYMENT_GATEWAYS.TAMARA,
      paymentToken: orderRecord.paymentToken || orderRecord.orderId,
      transactionOrderTotal: orderBody.transactionOrderTotal,
      paymentReferenceNo: orderRecord.paymentToken
    })}`;

    const failedPageUrl = `${originLocation}/${routeSlug}/payment-error`;
    const cancelUrl = `${originLocation}/${routeSlug}/checkout/`;

    return {
      success: successPageUrl,
      failure: failedPageUrl,
      cancel: cancelUrl
    };
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo, checkoutHandle } =
      BEConfig.checkoutApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
    return url;
  }
}

const instance = new TamaraService();

export default instance;
