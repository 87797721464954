import { GET_MEGA_MENU } from "../constants";
import get from "lodash/get";

type MegaMenuState = {
  megaMenuList: any[];
  staticContent?: {
    showSps?: boolean;
    spsRedirectUrl?: string;
    spsTitle?: string;
    imageUrl?: string;
  };
};

const initialState = {
  megaMenuList: []
};

const getMegaMenu = (state: MegaMenuState, { content, language }) => {
  const data = get(content, "data.result.megaMenuList", []);
  const sortedData =
    (data &&
      data.sort((a, b) => {
        const keyA = a.pos,
          keyB = b.pos;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })) ||
    [];

  const spsFilter = [...sortedData];

  spsFilter &&
    spsFilter.sort((a, b) => {
      const keyA = a.isSPS,
        keyB = b.isSPS;
      if (language === "english") {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
      } else {
        if (keyA > keyB) return 1;
        if (keyA < keyB) return -1;
      }

      return 0;
    });

  return {
    ...state,
    megaMenuList: [...spsFilter],
    staticContent: get(content, "data.result.staticContent", {})
  };
};

const megaMenuReducer = (
  state: MegaMenuState = initialState,
  action
): MegaMenuState => {
  switch (action.type) {
    case GET_MEGA_MENU:
      return getMegaMenu(state, action);
    default:
      return state;
  }
};
export default megaMenuReducer;
