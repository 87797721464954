export * from "./common";
export * from "./redirection";
export * from "./help";
export * from "./sizeChart";
export * from "./checkout";
export * from "./payment";
export * from "./url";
export * from "./homepage";
export * from "./signUp";
export * from "./localStorage";
export * from "./sale";

export const QUERY_STRING_CONSTANTS = [
  "BRAND_NAME_SEO",
  "PROMOTION_NAME_POST_HYPHEN",
  "OCCASION_SEO",
  "PROMOTION_NAME",
  "IDEAL_FOR_SEO"
];
export const COUNTRY_NAME_SEO = "COUNTRY_NAME_SEO";
export const SEO_BRAND_TITLE_FORMAT =
  "PROMOTION_NAME_POST_HYPHEN Buy BRAND_NAME_SEO OCCASION_SEO Collection Online | Brands For Less";
export const SEO_BRAND_DESCRIPTION_FORMAT =
  "PROMOTION_NAME Buy BRAND_NAME_SEO OCCASION_SEO Collection for less! Make your purchase online on Brands For Less. Best prices in COUNTRY_NAME_SEO!";
export const PRODUCT_PURCHASE_EXECUTED = "PRODUCT_PURCHASE_EXECUTED";
