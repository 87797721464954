import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSingleSelectFilter from "../hooks/useSingleSelectFilter";
import useSelectedByResponse from "../hooks/useSelectedByResponse";
import { selectedFiltersContext } from ".";
import BottomDropdown from "@/components/bottomDropdown";
import { FilterOption, GeneralFacet } from "../types";

type FilterMultiSelectProps = {
  filter: Omit<GeneralFacet & { data: FilterOption[] }, "type">;
};

export const FilterSortMob: FC<FilterMultiSelectProps> = ({ filter }) => {
  const { t } = useTranslation("productListing");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(selectedFiltersContext);
  const {
    filterOptions,
    selectedOption,
    onFilterOptionClick,
    setSelectedOption
  } = useSingleSelectFilter(filter, onSelection);
  const selectedByResponse = useSelectedByResponse(filterOptions)[0];

  useEffect(() => {
    if (selectedByResponse) {
      updateSelectedFilters({
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: [selectedByResponse]
      });
    }
  }, []);

  useEffect(() => {
    setSelectedOption(selectedByResponse);
    if (!selectedByResponse && selectedFilters[filter.queryParam]) {
      setSelectedFilters(currentFilters => {
        const { [filter.queryParam]: toRemove, ...newFilters } = currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  const closeFilter = e => {
    e?.stopPropagation();
    setIsFilterOpen(false);
  };

  const openFilter = () => {
    setIsFilterOpen(true);
  };

  return (
    <div className="sorting_wrapper" onClick={openFilter}>
      <p className="filter_name">{t("sort")}</p>
      <BottomDropdown
        isOpen={isFilterOpen}
        onClose={closeFilter}
        title={t("sort")}
        selectedOption={selectedOption}
        data={filter.data}
        onOptionClick={onFilterOptionClick}
        className="plp_sort_dropdown_filter"
      />
    </div>
  );
};
