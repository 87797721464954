import { ENV_CONFIG } from "../../config/env";
import { ContextStorage } from "@/libs/contextStorage";

import { selectLanguageSlug, selectCountrySlug } from "../../util/selectors";

import CheckoutService from "../checkoutService";

class CheckoutComService {
  _isSdkLoaded = false;

  async generateTokenFromForm() {
    this.client.enableSubmitForm();

    const data = await this.client.submitCard();

    return data.token;
  }

  pay(paymentData) {
    const state = ContextStorage.store.getState();

    const language = selectLanguageSlug(state);
    const country = selectCountrySlug(state);

    return CheckoutService.payByCheckoutCom({
      ...paymentData,
      language,
      country,
      domain: window.location.origin
    });
  }

  submitCheckoutComForm = async () => {
    this.client.enableSubmitForm();
    return await this.client.submitCard();
  };

  async saveCreditCard(sessionId) {
    try {
      return await CheckoutService.saveCreditCard(sessionId);
    } catch {
      return null;
    }
  }

  getPublicKey() {
    return ENV_CONFIG.checkout_com_public_key;
  }

  get isSdkLoaded() {
    return this._isSdkLoaded;
  }

  get client() {
    return window.Frames;
  }

  resetForm() {
    this.client.init();
  }

  loadSdk() {
    if (this._isSdkLoaded) return Promise.resolve();

    return new Promise((res, rej) => {
      const script = document.createElement("script");
      script.src = "https://cdn.checkout.com/js/framesv2.min.js";

      script.onload = () => {
        this._isSdkLoaded = true;
        //@ts-ignore
        res();
      };
      script.onerror = rej;

      document.body.appendChild(script);
    });
  }
}

const instance = new CheckoutComService();

export default instance;
