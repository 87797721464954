import React, { FC, memo, useMemo, useState } from "react";
import cn from "clsx";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { areEqual, FixedSizeList as List } from "react-window";
import { useHistory } from "react-router-dom";

import { itemsInCart } from "@/util";
import * as priceUtil from "@/util/priceUtil";
import { useIsRTL, useRouteSlug } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import withClientOnlyRender from "@/hocs/withClientOnlyRender";
import CartService, {
  Cart,
  OrderSummaryDeliveryDetails
} from "@/services/cartService";
import CheckoutAnalyticsEvent from "@/services/analytics/main/checkout";
import { checkoutSlice } from "@/redux/features";
import ProductItem from "./productItem";
import { DELIVERY_TYPES } from "@/constants";
import { selectLoggedInStatus } from "@/util/selectors";
import CustomScrollbars from "@/components/customScrollbars";
import { AnalyticService } from "@/services";
import { useGetOrderSummary } from "@/routes/cart/hooks/useGetOrderSummary";

const ProductListDropdown: FC<{
  isOpen: boolean;
  cart: Cart;
  isAddedNewProduct: boolean;
  moveToCart: () => void;
}> = ({ isOpen, cart = [], isAddedNewProduct, moveToCart }) => {
  const isRTL = useIsRTL();
  const routeSlug = useRouteSlug();

  const currency = useAppSelector(state => state.common.settings?.currencyCode);
  const isLoggedIn = useAppSelector(selectLoggedInStatus);
  const commonSettings = useAppSelector(state => state.common.settings);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { t } = useTranslation("common");

  const total = useMemo(
    () =>
      cart
        .map(product => {
          const quantity = product.quantity ?? 1;

          return (product.price?.current ?? 0) * quantity;
        })
        .reduce((total, num) => total + num, 0),
    [cart]
  );
  const isPriceAvailableForAllProducts = useMemo(
    () =>
      isEmpty(cart)
        ? true
        : cart.every(product => product.isPriceAvailableInUserCountry),
    [cart]
  );
  const count = useMemo(() => itemsInCart(cart), [cart]);

  const sortedCart = useMemo(
    () =>
      [...cart].sort(product =>
        product.isPriceAvailableInUserCountry ? 0 : -1
      ),
    [cart]
  );

  const validateCheckout = async () => {
    try {
      const validationData = await CartService.validateCheckout(
        DELIVERY_TYPES.DELIVERY_ADDRESS
      );

      dispatch(checkoutSlice.actions.setValidationData(validationData));
    } catch (e) {
      if (e.response.data && !e.response.data.success) {
        return history.push(`/${routeSlug}/my-cart`);
      }
    }

    if (!isLoggedIn) {
      history.push(`/${routeSlug}/login?cartRedirect=true`);
    } else {
      history.push(`/${routeSlug}/checkout/`);
    }
    CheckoutAnalyticsEvent.beginCheckout({
      value: total,
      items: cart
    });

    const orderSummary = await CartService.getCartSummary({
      deliveryType: DELIVERY_TYPES.DELIVERY_ADDRESS
    });

    AnalyticService.checkout.trackCheckout({
      orderItems: cart,
      totalAmount: orderSummary.priceDetails?.totalPrice,
      subTotal: orderSummary.priceDetails?.subTotal,
      deliveryType: DELIVERY_TYPES.DELIVERY_ADDRESS
    });
  };

  const listHeight = sortedCart.length === 1 ? 120 : 250;
  // 120px cart_item height + 14px for "marginBottom"
  const listItemSize = sortedCart.length === 1 ? 120 : 134;

  return (
    <div
      className={cn("cart_hover_dropdown_wrapper", {
        arabic: isRTL,
        active: isOpen || isAddedNewProduct
      })}
    >
      <h4>
        {t("header.myCart")}
        <span>
          {count} {count === 1 ? t("header.item") : t("header.items")}
        </span>
      </h4>

      {Boolean(count) && (
        <div className="inner_wrapper">
          {isAddedNewProduct && (
            <h5 className="floating_message">
              1 {t("header.itemAddedInCart")}
            </h5>
          )}
          <div className="items_in_user_cart">
            <List
              height={listHeight}
              width={313}
              itemCount={sortedCart.length}
              itemData={sortedCart}
              itemSize={listItemSize}
              outerElementType={CustomScrollbars}
            >
              {CartItemRow}
            </List>
          </div>
          <div className="subtotal">
            {t("header.subtotal")}{" "}
            <span>
              {" "}
              {currency}{" "}
              {total
                ? priceUtil.shapePriceWithComma(
                    total,
                    commonSettings.decimalPlaces
                  )
                : 0}
            </span>
          </div>

          <div className="cart_dropdown_action">
            <button className="small_grey_btn" onClick={moveToCart}>
              {t("header.viewBag")}
            </button>
            <button
              className={cn("small_yellow_btn", {
                disabled: !isPriceAvailableForAllProducts
              })}
              onClick={validateCheckout}
              disabled={!isPriceAvailableForAllProducts}
            >
              {t("header.checkout")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const CartItemRow: FC<{
  data: Cart;
  index: number;
  style: React.CSSProperties;
}> = memo(
  ({ data, index, style }) => (
    <ProductItem product={data[index]} style={style} />
  ),
  areEqual
);

export default withClientOnlyRender(memo(ProductListDropdown));
