import React, { Component } from "react";
import cn from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import ReactHtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { DROP_DOWN_SCROLL_TYPES } from "../../constants";
import { isServer } from "../../util";
import { CircleFlag } from "react-circle-flags";
import { SearchInput } from "../common/select/searchInput";

// TODO: this is deprecated, use /components/dropdown instead
class CustomDropdown extends Component {
  state = {
    isToggle: false,
    searchInputValue: "",
    filteredData: []
  };

  componentDidMount() {
    const { data } = this.props;
    this.handleScroll();
    if (Array.isArray(data)) {
      this.setState({ filteredData: [...data] });
    }
  }

  componentWillUnmount() {
    this.handleScroll();
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("scroll", this.scrollOutside);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (Array.isArray(data) && !isEqual(prevProps.data, data)) {
      this.setState({ filteredData: [...data] });
    }
  }

  scrollOutside = () => {
    const { isToggle } = this.state;
    const { withSearchInput } = this.props;

    if (isToggle) {
      this.setState({ isToggle: false });
      withSearchInput && this.resetSearchInputData();
    }
  };

  resetSearchInputData = () => {
    const { data } = this.props;
    this.setState({ searchInputValue: "" });
    this.setState({ filteredData: [...data] });
  };

  handleClickOutside = e => {
    const eventClassName = get(e, "target.className");
    const classNameEvent =
      eventClassName && !eventClassName?.includes?.("filter_open");
    const refWrapper = this.wrapperRef && !this.wrapperRef.contains(e.target);

    if (classNameEvent && refWrapper) {
      e.stopPropagation();
      const { isToggle } = this.state;
      const { withSearchInput } = this.props;

      if (isToggle) {
        this.setState({ isToggle: false });
        withSearchInput && this.resetSearchInputData();
      }
      this.props.addExtraClass && this.props.addExtraClass(e, false);
    }
  };

  setExtraClass = e => {
    const eventClientSizeX = get(e, "clientX") + 300;

    if (!isServer && eventClientSizeX > window.screen.width) {
      this.setState({ extraClass: "openLeft" });
    } else if (eventClientSizeX < 500) {
      this.setState({ extraClass: "openRight" });
    } else {
      this.setState({ extraClass: "" });
    }
  };

  handleFilterToggle = e => {
    const {
      name,
      scrollType = DROP_DOWN_SCROLL_TYPES.DEFAULT,
      withSearchInput,
      onClickForEvent
    } = this.props;
    const { isToggle } = this.state;

    e.stopPropagation();
    this.setExtraClass(e);
    onClickForEvent && onClickForEvent(name);

    switch (scrollType) {
      case DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITH_INLINE_SCROLL:
        this.setState(prevState => ({ isToggle: true }));
        break;
      default:
        this.setState(
          prevState => ({ isToggle: !prevState.isToggle }),
          () => !isToggle && withSearchInput && this.resetSearchInputData()
        );
        break;
    }
    this.props.addExtraClass && this.props.addExtraClass(e, true);
  };

  handleChange = item => {
    const disableOption = get(this.props, "disableOption", "");
    const isDisabled =
      get(item, "props.isDisabled", "") || get(item, "disableOption", "");

    if (disableOption === true && isDisabled === true) {
      setTimeout(() => {
        this.setState({ isToggle: true });
      }, 50);
      return false;
    }
    const { scrollType = DROP_DOWN_SCROLL_TYPES.DEFAULT, withSearchInput } =
      this.props;

    switch (scrollType) {
      case DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITH_INLINE_SCROLL:
        setTimeout(() => {
          this.setState({ isToggle: false });
          withSearchInput && this.resetSearchInputData();
        }, 150);
        break;
      default:
        break;
    }
    this.props.handleChange(item);
  };

  handleScroll = () => {
    const { scrollType = DROP_DOWN_SCROLL_TYPES.DEFAULT } = this.props;
    switch (scrollType) {
      case DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITH_INLINE_SCROLL:
      case DROP_DOWN_SCROLL_TYPES.DEFAULT:
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("scroll", this.scrollOutside);
        break;
      case DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITHOUT_INLINE_SCROLL:
        document.addEventListener("mousedown", this.handleClickOutside);
        break;
      default:
        break;
    }
  };

  defaultToggleImage = () => <div className="toggleImage" />;

  hasMultipleOptions = () => get(this.props, "data.length") > 0;

  onSearchValueChange = value => {
    const { data } = this.props;
    const filteredData = data.filter(item =>
      item.value.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({ filteredData });
  };

  render() {
    const {
      alwaysShowDropdownIcon,
      containerClass = "",
      defaultSelectValue,
      flagImage,
      forceTitle,
      modalClass = "",
      name,
      renderToggleImage,
      scrollbarMaxHeight,
      scrollbarMinHeight,
      selectedItem,
      style,
      valid,
      canDropdownToggle = true,
      readonly,
      withSearchInput,
      dropdownClass,
      t
    } = this.props;
    const { isToggle, filteredData, searchInputValue } = this.state;

    const selectedData = selectedItem || defaultSelectValue;
    const valueToParse = get(selectedData, "value");
    const spanContent = valueToParse
      ? ReactHtmlParser(valueToParse)
      : selectedData;

    const isHandleClickAvailable =
      !readonly && !isEmpty(filteredData) && canDropdownToggle;

    console.warn(
      "CustomDropdown is deprecated. Use /components/dropdown instead."
    );
    return (
      <div
        ref={node => (this.wrapperRef = node)}
        className={
          readonly
            ? cn("read-only", containerClass)
            : cn("dropdown_container", containerClass, {
                borderRed: !valid && typeof valid === "boolean",
                clickable: this.hasMultipleOptions(),
                filter_open: isToggle,
                zero_qty: !filteredData?.length && !alwaysShowDropdownIcon
              })
        }
        onClick={isHandleClickAvailable ? this.handleFilterToggle : undefined}
      >
        {flagImage ? (
          <span className="white-space-nowrap">
            <CircleFlag
              countryCode={flagImage.toLowerCase()}
              width={18}
              className="country_image"
            />{" "}
            <span className="country_name">{spanContent}</span>
          </span>
        ) : (
          <span className={cn({ expiry_details: selectedItem })}>
            <div
              className={
                readonly
                  ? "read-only-prefix"
                  : cn({
                      expiry_details_content_container: selectedItem
                    })
              }
            >
              {forceTitle || spanContent}
            </div>
            {this.hasMultipleOptions() &&
              (renderToggleImage
                ? renderToggleImage()
                : this.defaultToggleImage())}
          </span>
        )}
        <div className="modalContainer">
          {isToggle && (
            <div
              ref={this.setWrapperRef}
              style={style}
              className={`${modalClass} showModal`}
              onClick={this.stopPropagation}
            >
              {readonly ? (
                <div>{defaultSelectValue}</div>
              ) : (
                <>
                  {withSearchInput && (
                    <SearchInput
                      setSearchValue={searchInputValue}
                      onSearchValueChange={this.onSearchValueChange}
                      placeholder={t("typeHere")}
                    />
                  )}
                  <Scrollbars
                    universal
                    autoHeight
                    autoHeightMin={scrollbarMinHeight || 25}
                    autoHeightMax={
                      (scrollbarMaxHeight || 250) - (withSearchInput ? 50 : 0)
                    }
                    renderTrackHorizontal={props => (
                      <div
                        {...props}
                        style={{ display: "none" }}
                        className="track-horizontal"
                      />
                    )}
                    renderTrackVertical={props => (
                      <div
                        {...props}
                        style={{ display: "none" }}
                        className="track-vertical"
                      />
                    )}
                    className={dropdownClass}
                  >
                    {filteredData.length ? (
                      <ul className="dropdown_content_container" id={name}>
                        {map(filteredData, (item, index) => (
                          <li
                            className={cn({
                              selected_value: defaultSelectValue === item
                            })}
                            key={item.key ? item.key : index}
                            onClick={() => {
                              this.handleChange(item.key ? item.key : item);
                            }}
                          >
                            {item.countryName ? (
                              <>
                                <CircleFlag
                                  className="country_image"
                                  countryCode={item.key?.toLowerCase()}
                                  width={18}
                                />{" "}
                                <span className="country_name">
                                  {item.countryName}
                                </span>
                              </>
                            ) : item.value ? (
                              ReactHtmlParser(item.value)
                            ) : (
                              item
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="no_data_found">{t("noDataFound")}</p>
                    )}
                  </Scrollbars>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation("checkout")(CustomDropdown);
