import {
  SET_CART_SECTIONS,
  SET_CHECKOUT_SECTIONS,
  SET_HEADER_SECTIONS,
  SET_ORDER_SUCCESS_SECTIONS,
  SET_PDP_SECTIONS
} from "../constants/sections";

type SectionsState = {
  headerSections: {
    headerBottom: any | null;
    headerShopByStore: any | null;
  };
  pdpSections: {
    pdpTop: any | null;
    pdpSectionOne: any | null;
    pdpSectionTwo: any | null;
  };
  cartSections: {
    cartTop: any | null;
    cartVat: any | null;
  };
  checkoutSections: {
    checkoutTop: any | null;
  };
  orderSuccessSections: {
    orderSuccessTop: any | null;
    orderSuccessSectionOne: any | null;
  };
};

const initialState: SectionsState = {
  headerSections: {
    headerBottom: null,
    headerShopByStore: null
  },
  pdpSections: {
    pdpTop: null,
    pdpSectionOne: null,
    pdpSectionTwo: null
  },
  cartSections: {
    cartTop: null,
    cartVat: null
  },
  checkoutSections: {
    checkoutTop: null
  },
  orderSuccessSections: {
    orderSuccessTop: null,
    orderSuccessSectionOne: null
  }
};

const setHeaderSections = (state: SectionsState, action) => ({
  ...state,
  headerSections: action.sections
});

const setPDPSections = (state: SectionsState, action) => ({
  ...state,
  pdpSections: action.sections
});

const setCartSections = (state: SectionsState, action) => ({
  ...state,
  cartSections: action.sections
});

const setCheckoutSections = (state: SectionsState, action) => ({
  ...state,
  checkoutSections: action.sections
});

const setOrderSuccessSections = (state: SectionsState, action) => ({
  ...state,
  orderSuccessSections: action.sections
});

const sectionsReducer = (
  state: SectionsState = initialState,
  action
): SectionsState => {
  switch (action.type) {
    case SET_HEADER_SECTIONS:
      return setHeaderSections(state, action);
    case SET_PDP_SECTIONS:
      return setPDPSections(state, action);

    case SET_CART_SECTIONS:
      return setCartSections(state, action);

    case SET_CHECKOUT_SECTIONS:
      return setCheckoutSections(state, action);

    case SET_ORDER_SUCCESS_SECTIONS:
      return setOrderSuccessSections(state, action);

    default:
      return state;
  }
};

export default sectionsReducer;
