import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import find from "lodash/find";
import { Image } from "../../image";
import IntlInput from "../../intlTelInput/intlTelInput";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { handleAppDownloadInfo } from "@/redux/actions/myAccount.action";
import { handleTextChange } from "@/redux/actions/address.action";
import { OtpService } from "@/services";
import { sleep } from "@/util/common";
import {
  selectAppDownloadPhone,
  selectCountryData,
  selectCountryShort,
  selectMobileCountryCode,
  selectMobileLocalCode,
  selectMobileLocalNumberLength
} from "@/util/selectors";
import "./style.scss";

const DynamicDownloadMobileApp: FC<{
  bannerData: { key: string; value: string }[];
}> = ({ bannerData }) => {
  const { t } = useTranslation("mobileAppReference");
  const dispatch = useAppDispatch();
  const appDownloadPhone = useAppSelector(selectAppDownloadPhone);
  const countryData = useAppSelector(selectCountryData);
  const countrySHORT = useAppSelector(selectCountryShort);
  const mobileLocalNumberLength = useAppSelector(selectMobileLocalNumberLength);
  const mobileCountryCode = useAppSelector(selectMobileCountryCode);
  const mobileLocalCode = useAppSelector(selectMobileLocalCode);

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [areaCode, setAreaCode] = useState(null);
  const [showMessageSentText, setShowMessageSentText] = useState(false);

  const qrCodeSrc = useMemo(() => {
    return (find(bannerData, { key: "qrCodeUrl" }) || {}).value;
  }, [bannerData]);

  useEffect(() => {
    const areaCodes = mobileLocalCode && mobileLocalCode.split(",");
    if (areaCodes) {
      setAreaCode(areaCodes[0]);
    }
  }, [mobileLocalCode]);

  const handleCountryChange = (status, value) => {
    setMobileNumber(value);
    dispatch(handleAppDownloadInfo("phone", value, status));
  };

  const onSelectFlag = (a, b) => {
    dispatch(handleTextChange("phone", ""));
    dispatch(handleTextChange("dialCode", b.dialCode));
  };

  const onSelectArea = area => {
    dispatch(handleTextChange("phone", ""));
    dispatch(handleTextChange("areaCode", area));
    setAreaCode(area);
  };

  const sendMessage = async () => {
    if (!mobileNumber)
      return dispatch(handleAppDownloadInfo("phone", "", false));
    if (mobileNumber.length !== mobileLocalNumberLength) return;

    const fullPhoneNumber = `${mobileCountryCode}${areaCode}${mobileNumber}`;

    try {
      if (errorMessage) {
        setErrorMessage(null);
      }

      await OtpService.sendAppDownloadSms(fullPhoneNumber);

      dispatch(handleAppDownloadInfo("phone", "", true));

      setShowMessageSentText(true);
      setMobileNumber("");
      await sleep(2000);
      setShowMessageSentText(false);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.message);
    }
  };

  return (
    <section className="qr-code-section">
      <div className="download-page-wrap">
        <div className="qrCode-Container">
          <Image
            src={qrCodeSrc}
            alt="qr code"
            className="qrcode-app"
            loader={<div className="qrcode-app_loader" />}
          />
        </div>
        <div className="qrCode-desc-container">
          <h3 className="qrcode-description">{t("qrCodeDescription")}</h3>
          <div className="form_wrapper">
            <div className="form-group">
              <IntlInput
                valid={appDownloadPhone.errors.phone.valid}
                phone={appDownloadPhone.info.phone || ""}
                handleCountryChange={handleCountryChange}
                areaCode={areaCode}
                onSelectFlag={onSelectFlag}
                countryData={countryData}
                defaultCountry={countrySHORT}
                mobileLocalNumberLength={mobileLocalNumberLength}
                onSelectArea={onSelectArea}
              />

              {errorMessage && <span className="error">{errorMessage}</span>}
              {!appDownloadPhone.errors.phone.valid && (
                <span className="error">{t("invalidPhoneNumberMessage")}</span>
              )}
              {showMessageSentText && (
                <span className="success-message">{t("messageSentText")}</span>
              )}
            </div>
            <button className="Send-Button" onClick={sendMessage}>
              {t("buttonText")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DynamicDownloadMobileApp;
