import React, { useEffect, useRef, useState } from "react";
import cn from "clsx";
import "./style.scss";
//

type MobileSwipeableListProps = {
  classname: string;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  head: any;
  children: JSX.Element;
};

const MobileSwipeableList: React.FC<MobileSwipeableListProps> = ({
  classname,
  open,
  onOpen,
  onClose,
  head,
  children
}) => {
  const [startYPosition, setStartYPosition] = useState(null);
  const drawerRef = useRef(null);

  const handleTouchStart = e => {
    setStartYPosition(e.touches[0].clientY);
  };

  const handleTouchMove = e => {
    const deltaYPosition = e.touches[0].clientY - startYPosition;
    if (deltaYPosition > 50) {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    const drawerClassList = drawerRef.current.classList;
    drawerClassList.add("closing");
    setTimeout(() => {
      onClose();
      drawerClassList.remove("closing");
    }, 400);
  };
  ``;
  return (
    <div className={cn("drawer_wrapper", { [classname]: !!classname })}>
      {open && (
        <div className="drawer_content">
          <div className="overlay_class"></div>
          <div
            className={cn("drawer", {
              open
            })}
            ref={drawerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div className="drawer_heading">
              {head && head}
              <div className="cross_button" onClick={closeDrawer}></div>
            </div>
            <div>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSwipeableList;
