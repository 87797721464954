import qs from "query-string";
import isArray from "lodash/isArray";

import { BEConfig } from "../config/env";
import { generateUUID } from "../util";

import { webapiGet, webapiPost } from "../webapis/core";

import ApiService from "./apiService";

class CommonService extends ApiService {
  async getCountryList(language) {
    const { settingsHandle } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${settingsHandle}`;
    const queryParams = qs.stringify({
      language
    });
    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      generateUUID()
    ).request;

    //@ts-ignore
    if (!isArray(response.data)) throw new Error(response);

    return response.data;
  }

  async getShortCountryList(language) {
    const { settingsHandle, shortCountryList } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${settingsHandle}${shortCountryList}`;
    const queryParams = qs.stringify({
      language
    });
    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      generateUUID()
    ).request;

    //@ts-ignore
    if (!isArray(response.data)) throw new Error(response);

    return response.data;
  }

  async getCountriesMobileDetails() {
    const { settingsHandle, mobileDetails } = BEConfig.commonApi;
    const apiURL = `${this.getBaseUrl()}${settingsHandle}`;
    const endpoint = `${apiURL}${mobileDetails}`;
    const response = await webapiGet(this.getAccessToken(), endpoint).request;

    //@ts-ignore
    if (!isArray(response.data)) throw new Error(response);

    return response.data;
  }

  async getPayLaterOptionsList() {
    const { settingsHandle, payLaterOptions } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${settingsHandle}${payLaterOptions}`;
    const response = await webapiGet(this.getAccessToken(), apiURL).request;

    return response.data;
  }

  async createVideoRecord(
    data: CreateVideoRecord
  ): Promise<CreateVideoRecordPayload> {
    const { videoRecordHandle } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${videoRecordHandle}`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { ...data },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async getVideoRecord(videoId: string): Promise<GetVideoRecordPayload> {
    const { videoRecordWatched } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${videoRecordWatched}/${videoId}`;
    const response = await webapiGet(this.getAccessToken(), apiURL).request;

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.commonApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

type GetVideoRecordPayload = {
  videoId: string;
  isWatched: boolean;
  message?: string;
};

type CreateVideoRecordPayload = {
  name: string;
  mobileNumber: string;
  email: string;
  videoId: string;
  customerId?: number;
  visitorId?: string;
  message?: string;
  _id?: string;
  __v?: number;
};

export type CreateVideoRecord = {
  name: string;
  mobileNumber: string;
  email: string;
  videoId: string;
};

const instance = new CommonService();

export default instance;
