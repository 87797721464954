import React, {
  useRef,
  useState,
  useEffect,
  memo,
  useCallback,
  useMemo
} from "react";
import cn from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import { LOADER_TYPES } from "@/constants";

import { useRouteSlug } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { sleep } from "@/util/common";

import {
  selectCartGeneralCount,
  selectWishListAndCartGeneralLoaded
} from "@/util/selectors";
import { fetchCartData } from "@/redux/actions/cart.action";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import Loader from "../../../loader";
import ProductListDropdown from "./productListDropdown";
import "./style.scss";

const CartIcon = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddedNewProduct, setIsAddedNewProduct] = useState(false);
  const [isCartPreviouslyHovered, setIsCartPreviouslyHovered] = useState(false);

  const routeSlug = useRouteSlug();

  const cart = useAppSelector(state => state.cartReducer.cart);
  const currency = useAppSelector(state => state.common.settings?.currencyCode);

  const cartGeneralCount = useAppSelector(selectCartGeneralCount);
  const wishListAndCartBaseDataLoaded = useAppSelector(
    selectWishListAndCartGeneralLoaded
  );

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const previousCartCount = useRef(cartGeneralCount);

  const totalCartProductQuantity = cart.reduce((total, product) => {
    return !product.errors && product.isSelected
      ? total + product.quantity
      : total;
  }, 0);

  useEffect(() => {
    (async () => {
      if (!wishListAndCartBaseDataLoaded) return;
      const isCartPage = location.pathname.includes("/my-cart");
      if (isCartPage) {
        previousCartCount.current = cartGeneralCount;
        return;
      }

      if (
        cartGeneralCount !== previousCartCount.current ||
        cartGeneralCount !== totalCartProductQuantity
      ) {
        try {
          setIsLoading(true);

          await dispatch(fetchCartData());

          setIsLoading(false);
        } catch (error) {
          console.error("Fetching cart error: ", error);
        }

        if (
          cartGeneralCount > previousCartCount.current &&
          cartGeneralCount === totalCartProductQuantity
        ) {
          previousCartCount.current = cartGeneralCount;
          await sleep(1000);
          setIsAddedNewProduct(true);
          await sleep(4000);
          setIsAddedNewProduct(false);
        } else {
          previousCartCount.current = cartGeneralCount;
        }
      }
    })();
  }, [
    wishListAndCartBaseDataLoaded,
    cartGeneralCount,
    location,
    isCartPreviouslyHovered
  ]);

  const dropdownProducts = useMemo(() => {
    const filtered = cart.filter(product => product.isSelected);
    return filtered.length > 0 ? filtered : cart;
  }, [cart]);

  const timerId = useRef<NodeJS.Timeout>(null);

  const handleDropdownHover = (isHovered: boolean) => {
    if (!isCartPreviouslyHovered) setIsCartPreviouslyHovered(true);

    if (isHovered) {
      timerId.current = setTimeout(() => {
        setDropdownOpen(true);
        if (Boolean(cartGeneralCount)) {
          CommonAnalyticsEvent.viewMiniCart();
        }

        clearTimeout(timerId.current);
        timerId.current = null;
      }, 300);
    } else {
      clearTimeout(timerId.current);
      setDropdownOpen(false);
    }
  };

  const moveToCart = useCallback(() => {
    history.push(`/${routeSlug}/my-cart/`);
  }, [currency, routeSlug, history]);

  return (
    <div
      className="cart"
      onMouseEnter={() => handleDropdownHover(true)}
      onMouseLeave={() => handleDropdownHover(false)}
    >
      {isLoading && (
        <Loader
          type={LOADER_TYPES.DOTS}
          visible
          style={loaderStyle}
          size={10}
        />
      )}
      <div>
        <span
          className={cn("cart_icon", {
            invisible: isLoading
          })}
          onClick={moveToCart}
        />
        {Boolean(cartGeneralCount) && (
          <>
            <span
              className={cn("items_in_bucket", {
                invisible: isLoading
              })}
              onClick={moveToCart}
            >
              {cartGeneralCount >= 100 ? "99+" : cartGeneralCount}
            </span>
            <ProductListDropdown
              cart={dropdownProducts}
              isOpen={isDropdownOpen}
              isAddedNewProduct={isAddedNewProduct}
              moveToCart={moveToCart}
            />
          </>
        )}
      </div>
    </div>
  );
};

const loaderStyle = {
  top: "0px",
  left: "15px",
  right: "0px",
  bottom: "0px",
  backgroundColor: "inherit",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export default memo(CartIcon);
