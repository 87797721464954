import { BROWSER, DESKTOP, LANGUAGE_ROUTE_KEY, MOBILE } from "../../constants";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  CHANGE_LANGUAGE,
  COMMON_DATA,
  SET_STATES_DATA,
  SET_LOYALITY_POINTS,
  SET_LOYALTY_PROGRAM_STATUS,
  SHOW_ERROR_PAGE,
  HIDE_ERROR_PAGE,
  USER_ORIGIN_COUNTRY,
  HIDE_PAGE_LOADER,
  SHOW_PAGE_LOADER,
  SET_PAYPAL_TOKEN,
  SET_COUNTRIES_MOBILE_DETAILS,
  SET_SELECTED_COUNTRY_MOBILE_DETAILS,
  SET_DEVICE_TYPE,
  SET_CONFIG_SETTINGS,
  SET_IS_CHECKED_LOYALTY_PROGRAM,
  SET_IS_SERVER_LOADED_REDUX_STATE,
  SET_IS_HEADER_FIXED,
  SET_COUNTRY_ID
} from "../constants";

import { languageFromPathName, isServer } from "../../util";
import { getDeviceInfo } from "../../util/common";

type ErrorPage = {
  is404: boolean;
  is500: boolean;
  is503: boolean;
};

type PageLoader = {
  visible: boolean;
  callCount: number;
};

type ConfigSettings = {
  lisaUrl?: any;
  cdnDomain?: any;
  cdnImages?: any;
  default?: any;
  syte?: any;
  customerSupportDetails?: any;
  messages?: any;
  myAccount?: any;
};

type GeneralInfo = {
  deviceInfo: any;
  appVersion: string;
};

type SubscribeNow = {
  email: string;
};

type CountryData = any[];
type IpCountryData = Record<string, any>;
type UserOriginCountryData = Record<string, any>;
type SelectedCountryMobileDetails = Record<string, any>;
type CountriesMobileDetails = any[];

type CommonState = {
  language: string;
  showLoader: boolean;
  apiCount: number;
  loyalityPoints: number;
  generalInfo: GeneralInfo;
  subscribeNow: SubscribeNow;
  settings: Record<string, any>;
  countryData: CountryData;
  ipCountryData: IpCountryData;
  errorPage: ErrorPage;
  userOriginCountryData: UserOriginCountryData;
  isHomePageRedux: boolean;
  pageLoader: PageLoader;
  selectedSizeType: string;
  countriesMobileDetails: CountriesMobileDetails;
  selectedCountryMobileDetails: SelectedCountryMobileDetails;
  isJoinedToLoyaltyProgram: boolean;
  deviceType: string;
  configSettings: ConfigSettings;
  isCheckedLoyaltyProgram: boolean;
  isServerLoadedReduxState: boolean;
  isHeaderFixed: boolean;
  payPalToken?: string;
};

const deviceInfo = getDeviceInfo();

const initialState: CommonState = {
  language: LANGUAGE_ROUTE_KEY.english,
  showLoader: true,
  apiCount: 0,
  loyalityPoints: 0,
  generalInfo: {
    deviceInfo: deviceInfo,
    appVersion: "1.0"
  },
  subscribeNow: {
    email: ""
  },
  settings: {},
  countryData: [],
  ipCountryData: {},
  errorPage: {
    is404: false,
    is500: false,
    is503: false
  },
  userOriginCountryData: {},
  isHomePageRedux: false,
  pageLoader: {
    visible: false,
    callCount: 0
  },
  selectedSizeType: "",
  countriesMobileDetails: [],
  selectedCountryMobileDetails: {},
  isJoinedToLoyaltyProgram: false,
  deviceType: "",
  configSettings: {
    cdnDomain: null,
    cdnImages: null,
    default: null,
    syte: null,
    customerSupportDetails: null
  },
  isCheckedLoyaltyProgram: false,
  isServerLoadedReduxState: isServer,
  isHeaderFixed: false
};

const showErrorPage = (state: CommonState, { key, value }) => ({
  ...state,
  errorPage: { ...state.errorPage, [key]: value }
});

const hideErrorPage = (state: CommonState) => ({
  ...state,
  errorPage: {
    ...state.errorPage,
    is404: false,
    is500: false,
    is503: false
  }
});

const showLoader = (state: CommonState) => ({
  ...state,
  showLoader: true,
  apiCount: state.apiCount + 1
});

const hideLoader = (state: CommonState) => {
  const data = {
    ...state,
    apiCount: state.apiCount - 1
  };
  if (state.apiCount - 1 < 1) {
    data.showLoader = false;
    data.apiCount = 0;
  }
  return data;
};

const setCommonData = (state: CommonState, action) => ({ ...state, ...action });

const setStatesData = (state: CommonState, action) => ({
  ...state,
  settings: { ...state.settings, state: action.state }
});

const setLoyaltyPoints = (state: CommonState, { loyaltyPoints }) => ({
  ...state,
  loyaltyPoints
});

const setLoyaltyProgramStatus = (
  state: CommonState,
  { isJoinedToLoyaltyProgram }
) => ({
  ...state,
  isJoinedToLoyaltyProgram
});

const setGeneralInfo = (state: CommonState, action) => ({
  ...state,
  generalInfo: {
    ...state.generalInfo,
    ...action.generalInfo
  }
});

const userOriginCountry = (state: CommonState, action) => ({
  ...state,
  userOriginCountryData: {
    ...action.userOriginCountry,
    mobileLocalNumberLength: action.userOriginCountry.mobileNumberCriteria
      ?.acceptableLength
      ? Math.max(
          ...action.userOriginCountry.mobileNumberCriteria.acceptableLength
        )
      : action.userOriginCountry.mobileLocalNumberLength
  }
});

const changeLanguage = (state: CommonState, action) => ({
  ...state,
  language: languageFromPathName(action.name)
});

const showPageLoader = (state: CommonState, action) => {
  return {
    ...state,
    pageLoader: {
      visible: true,
      callCount: state.pageLoader.callCount + 1,
      ...action.payload
    }
  };
};
const hidePageLoader = (state: CommonState, action) => {
  const callCount = state.pageLoader.callCount - 1;
  if (callCount < 0) {
    console.error("Hiding loader without setting it visible previously");
  }
  return {
    ...state,
    pageLoader: {
      visible: callCount > 0,
      callCount: callCount > 0 ? callCount : 0,
      ...action.payload
    }
  };
};
const setPayPalToken = (state: CommonState, payPalToken) => ({
  ...state,
  payPalToken
});

const setCountriesMobileDetails = (state: CommonState, action) => ({
  ...state,
  countriesMobileDetails: action.payload
});
const setSelectedCountryMobileDetails = (state: CommonState, action) => ({
  ...state,
  selectedCountryMobileDetails: action.payload
});

const setDeviceType = (state: CommonState, action) => {
  const deviceType = action.payload === BROWSER ? DESKTOP : action.payload;

  return {
    ...state,
    deviceType
  };
};

const setConfigSettings = (state: CommonState, { configs }) => ({
  ...state,
  configSettings: configs
});

const setIsCheckedLoyaltyProgram = (
  state: CommonState,
  { isCheckedLoyaltyProgram }
) => ({
  ...state,
  isCheckedLoyaltyProgram
});

export const setIsServerLoadedReduxState = (
  state: CommonState,
  { payload }
) => ({
  ...state,
  isServerLoadedReduxState: payload
});

const setIsHeaderFixed = (state: CommonState, { isHeaderFixed }) => ({
  ...state,
  isHeaderFixed
});

const setCountryId = (state: CommonState, action) => ({
  ...state,
  settings: { ...state.settings, countryId: action.payload }
});

const commonReducer = (
  state: CommonState = initialState,
  action
): CommonState => {
  switch (action.type) {
    case SHOW_LOADER:
      return showLoader(state);
    case HIDE_LOADER:
      return hideLoader(state);
    case CHANGE_LANGUAGE:
      return changeLanguage(state, action);
    case COMMON_DATA:
      return setCommonData(state, action);
    case SET_STATES_DATA:
      return setStatesData(state, action);
    case SET_LOYALITY_POINTS:
      return setLoyaltyPoints(state, action);
    case SHOW_ERROR_PAGE:
      return showErrorPage(state, action);
    case HIDE_ERROR_PAGE:
      return hideErrorPage(state);
    case USER_ORIGIN_COUNTRY:
      return userOriginCountry(state, action);
    case SHOW_PAGE_LOADER:
      return showPageLoader(state, action);
    case HIDE_PAGE_LOADER:
      return hidePageLoader(state, action);
    case SET_PAYPAL_TOKEN:
      return setPayPalToken(state, action.payload);
    case SET_COUNTRIES_MOBILE_DETAILS:
      return setCountriesMobileDetails(state, action);
    case SET_SELECTED_COUNTRY_MOBILE_DETAILS:
      return setSelectedCountryMobileDetails(state, action);
    case SET_LOYALTY_PROGRAM_STATUS:
      return setLoyaltyProgramStatus(state, action);
    case SET_DEVICE_TYPE:
      return setDeviceType(state, action);
    case SET_CONFIG_SETTINGS:
      return setConfigSettings(state, action);
    case SET_IS_CHECKED_LOYALTY_PROGRAM:
      return setIsCheckedLoyaltyProgram(state, action);
    case SET_IS_SERVER_LOADED_REDUX_STATE:
      return setIsServerLoadedReduxState(state, action);
    case SET_IS_HEADER_FIXED:
      return setIsHeaderFixed(state, action);
    case SET_COUNTRY_ID:
      return setCountryId(state, action);

    default:
      return state;
  }
};

export default commonReducer;
