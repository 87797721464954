import { Product } from "@/services/productService";
import { HANDLE_GRID_CHANGE, SET_INITIAL_STATE } from "../constants";

type ProductListingState = {
  showQuicklookModal: boolean;
  appOnlyItemModal: {
    showAppOnlyItemModal: boolean;
    product: Product;
  };
  sysGeneratedUrl: string[];
  gridValue?: number;
};

const initialState: ProductListingState = {
  showQuicklookModal: false,
  appOnlyItemModal: {
    showAppOnlyItemModal: false,
    product: null
  },
  sysGeneratedUrl: []
};

const handleGridChange = (state: ProductListingState, { gridValue }) => ({
  ...state,
  gridValue
});

const setInitialState = (state: ProductListingState) => ({
  ...initialState,
  sysGeneratedUrl: state.sysGeneratedUrl
});

const productListingReducer = (
  state: ProductListingState = initialState,
  action
): ProductListingState => {
  switch (action.type) {
    case HANDLE_GRID_CHANGE:
      return handleGridChange(state, action);
    case SET_INITIAL_STATE:
      return setInitialState(state);
    default:
      return state;
  }
};

export default productListingReducer;
