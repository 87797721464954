import {
  SET_ORDER_DATA,
  SET_ORDER_STATUS,
  SET_ORDER_COUNTRY,
  RESET_ORDER_STATE
} from "../constants";

import { order } from "../initialSeed/orderSeed";

type OrderState = {
  order: any;
  orderCountryData: Record<string, any>;
};

const initialState: OrderState = {
  order: order,
  orderCountryData: {}
};

const setAddressData = (state: OrderState, { order }) => ({ ...state, order });

const setOrderData = (state: OrderState, { order }) => ({ ...state, order });

const setOrderCountryData = (state: OrderState, action) => ({
  ...state,
  orderCountryData: action.payload
});

const resetOrderState = () => ({
  ...initialState
});

const orderReducer = (state: OrderState = initialState, action): OrderState => {
  switch (action.type) {
    case SET_ORDER_DATA:
      return setAddressData(state, action);
    case SET_ORDER_STATUS:
      return setOrderData(state, action);
    case SET_ORDER_COUNTRY:
      return setOrderCountryData(state, action);
    case RESET_ORDER_STATE:
      return resetOrderState();

    default:
      return state;
  }
};

export default orderReducer;
