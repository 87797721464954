import React, { createContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cn from "clsx";
import {
  GRID_BY_SCREEN,
  LANGUAGE_ROUTE_KEY,
  LOADER_TYPES,
  MAIN_TAB_INDEX,
  SALE_SREEN
} from "@/constants";
import { selectCdnImageSettings } from "@/util/selectors";
import { useIsRTL, useLongLanguage } from "@/hooks";
import SaleTabs from "./SaleTabs";
import { SaleService } from "@/services";
import Loader from "@/components/loader";
import { SaleComingSoon } from "./SaleComingSoon";
import Breadcrumbs from "@/routes/productListing/breadcrumbs";
import ProductListingPagination from "@/routes/productListing/productListingPagination";
import defaultProductSrc from "@/images/product_default.gif";
import { SelectedFiltersType } from "./saleFilters/types";
import "./style.scss";

export const SaleWrapper = ({ pageData }) => {
  const [saleData, setSaleData] = useState(null);
  const [tabsData, setTabsData] = useState([]);
  const [heading, setHeading] = useState("");
  const [selectedFiltersQuery, setSelectedFiltersQuery] =
    useState<SelectedFiltersType>({});
  const [selectedAssistiveFilters, setSelectedAssistiveFilters] = useState({});
  const [currentTab, setCurrentTab] = useState(1);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [gridValue, setGridValue] = useState(0);

  const bannerData = pageData.data;
  const pageContentValue = bannerData.slug;

  const configCdnImagesSettings = useSelector(selectCdnImageSettings);
  const language = useLongLanguage();
  const callbackSelectedFiltersRef = useRef(null);
  const tabsDataRef = useRef(null);
  const isRTL = useIsRTL();

  callbackSelectedFiltersRef.current = selectedAssistiveFilters;
  tabsDataRef.current = tabsData;

  const DEFAULT_ITEM_INDEX = 0;

  const preloadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = () => reject();
    });
  };

  useEffect(() => {
    preloadImage(defaultProductSrc);
  }, []);

  useEffect(() => {
    setGridValueForProducts();

    const data = setDefaultSaleData();
    setTabsDetails(data);
  }, []);

  const setGridValueForProducts = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= SALE_SREEN.LARGE_WIDTH) {
      setGridValue(GRID_BY_SCREEN.LARGE);
    } else if (windowWidth >= SALE_SREEN.MEDIUM_WIDTH) {
      setGridValue(GRID_BY_SCREEN.MEDIUM);
    } else if (windowWidth > SALE_SREEN.SMALL_WIDTH) {
      setGridValue(GRID_BY_SCREEN.SMALL);
    } else {
      setGridValue(GRID_BY_SCREEN.X_SMALL);
    }
  };

  const setDefaultSaleData = () => {
    const defaultData = pageData?.data?.sales[0]?.data;
    setSaleData(defaultData);
    setHeading(pageData.data.name);
    setBreadcrumbs(pageData.breadcrumbs);

    return pageData.data;
  };

  const onTabChange = async tabId => {
    const tabsData = tabsDataRef.current;
    const availableProductsForTab = tabsData[tabId - 1].productsCount;

    if (!availableProductsForTab) {
      setSaleData({ products: [] });
      setCurrentTab(tabId);
      return;
    }

    if (tabId === MAIN_TAB_INDEX) {
      setDefaultSaleData();
    }
    if (tabsData[tabId - 1]) {
      setSaleData({ hits: [] });
      const { data } = await SaleService.getSaleSpecificTabData(
        tabsData[tabId - 1].slug,
        pageContentValue
        // { gridValue }
      );
      setSaleData(data);
    }

    setCurrentTab(tabId);
  };

  const onFilterClick = async ({ slug, queries }) => {
    const { data } = await SaleService.getSaleSpecificTabData(
      slug,
      pageContentValue,
      // { ...queries, page: undefined, gridValue }
      { ...queries, page: undefined }
    );
    setSaleData(data);
  };

  const handlePaginationClick = async e => {
    const tabDetails = tabsData[currentTab - 1];

    const updatedSelectedFilters = {
      ...selectedAssistiveFilters,
      ...selectedFiltersQuery,
      page: e.selected + 1
      // gridValue
    };

    window.scrollTo(0, 0);

    setSelectedAssistiveFilters(updatedSelectedFilters);

    const { data } = await SaleService.getSaleSpecificTabData(
      tabDetails.slug,
      pageContentValue,
      updatedSelectedFilters
    );
    setSaleData(data);
  };

  const clearAllFilters = () => {
    setSelectedAssistiveFilters({});
    setDefaultSaleData();
    setCurrentTab(1);
  };

  const handleTabClick = e => {
    setSelectedFiltersQuery({});
    setSelectedAssistiveFilters({});
    onTabChange(+e.currentTarget.id);
  };

  const setTabsDetails = data => {
    const shortLang = LANGUAGE_ROUTE_KEY[language];
    const sales = data.sales;
    const tabsData = sales.map((sale, index) => {
      const tabTitle = `${shortLang}_title`;

      return {
        title: sale[tabTitle],
        from: sale.from,
        to: sale.to,
        id: index + 1,
        slug: sale.slug,
        mainTab: index === DEFAULT_ITEM_INDEX && sales.length > 1,
        productsCount: sale.productsCount,
        heading: sale.heading,
        enabled: sale.enabled
      };
    });
    setTabsData(tabsData);
  };

  const showPagination = Boolean(
    tabsData[currentTab - 1] && saleData?.hits?.length
  );

  return (
    <div
      className={cn("catalog", {
        catalog_arabic: isRTL
      })}
    >
      {
        <div
          className={cn("breadcrumb flash-sales", {
            arabic: language.includes("ar")
          })}
        >
          <div className="container large-container">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
      }
      {saleData ? (
        <>
          <SalePageDataContext.Provider
            value={{
              saleData,
              pageData,
              setSaleData,
              setSelectedFiltersQuery
            }}
          >
            <SaleTabs
              saleDefaultData={saleData}
              configCdnImagesSettings={configCdnImagesSettings}
              currentTab={currentTab}
              tabsData={tabsData}
              language={language}
              onFilterClick={onFilterClick}
              selectedAssistiveFilters={selectedAssistiveFilters}
              setSelectedAssistiveFilters={setSelectedAssistiveFilters}
              handleTabClick={handleTabClick}
            />
          </SalePageDataContext.Provider>
          {showPagination && (
            <ProductListingPagination
              showPageCount={saleData?.hits?.length}
              activePage={saleData.page}
              pagesTotal={saleData.nbPages}
              handlePageClick={handlePaginationClick}
            />
          )}
        </>
      ) : saleData === undefined ? (
        <SaleComingSoon heading={heading} />
      ) : (
        <div>
          <Loader
            type={LOADER_TYPES.DOTS}
            visible
            style={loaderStyle}
            size={10}
          />
        </div>
      )}
    </div>
  );
};

const loaderStyle = {
  top: "0px",
  left: "15px",
  right: "0px",
  bottom: "0px",
  backgroundColor: "inherit",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export const SalePageDataContext = createContext<{
  saleData;
  pageData;
  setSaleData: (val: any) => void;
  setSelectedFiltersQuery: (val: any) => void;
}>(null);
