import ApiService from "./apiService";

import { BEConfig } from "../config/env";

import { webapiPost, webapiGet } from "../webapis/core";

class PayPalService extends ApiService {
  private isLoaded = false;

  public async getPayPalToken() {
    const endpoint = this.getBaseUrl() + BEConfig.checkoutApi.paypalToken;

    const response = await webapiGet(this.getAccessToken(), endpoint).request;

    return response.data;
  }

  public loadSdk() {
    return new Promise<void>((resolve, reject) => {
      if (this.isLoaded) return resolve();

      const script = document.createElement("script");
      script.src = "https://www.paypalobjects.com/api/checkout.min.js";
      script.setAttribute("log-level", "warn");
      script.setAttribute("data-version-4", "");
      script.setAttribute("async", "");

      script.onload = () => {
        this.isLoaded = true;
        console.log("PayPal loaded");
        resolve();
      };
      script.onerror = reject;

      document.head.appendChild(script);
    });
  }

  public async loadBraintree() {
    const { client, dataCollector, paypalCheckout } = await import(
      /* webpackChunkName: "paypal_braintree_lib" */ "braintree-web"
    );

    return { client, dataCollector, paypalCheckout };
  }

  private getBaseUrl() {
    const { baseURL, protocol, port, versionInfo, checkoutHandle } =
      BEConfig.checkoutApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;

    return url;
  }
}

const instance = new PayPalService();

export default instance;
