import React, { FC, useContext, useEffect, useState } from "react";
import cn from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import FilterOptionItem from "@/routes/productListing/productListingFiltersWeb/filterOptionItem";
import useMultiselectFilter from "@/routes/productListing/hooks/useMultiselectFilter";
import useSelectedByResponse from "@/routes/productListing/hooks/useSelectedByResponse";
import { SelectedFiltersContext } from "../saleWebFilteringSection";
import FilterElement from "./filterElement";
import { GeneralFacet, MultiSelectFacet } from "../types";

type FilterMultiSelectProps = {
  filter: Omit<GeneralFacet & MultiSelectFacet, "sortOrder">;
};

const FilterMultiSelectWeb: FC<FilterMultiSelectProps> = ({ filter }) => {
  const { t } = useTranslation("productListing");
  const [searchValue, setSearchValue] = useState("");
  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(SelectedFiltersContext);
  const {
    filterOptions,
    selectedOptions,
    onFilterOptionClick,
    clearSelectedOptions,
    setSelectedOptions
  } = useMultiselectFilter(filter, onSelection);
  const selectedByResponse = useSelectedByResponse(filterOptions);

  useEffect(() => {
    if (!isEmpty(selectedByResponse)) {
      updateSelectedFilters({
        type: filter.type,
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: selectedByResponse
      });
    }
  }, [selectedByResponse]);

  useEffect(() => {
    setSelectedOptions(selectedByResponse);
    if (isEmpty(selectedByResponse) && selectedFilters[filter.queryParam]) {
      setSelectedFilters(currentFilters => {
        const { [filter.queryParam]: toRemove, ...newFilters } = currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  const handleSearchType = (e: any) => {
    setSearchValue(e.target.value.toLocaleLowerCase());
  };

  const handleClearFilter = () => {
    searchValue && setSearchValue("");
    selectedOptions.length && clearSelectedOptions();
  };

  const isSearchable = filter.search;
  const selectedOptionsCount = selectedOptions.length;

  return (
    <>
      <FilterElement
        className={cn({
          items_selected: selectedOptionsCount
        })}
        filterLabel={filter.label}
        filterSelectedCount={selectedOptionsCount}
        filterWrapperClassName="mxw-300"
        setSearchValue={setSearchValue}
      >
        <div>
          {isSearchable && (
            <div className="brand-search-wrapper">
              <input
                type="text"
                placeholder={filter.search.placeholder}
                value={searchValue}
                onChange={handleSearchType}
                aria-label={`Search for ${filter.label}`}
              />
              {!searchValue && <span className="search_icon" />}
            </div>
          )}
          <Scrollbars
            autoHeight
            autoHeightMin={55}
            autoHeightMax={isSearchable ? 193 : 240}
          >
            <ul className={cn("hierarchical_categories2_item")}>
              {filterOptions
                .filter(option =>
                  option.label?.toLocaleLowerCase().includes(searchValue)
                )
                .map(option => (
                  <FilterOptionItem
                    key={option.key}
                    option={option}
                    isSelected={option.isSelected}
                    onSelection={onFilterOptionClick}
                  />
                ))}
            </ul>
          </Scrollbars>
        </div>

        <div className="count_selected">
          {selectedOptionsCount} selected
          <span onClick={handleClearFilter}>{t("clear")}</span>
        </div>
      </FilterElement>
    </>
  );
};

export default FilterMultiSelectWeb;
