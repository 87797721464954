import React, { FC, memo } from "react";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import { Image } from "../image";
import { useAppSelector } from "@/redux/store";

type Props = { defaultCountry?: any; footerWeb?: boolean };

const PaymentIcons: FC<Props> = ({ defaultCountry, footerWeb }) => {
  const { t } = useTranslation("footer");
  const settings = useAppSelector(state => state.common.settings);
  const paymentOptions = get(defaultCountry || settings, "paymentOptions", {});
  const optionsWithImages = filter(paymentOptions, option => option.image);

  return (
    <>
      <div className={cn("we_accept", { we_accept_footer: footerWeb })}>
        {!footerWeb && <div className="title">{t("weAccept")}</div>}
        <div className="we-accept-payments">
          <ul>
            {map(optionsWithImages, (options, index) => (
              <li key={index}>
                <Image src={options.image} alt="cardAccept" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default memo(PaymentIcons);
