import React, { FC } from "react";
import ReactHtmlParser from "react-html-parser";

type SearchResult = {
  value: string;
  matchLevel: string;
  fullyHighlighted: boolean;
  matchedWords: string[];
};

type QueryResult = {
  query: SearchResult;
};

const SearchResults: FC<{
  item: QueryResult;
  moveToProductListing: (item: string) => void;
}> = ({ item, moveToProductListing }) => {
  const value = item?.query?.value;

  if (!value) return null;

  return (
    <li
      onClick={() =>
        moveToProductListing(
          value.replaceAll("<em>", "").replaceAll("</em>", "")
        )
      }
    >
      {ReactHtmlParser(value.replaceAll(/em>/g, "b>") || "NA")}
    </li>
  );
};

export default SearchResults;
