import React, { FC, useEffect, useMemo, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import cn from "clsx";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useCountryId, useIsRTL, useLongLanguage, useRouteSlug } from "@/hooks";
import { SectionService } from "@/services";
import CatalogService from "@/services/catalogService";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { SECTION_TABS_MODULE_TYPE } from "@/constants/sections";
import DynamicBannersWrapper from "../dynamicBanner";
import { DynamicTabProps, TabData } from "./types";
import { hidePageLoader, showPageLoader } from "@/redux/actions/common.action";
import { LOADER_TYPES } from "@/constants";
import { isMobile } from "@/util";
import "./style.scss";

const TAB_NONE_STYLE = "none";
const TAB_DEFAULT_STYLE = "style1";

const NAVIGATION_TYPES = {
  INLINE: "inline",
  REDIRECTION: "redirectionUrl"
};

interface TabCSSProperties extends React.CSSProperties {
  tabBgColor?: string;
  tabHgColor?: string;
  highlightedColor?: string;
  fontColor?: string;
}

const DynamicTab: FC<DynamicTabProps> = ({ content }) => {
  const history = useHistory();
  const routeSlug = useRouteSlug();
  const countryId = useCountryId();
  const storeId = useAppSelector(state => state.page.homepageState.storeId);
  const language = useLongLanguage();
  const isRtl = useIsRTL();
  const [activeContent, setActiveContent] = useState(null);
  const [activeTab, setActiveTab] = useState<null | number>(null);
  const dispatch = useAppDispatch();

  const wrapperStyle = useMemo(() => {
    const styles: TabCSSProperties = { ...content.styles };
    if (content.width.value) {
      styles.width = content.width.value;
    }
    delete styles.backgroundColor;
    styles["--tabs-bg_color"] = content.styles.backgroundColor;

    styles["--tab-font_color"] = content.styles.fontColor;
    styles["--tab-bg_color"] = content.styles.tabBgColor;

    styles["--tab-highlighted_font_color"] = content.styles.highlightedColor;
    styles["--tab-highlighted_bg_color"] = content.styles.tabHgColor;

    return styles;
  }, []);

  const showLoader = () =>
    dispatch(showPageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true }));

  const hideLoader = () =>
    dispatch(hidePageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true }));

  const handleSectionsData = async (sectionSlug: string) => {
    showLoader();

    try {
      const response = await SectionService.getDynamicSections({
        moduleType: SECTION_TABS_MODULE_TYPE,
        slug: sectionSlug
      });

      const dynamicData = response.find(
        data => data?.sectionSlug === sectionSlug
      );

      if (dynamicData?.content) {
        setActiveContent(dynamicData.content);
      } else {
        setActiveContent(null);
      }
    } catch (e) {
      console.error(e);
      setActiveContent(null);
    } finally {
      hideLoader();
    }
  };

  const handleCatalogData = async (catalogUrl: string) => {
    showLoader();

    const url = catalogUrl.startsWith("/") ? catalogUrl.slice(1) : catalogUrl;
    try {
      const response = await CatalogService.getPageData({
        url,
        countryId,
        language,
        storeId,
        limit: undefined,
        cookies: undefined
      });

      if (response?.data) {
        setActiveContent(response.data.content);
      } else {
        setActiveContent(null);
      }
    } catch (e) {
      console.error(e);
      setActiveContent(null);
    } finally {
      hideLoader();
    }
  };

  const onNavLinkClick = async (
    tabData: TabData,
    isActive: boolean,
    index: number
  ) => {
    if (isActive) return;
    setActiveTab(index);

    if (tabData.navigationType === NAVIGATION_TYPES.REDIRECTION) {
      history.push(`/${routeSlug}${tabData.redirectionUrl}`);
    } else if (tabData.sectionSlug) {
      await handleSectionsData(tabData.sectionSlug);
    } else if (tabData.navigationType === NAVIGATION_TYPES.INLINE) {
      await handleCatalogData(tabData.catalogUrl);
    }
  };

  // Initial setup
  useEffect(() => {
    if (!content?.tab) return;
    const defaultTabIndex = content.tab.findIndex(tab => tab.isDefault);
    setActiveTab(defaultTabIndex);
    const tabData = content.tab[defaultTabIndex][language];

    if (tabData.sectionSlug) {
      handleSectionsData(tabData.sectionSlug);
    } else if (tabData.catalogUrl) {
      handleCatalogData(tabData.catalogUrl);
    }
  }, [content, language]);

  if (!content?.tab) {
    console.error("No dynamicTab data found");
  }

  const tabStyle =
    content.tabStyle !== TAB_NONE_STYLE ? content.tabStyle : TAB_DEFAULT_STYLE;

  const isNotAvailableForResp =
    isMobile.any() && !content?.enabledOn?.includes("resp");

  if (isNotAvailableForResp) {
    return null;
  }

  return (
    <>
      <div className={cn("dynamic_tab_wrapper", tabStyle)} style={wrapperStyle}>
        <Nav tabs>
          {content?.tab?.map((tab, index) => {
            const tabData = tab[language];
            const isActive = activeTab === index;
            let isIconLeft = tabData.imagePosition === "left";
            // Because of rtl we need to switch position
            if (isRtl) {
              isIconLeft = !isIconLeft;
            }

            return (
              <NavItem key={index}>
                <NavLink
                  className={cn({
                    active: isActive
                  })}
                  onClick={() => onNavLinkClick(tabData, isActive, index)}
                >
                  {isIconLeft && tabData.imageURL && (
                    <img src={tabData.imageURL} alt="tab" />
                  )}
                  <span>{tabData.title}</span>
                  {!isIconLeft && tabData.imageURL && (
                    <img src={tabData.imageURL} alt="tab" />
                  )}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent>
          <TabPane>
            {!isEmpty(activeContent) &&
              activeContent.map(section => (
                <DynamicBannersWrapper section={section} key={section._id} />
              ))}
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default DynamicTab;
