import CommonService from "@/services/CommonService";
import PayPalService from "@/services/payPalService";
import CatalogService from "@/services/catalogService";
import ProductService from "@/services/productService";

import { LoaderConfig } from "@/components/loader";

import { getAccessToken } from "../../util/storeHelper";
import {
  generateUUID,
  languageFromPathName,
  isServer,
  getCountryNameFromUrl,
  getVisitorId
} from "../../util";
import { handleFetchError } from "../../util/errorHandler";
import { getUserCountryStorage } from "../../util/browserStorage";

import { BEConfig, ENV_CONFIG } from "../../config/env";
import { fetchWebApi } from "../../webapis/apiResource";

import { getDataAsObject } from "../../util/common";

import {
  SHOW_LOADER,
  HIDE_LOADER,
  CHANGE_LANGUAGE,
  SAVE_BREADCRUMB_DATA,
  COMMON_DATA,
  SET_BRANDS_DATA,
  RECENTLY_VIEWED,
  SET_STATES_DATA,
  SET_LOYALITY_POINTS,
  SET_SEARCH_TEXT,
  RESET_BREADCRUMB_DATA,
  RESET_BRANDS_DATA,
  SHOW_ERROR_PAGE,
  HIDE_ERROR_PAGE,
  USER_ORIGIN_COUNTRY,
  SHOW_PAGE_LOADER,
  HIDE_PAGE_LOADER,
  SET_PAYPAL_TOKEN,
  SET_ORDER_COUNTRY,
  RESET_ORDER_STATE,
  SET_COUNTRIES_MOBILE_DETAILS,
  SET_SELECTED_COUNTRY_MOBILE_DETAILS,
  SET_LOYALTY_PROGRAM_STATUS,
  SET_DEVICE_TYPE,
  SET_CONFIG_SETTINGS,
  SET_IS_CHECKED_LOYALTY_PROGRAM,
  RECENTLY_VIEWED_PRODUCTS,
  SET_IS_SERVER_LOADED_REDUX_STATE,
  SET_IS_HEADER_FIXED,
  SET_COUNTRY_ID
} from "../constants";
import {
  PRODUCT_CAROUSEL_ITEMS_LIMIT,
  DEFAULT_PAGE_LOADER_CONFIG
} from "../../constants";

import { setStoreList } from "./page.action";
import BrandsService, { Brand } from "../../services/brandsService";
import { AppDispatch } from "../store";

export const showErrorPage = (key, value) => ({
  type: SHOW_ERROR_PAGE,
  key,
  value
});

export const handleCountryId = (countryId: number) => ({
  type: SET_COUNTRY_ID,
  payload: countryId
});

export const hideErrorPage = () => ({ type: HIDE_ERROR_PAGE });

export const showLoadingIndicator = () => ({ type: SHOW_LOADER });

export const hideLoadingIndicator = () => ({ type: HIDE_LOADER });

export const showPageLoaderStart = payload => ({
  type: SHOW_PAGE_LOADER,
  payload
});

export const showPageLoaderEnd = payload => ({
  type: HIDE_PAGE_LOADER,
  payload
});

export const showPageLoader =
  (payload = {}) =>
  async dispatch => {
    dispatch(showPageLoaderStart(payload));
  };
export const hidePageLoader =
  (payload = {}) =>
  async dispatch => {
    dispatch(showPageLoaderEnd(payload));
  };

export const showLoaders =
  (pageLoaderConfig: LoaderConfig = DEFAULT_PAGE_LOADER_CONFIG) =>
  dispatch => {
    dispatch(showLoadingIndicator());
    dispatch(showPageLoader(pageLoaderConfig));
  };

export const hideLoaders =
  (pageLoaderConfig = DEFAULT_PAGE_LOADER_CONFIG) =>
  dispatch => {
    dispatch(hideLoadingIndicator());
    dispatch(hidePageLoader(pageLoaderConfig));
  };

export const updateLanguageName = name => ({
  type: CHANGE_LANGUAGE,
  name
});

export const saveBreadcrumb = navData => ({
  type: SAVE_BREADCRUMB_DATA,
  navData
});

export const resetBreadCrumb = () => ({ type: RESET_BREADCRUMB_DATA });

export const setCommonData = settings => ({ type: COMMON_DATA, settings });

export const setCountryListData = countryData => ({
  type: COMMON_DATA,
  countryData
});

export const setBrandsData = (brands: Brand[]) => ({
  type: SET_BRANDS_DATA,
  brands
});
export const setSearchText = text => ({ type: SET_SEARCH_TEXT, text });

export const setStatesData = state => ({ type: SET_STATES_DATA, state });

export const setLoyaltyPoints = loyaltyPoints => ({
  type: SET_LOYALITY_POINTS,
  loyaltyPoints
});

export const setLoyaltyProgramStatus = isJoinedToLoyaltyProgram => ({
  type: SET_LOYALTY_PROGRAM_STATUS,
  isJoinedToLoyaltyProgram
});

export const setIsCheckedLoyaltyProgram = isCheckedLoyaltyProgram => ({
  type: SET_IS_CHECKED_LOYALTY_PROGRAM,
  isCheckedLoyaltyProgram
});

export const resetBrandsReducer = () => ({ type: RESET_BRANDS_DATA });

export const setUserOriginCountry = userOriginCountry => ({
  type: USER_ORIGIN_COUNTRY,
  userOriginCountry
});

export const resetOrderState = () => ({
  type: RESET_ORDER_STATE
});

// export const toastMsg = (
//   toastType: TOAST_TYPES,
//   message: string,
//   noTitle: boolean,
//   time?: number
// ) => {
//   const toastConfig = toastNotificationOpts(
//     !noTitle && capitalize(toastType),
//     message,
//     time
//   );
//   const toastAction = toastType === TOAST_TYPES.SUCCESS ? success : error;

//   return dispatch => dispatch(toastAction(toastConfig));
// };

// TO DO: Remove after implementation new shape for all pages where we use it
export const setRecentlyViewed = product => {
  let recentLyViewed = localStorage.getItem(RECENTLY_VIEWED) as any;
  recentLyViewed = (recentLyViewed && getDataAsObject(recentLyViewed)) || [];
  if (!recentLyViewed.find(prod => prod.id === product.id)) {
    recentLyViewed.unshift(product);
    if (recentLyViewed.length > 12) {
      recentLyViewed.splice(12, recentLyViewed.length);
    }
    localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(recentLyViewed));
  }
};

// TO DO: Remove after implementation new shape for all pages where we use it
export const getRecentlyViewed = () => {
  const recentlyViewed = localStorage.getItem(RECENTLY_VIEWED);
  return (recentlyViewed && getDataAsObject(recentlyViewed)) || [];
};

export const setRecentlyViewedProducts = product => {
  let recentLyViewed = localStorage.getItem(RECENTLY_VIEWED_PRODUCTS) as any;
  recentLyViewed = (recentLyViewed && getDataAsObject(recentLyViewed)) || [];
  if (!recentLyViewed.find(prod => prod.id === product.id)) {
    recentLyViewed.unshift(product);
    if (recentLyViewed.length > 12) {
      recentLyViewed.splice(12, recentLyViewed.length);
    }
    localStorage.setItem(
      RECENTLY_VIEWED_PRODUCTS,
      JSON.stringify(recentLyViewed)
    );
  }
};

export const getRecentlyViewedProducts = () => {
  const recentlyViewed = localStorage.getItem(RECENTLY_VIEWED_PRODUCTS);
  return (recentlyViewed && getDataAsObject(recentlyViewed)) || [];
};

export const getBrandsData = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await BrandsService.getBrandsData();

      dispatch(setBrandsData(response));

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    }
  };
};

export const getLoyaltyPoints =
  (orderItems, showLoader = false) =>
  async dispatch => {
    if (showLoader) dispatch(showLoadingIndicator());

    try {
      const loyaltyPoints = await ProductService.getLoyaltyPointsByProducts(
        orderItems
      );

      if (loyaltyPoints !== undefined)
        dispatch(setLoyaltyPoints(loyaltyPoints));

      return loyaltyPoints;
    } catch (error) {
      return error.response;
    } finally {
      if (showLoader) dispatch(hideLoadingIndicator());
    }
  };

const getLanguage = lang =>
  lang && lang.includes("ar") ? "arabic" : "english";

const mapMobileDetails = countries =>
  countries.map(
    ({
      countryId,
      countrySHORT,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength,
      countryName,
      mobileNumberCriteria
    }) => ({
      countryId,
      countrySHORT,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength,
      countryName,
      mobileNumberCriteria
    })
  );

const getVersionedCommonApiBaseUrl = () => {
  const { protocol, baseURL, port, versionInfo } = BEConfig.commonApi;
  return `${protocol}${baseURL}${port}${versionInfo}`;
};

export const getCountrySettings = (language?, id?, url?) => {
  const lang = getLanguage(language);
  const shortCountryNameFromUrl = getCountryNameFromUrl(
    isServer ? url || `/${language}` : window.location.href
  );
  const countryFromCookies = (getUserCountryStorage() || "").toLowerCase();
  const countryIdentifier =
    id || shortCountryNameFromUrl || countryFromCookies || ENV_CONFIG.countryId;
  const { settingsHandle, countryByIdSetting } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${settingsHandle}${countryByIdSetting}${countryIdentifier}?language=${lang}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL, generateUUID())
      .then(response => {
        if (response.status === 200 && response.data) {
          const shapedData = [response.data];
          dispatch(setStoreList(lang, response.data.storeList));
          dispatch(setCountryListData(shapedData));
          response.data = shapedData;
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getOrderCountryById = (id, language) => {
  const lang = getLanguage(language);

  const { settingsHandle, countryByIdSetting } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${settingsHandle}${countryByIdSetting}${id}?language=${lang}`;
  return (dispatch, getState) =>
    fetchWebApi(getAccessToken(getState), apiURL, generateUUID())
      .then(response => {
        if (response.status === 200 && response.data) {
          const shapedData = response.data;
          dispatch({ type: SET_ORDER_COUNTRY, payload: shapedData });
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
};

export const getCountryList = language => async (dispatch, getState) => {
  const lang = getLanguage(language);
  const { countryData } = getState().common;

  if (countryData.length > 1) return countryData;

  try {
    const countryDataList = await CommonService.getShortCountryList(lang);
    dispatch(setCountryListData(countryDataList));

    return countryDataList;
  } catch (error) {
    handleFetchError(error, dispatch);
    return error.response;
  }
};

export const getStatesData = (countryId, language = "en") => {
  const lang = languageFromPathName(language);
  const { statesHandle } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${statesHandle}/${countryId}`;

  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL, getVisitorId(), lang)
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(setStatesData(response.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getPayPalToken = () => async dispatch => {
  const token = await PayPalService.getPayPalToken();
  token && dispatch({ type: SET_PAYPAL_TOKEN, payload: token });
};

export const setCountriesMobileDetails = details => ({
  type: SET_COUNTRIES_MOBILE_DETAILS,
  payload: details
});
export const setSelectedCountryMobileDetails = details => ({
  type: SET_SELECTED_COUNTRY_MOBILE_DETAILS,
  payload: details
});

export const getMobileDetailsFromCountryList = language => async dispatch => {
  try {
    const countries = await CommonService.getShortCountryList(
      getLanguage(language)
    );
    const details = mapMobileDetails(countries);
    dispatch(setCountriesMobileDetails(details));
  } catch (error) {
    console.error(error);
  }
};

export const getCatalogCarouselData =
  ({ url, countryId, language, storeId }) =>
  async dispatch => {
    dispatch(showLoadingIndicator());

    try {
      const limit = PRODUCT_CAROUSEL_ITEMS_LIMIT;

      const response = await CatalogService.getCarouselData({
        url,
        countryId,
        language,
        storeId,
        limit
      });
      const { hits } = response;

      const carouselData = {
        enable: true,
        searchUrl: url,
        type: "product-carousel",
        [language]: { imageList: hits },
        queryId: response.queryID
      };

      return carouselData;
    } catch (error) {
      console.error("Call getCatalogCarouselData faced the error\n", error);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const setDeviceType = type => ({
  type: SET_DEVICE_TYPE,
  payload: type
});

const setConfigurationSettings = configs => ({
  type: SET_CONFIG_SETTINGS,
  configs
});

export const getConfigurationSettings = () => async (dispatch, getState) => {
  const { protocol, baseURL, port, versionInfo, configurations } =
    BEConfig.catalogApi;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${configurations}`;
  try {
    const response = await fetchWebApi(getAccessToken(getState), apiURL);

    if (response.status === 200 && response.data) {
      dispatch(setConfigurationSettings(response.data));
    }
    return response;
  } catch (error) {
    handleFetchError(error, dispatch);
    return error.response;
  }
};

export const setIsServerLoadedReduxState = state => ({
  type: SET_IS_SERVER_LOADED_REDUX_STATE,
  payload: state
});

export const setIsHeaderFixed = isHeaderFixed => ({
  type: SET_IS_HEADER_FIXED,
  isHeaderFixed
});
