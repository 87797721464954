import { GET_SIZE_CHART } from "../constants";

const initialState = {} as any;

const getSizeChart = (state, { content }) => ({ ...state, ...content });

const sizeChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SIZE_CHART:
      return getSizeChart(state, action);
    default:
      return state;
  }
};

export default sizeChartReducer;
