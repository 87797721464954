import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";
import { ContextStorage } from "@/libs/contextStorage";

import { isServer } from "../../../util";
import {
  CreatedProductItem,
  EcommernceReturnPayload,
  ReturnProductItem
} from "./type";
import { STATIC_COUNTRY_CODE } from "../../../constants";

export const getConfigData = () => {
  const store = ContextStorage.store.getState();
  const { language, settings } = store.common;
  const { pageType } = store.page;

  const { countryCode, currencyCode, countryName, countryId } = settings;

  return {
    language,
    countryCode,
    currencyCode,
    countryName,
    countryId,
    pageType
  };
};

export const getCountryCodeFromURL = () => {
  const slug = window.location?.pathname?.split("/")?.[1];
  const code = slug && slug.split("-")[1];
  return code ? STATIC_COUNTRY_CODE[code] : undefined;
};

/**
 * Sanitizes a string value.
 *
 * @param value - The value to be sanitized (string, boolean, or number).
 * @returns If the value is a string, it is converted to lowercase. If it's an empty string, it is replaced with undefined.
 * If the value is not a string, it is returned as is.
 */
export const sanitizeString = (value: string | boolean | number) => {
  return typeof value === "string"
    ? value.trim() === ""
      ? ""
      : value.toLowerCase()
    : value;
};

export function sanitizeStringKeepRegister(value: string | boolean | number) {
  return typeof value === "string" ? (value.trim() === "" ? "" : value) : value;
}

/**
 * Pushes data to the Google Analytics layer.
 *
 * @param payload The data to be pushed.
 * @description Use this method to push data to the Google Analytics layer.
 */
export function pushToDataLayer(data: Record<string, any>): void {
  const { language, countryCode, countryName, pageType = "" } = getConfigData();
  if (!isServer) {
    const country = countryCode || getCountryCodeFromURL() || "";
    const payload = {
      language_cd: language,
      country_cd: sanitizeString(country),
      country_name: sanitizeString(countryName),
      page_type: lowerCase(pageType),
      ...data
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(payload);
  }
}

/**
 * Creates a product payload required for Google Analytics (GA) based on the provided product object.
 *
 * @param item Product object used to create the GA ecommerce payload.
 * @param includeQuantitySize Specifies whether to include quantity and size information, typically for cart-related events.
 * @param index Index of the product in a list (if applicable), default is 0.
 * @returns Returns a product payload required for GA ecommerce.
 */
export function createProductItem(
  item,
  includeQuantitySize: boolean = false,
  index: number = 0
): CreatedProductItem {
  const promotion_name =
    item.promotion
      ?.map((promoField: any) => promoField.defaultValue)
      ?.join("|") ||
    item.overlayTagLabel?.defaultValue ||
    "";
  const currentPrice = +(
    item.currentPrice ||
    item.transactionPrice ||
    item.price?.current ||
    item.price ||
    0
  );
  const oldPrice = item.oldPrice || item.price?.old || item._price?.old || 0;
  const price = +(oldPrice || currentPrice);

  let discountVal = 0;

  let color = [];
  const COLOR_ATTR = "color_family";
  const colorObj = item.highlights?.attributes?.find(
    each => each.key === COLOR_ATTR
  );
  if (!isEmpty(colorObj)) {
    color = colorObj.values?.map(colorItem => colorItem.defaultValue);
  }
  const discount =
    get(item, "price.discountPercentage", 0) ||
    get(item, "_price.discountPercentage", 0) ||
    get(item, "discount", 0) ||
    0;

  if (discount !== 0) {
    discountVal = oldPrice - currentPrice;
  }
  const currency = get(item, "price.currency", "");
  const position = get(item, "position", null);
  const itemIndex = index + 1;
  let outOfStockSizes = "";
  // outOfStockSizes = (!item.isMultisize ? item.sizes?.[0]?.variants : item.sizes)
  outOfStockSizes = (item.sizes?.[0]?.variants || item.sizes || [])
    .filter(size => size.sizeStock === 0)
    .map(unavailedSize => unavailedSize.size)
    ?.join("|");

  let itemOrigin = "product";
  if (item.origin) {
    itemOrigin = item.origin || itemOrigin;
  }

  // For all the events after viewing a product (e.g., add to cart, wishlist, checkout, order, etc.).
  let quantitativeData = {};
  if (includeQuantitySize) {
    const quantity = get(item, "quantity", "");
    const size = get(item, "size", "");
    quantitativeData = {
      quantity,
      size
    };
  }

  const productObject = {
    item_id: item.id.toString(),
    item_name: item.title?.defaultValue,
    affiliation: "brandsforless",
    item_brand: item.brand?.defaultValue,
    item_category: item.parentCategory?.defaultValue,
    item_category2: item.category?.defaultValue,
    item_category3: item.subcategory?.defaultValue,
    item_category4: "auto applied",
    currency,
    discount: discountVal,
    price,
    promotion_id: promotion_name,
    promotion_name: promotion_name,
    out_of_stock_sizes: outOfStockSizes,
    item_color: color.join("|"),
    item_stock: item.pstock,
    item_image_url: item.captionImageURL,
    item_url: item.url,
    index: position ? position + 1 : itemIndex,
    item_origin: itemOrigin,
    ...quantitativeData,
    currentPrice // will remove in the returned data.
  };

  const finalObject = {};
  Object.entries(productObject).forEach(
    ([key, value]) =>
      (finalObject[key] = (
        key.includes("url") ? sanitizeStringKeepRegister : sanitizeString
      )(value))
  );
  return finalObject as CreatedProductItem;
}
export function getItemTotalPrice(
  currentPrice: number,
  product: ReturnProductItem
) {
  let price = currentPrice;
  if (product?.quantity && product?.quantity > 1) {
    price = currentPrice * product?.quantity;
  }
  return price;
}

/**
 * Retrieves data for Google Analytics (GA) ecommerce parameters, including currency, product/s, and total price.
 *
 * @param data Can be an array of Product objects or a single Product object passed to createProductItem to generate the GA ecommerce payload.
 * @param includeQuantitySize Specifies whether to include quantity and size information, typically for cart-related events.
 * @returns Returns all properties required for GA ecommerce parameters, i.e., currency, product/s, and total price.
 */
export function getEcommerceData(
  data,
  includeQuantitySize = false
): EcommernceReturnPayload {
  const { currencyCode } = getConfigData();
  const currency = lowerCase(currencyCode);
  if (isArray(data)) {
    let totalPrice = 0;
    const payload = data.map((item, index) => {
      const { currentPrice, ...product } = createProductItem(
        item,
        includeQuantitySize,
        index
      );
      const singleProductPrice = getItemTotalPrice(currentPrice, product);
      totalPrice += singleProductPrice;
      return product;
    });

    return { payload, totalPrice, currency };
  } else {
    const { currentPrice, ...product } = createProductItem(
      data,
      includeQuantitySize
    );
    const singleProductPrice = getItemTotalPrice(currentPrice, product);
    return { payload: product, totalPrice: singleProductPrice, currency };
  }
}

export const getMenuTree = (...levels) => {
  const correctFormat = string => string.toLowerCase().trim();
  const formattedLevels = levels.map(correctFormat);
  return formattedLevels.join(" > ");
};
