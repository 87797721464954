import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import reducer from "./features";
import legacyReducer from "./reducers";

import { updateLanguageName } from "./actions/common.action";

export const isServer: boolean = typeof window === "undefined";

export type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const handleStoreInitialSetup = (
  store: Store,
  { language }: { language: Language }
) => {
  store.dispatch(updateLanguageName(language));
};

const createStore = ({
  preloadedState,
  language
}: {
  preloadedState?: Record<string, any>;
  language: Language;
}) => {
  const store = configureStore({
    reducer: { ...reducer, ...legacyReducer },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });

  if (!preloadedState) handleStoreInitialSetup(store, { language });

  return store;
};

export default createStore;
