import {
  LISA_CLIENT_NAME,
  LISA_IFRAME_URL,
  LISA_PLAYER_CLASS,
  LISA_RECIPIENT
} from "../../constants";

class LiSACommunicationService {
  sendMessage(messageBody) {
    const iframe = document.querySelector(`.${LISA_PLAYER_CLASS}`);
    if (iframe) {
      //@ts-ignore
      iframe.contentWindow.postMessage(
        {
          sender: LISA_CLIENT_NAME,
          recipient: LISA_RECIPIENT,
          ...messageBody
        },
        LISA_IFRAME_URL
      );
    }
  }

  minimize() {
    this.sendMessage({
      target: "app",
      action: "minimize"
    });
  }

  maximize() {
    this.sendMessage({
      target: "app",
      action: "maximize"
    });
  }

  pause() {
    this.sendMessage({
      target: "app",
      action: "pause"
    });
  }

  resume() {
    this.sendMessage({
      target: "app",
      action: "resume"
    });
  }

  getItemCountUpdatePayload(itemCount) {
    return {
      action: "add-to-cart",
      target: "cart",
      numberOfItems: itemCount
    };
  }

  sendFailedItemAdding(itemCount) {
    this.sendMessage({
      success: false,
      message: "Sorry! The product is no longer available.",
      ...this.getItemCountUpdatePayload(itemCount)
    });
  }
}
const instance = new LiSACommunicationService();
export default instance;
