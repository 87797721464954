import React from "react";
import backToTopGif from "../../../images/gifs/back-to-top.gif";
import "./style.scss";

const BackToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="back-to-top" onClick={scrollToTop}>
      <img src={backToTopGif} alt="Back to top" />
    </div>
  );
};

export default BackToTopButton;
