import React, { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import get from "lodash/get";
import cn from "clsx";
import IntlTelInputReact from "intl-tel-input/react";
import "intl-tel-input/build/css/intlTelInput.css";

import CustomDropdown from "../genericDropDown";
import { DROP_DOWN_SCROLL_TYPES } from "../../constants";
import {
  getMobileDetailsFromCountryList,
  setSelectedCountryMobileDetails
} from "../../redux/actions/common.action";
import { getAcceptablePhoneLengths } from "@/util";
import "./style.scss";

const basicPlaceholder = "123 4567890";

class IntlInput extends Component {
  state = {
    selectedCountry: this.props.defaultCountry || "",
    mobileLocalNumberLength: this.props.mobileLocalNumberLength || 7
  };

  componentDidMount() {
    const {
      getMobileDetailsFromCountryList,
      countriesMobileDetails,
      withCountrySelection,
      language
    } = this.props;
    if (withCountrySelection && isEmpty(countriesMobileDetails)) {
      getMobileDetailsFromCountryList(language);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      defaultCountry,
      countriesMobileDetails,
      withCountrySelection,
      setSelectedCountryMobileDetails,
      selectedCountryMobileDetails
    } = this.props;
    const { prevDefaultCountry } = prevProps;
    const { selectedCountry } = this.state;
    if (!prevDefaultCountry && !selectedCountry && defaultCountry) {
      this.setState({ selectedCountry: defaultCountry });
    }

    if (
      withCountrySelection &&
      defaultCountry &&
      !isEmpty(countriesMobileDetails) &&
      isEmpty(selectedCountryMobileDetails)
    ) {
      const mobileLocalNumberData = this.getMobileLocalNumberData(
        countriesMobileDetails,
        defaultCountry
      );
      setSelectedCountryMobileDetails(mobileLocalNumberData);
    }
  }

  checkNumber = text => {
    const regex = /^[0-9\b]+$/;
    if (regex.test(text) || text === "") {
      const {
        handleCountryChange,
        withCountrySelection,
        selectedCountryMobileDetails
      } = this.props;
      const { mobileLocalNumberLength } = withCountrySelection
        ? selectedCountryMobileDetails
        : this.props;

      handleCountryChange &&
        handleCountryChange(
          text.trim().length === parseInt(mobileLocalNumberLength || 8),
          text
        );
    }
  };

  onSelectFlag = (a, dropdownCountryData) => {
    const {
      onSelectFlag,
      countryData: prevCountry,
      onSelectArea,
      withCountrySelection,
      countriesMobileDetails,
      setSelectedCountryMobileDetails,
      defaultCountry,
      phone,
      areaCode,
      clearPhoneInput
    } = this.props;
    const { selectedCountry } = this.state;
    const countryData = withCountrySelection
      ? countriesMobileDetails
      : prevCountry;

    const areaCodes = this._getAreaCodes(
      countryData,
      dropdownCountryData.iso2 && dropdownCountryData.iso2.toUpperCase()
    );
    if (dropdownCountryData.iso2 === "sa") {
      areaCodes.splice(0, 1);
    }
    //
    // Because we don't use phone number appearance based on the each country number shape with block of the code is omitted for now
    //
    // const placeholder = window.intlTelInputUtils.getExampleNumber(
    //   dropdownCountryData.iso2,
    //   true,
    //   1
    // );
    // const placeholderTransformed =
    //   (areaCodes &&
    //     areaCodes.length &&
    //     placeholder.substring(
    //       placeholder.indexOf(areaCodes[0]) + areaCodes[0].length,
    //       placeholder.length
    //     )) ||
    //   placeholder;

    const mobileLocalNumberData = this.getMobileLocalNumberData(
      countriesMobileDetails,
      dropdownCountryData.iso2
    );

    this.setState({
      selectedCountry:
        dropdownCountryData.iso2 && dropdownCountryData.iso2.toUpperCase(),
      mobileLocalNumberLength: mobileLocalNumberData.mobileLocalNumberLength
    });
    onSelectFlag && onSelectFlag(a, dropdownCountryData);
    const isCountryUpdatedWithFetching =
      selectedCountry && selectedCountry === defaultCountry;
    const missMatchingAreaCodes =
      areaCodes?.length && areaCode && !areaCodes.includes(areaCode);

    if (isCountryUpdatedWithFetching) {
      this.checkNumber("");
    }
    if (
      (isCountryUpdatedWithFetching || !phone || missMatchingAreaCodes) &&
      onSelectArea
    ) {
      onSelectArea((areaCodes && areaCodes.length && areaCodes[0]) || "");
    }
    if (onSelectArea && areaCode && !mobileLocalNumberData.mobileLocalCode) {
      onSelectArea("");
    }
    if (
      phone &&
      mobileLocalNumberData.mobileLocalNumberLength !== phone.length
    ) {
      clearPhoneInput();
    }
    setSelectedCountryMobileDetails(mobileLocalNumberData);
  };

  componentWillUnmount() {
    const { selectedCountryMobileDetails, setSelectedCountryMobileDetails } =
      this.props;
    if (!isEmpty(selectedCountryMobileDetails)) {
      setSelectedCountryMobileDetails({});
    }
  }

  getMobileLocalNumberData = (countriesMobileDetails, countryShortName) =>
    countriesMobileDetails.find(
      country =>
        country.countrySHORT.toLowerCase() === countryShortName?.toLowerCase()
    ) || {};

  onAreaChange = (areaCode, modifiedPhone) => {
    const { onSelectArea } = this.props;
    onSelectArea && onSelectArea(areaCode, modifiedPhone);
  };

  _getAreaCodes = (countryData, selectedCountry, settings) => {
    const countryInfo =
      settings?.countrySHORT?.toLowerCase() === selectedCountry?.toLowerCase()
        ? settings
        : find(
            countryData,
            country => country.countrySHORT === selectedCountry
          );

    const mobileLocalCode = get(countryInfo, "mobileLocalCode");

    return mobileLocalCode ? mobileLocalCode.split(",") : [];
  };

  render() {
    const { selectedCountry } = this.state;
    const {
      countryData,
      areaCode,
      defaultCountry,
      phone,
      valid,
      handleCountryChange,
      onSelectArea,
      forwardedRef,
      withCountrySelection,
      countriesMobileDetails,
      getMobileDetailsFromCountryList,
      selectedCountryMobileDetails,
      setSelectedCountryMobileDetails,
      onChange,
      readonly,
      settings,
      ...otherProps
    } = this.props;
    const { mobileLocalNumberLength } = withCountrySelection
      ? {
          mobileLocalNumberLength: Math.max(
            ...getAcceptablePhoneLengths(selectedCountryMobileDetails)
          )
        }
      : this.props;
    let modifiedPhone = phone;
    const areaCodes = this._getAreaCodes(
      ...(withCountrySelection
        ? [countriesMobileDetails, selectedCountry, settings]
        : [countryData, defaultCountry, settings])
    );

    if (
      areaCodes &&
      areaCodes.length &&
      phone.length > mobileLocalNumberLength
    ) {
      modifiedPhone = modifiedPhone.slice(areaCode && areaCode.length);
    }
    const placeholder =
      mobileLocalNumberLength &&
      basicPlaceholder.substr(0, mobileLocalNumberLength + 1);
    const countriesShortNames = map(countriesMobileDetails, "countrySHORT");
    const intlTelInputProps = withCountrySelection
      ? {
          containerClassName: "intl-tel-input with-selection",
          onlyCountries: countriesShortNames,
          i18n: {
            ...countriesMobileDetails.reduce((acc, countryData) => {
              acc[countryData.countrySHORT.toLowerCase()] =
                countryData.countryName;
              return acc;
            }, {})
          }
        }
      : {
          containerClassName: "intl-tel-input",
          disabled: true,
          allowDropdown: false
        };
    const shouldIntlTelBeInitialized =
      (defaultCountry && !withCountrySelection) ||
      (defaultCountry && !isEmpty(countriesShortNames));

    return (
      <div
        className={cn("phone_container", {
          borderRed: !valid
        })}
      >
        <input
          type="text"
          className={cn("form-control", "phone_input", {
            "form-control": readonly,
            borderRed: !valid
          })}
          readOnly={readonly}
          name="phone"
          id="phone"
          maxLength={mobileLocalNumberLength}
          placeholder={placeholder}
          onChange={e => {
            this.checkNumber(e.target.value);
            onChange && onChange(e);
          }}
          value={modifiedPhone}
          ref={forwardedRef}
          aria-label="Phone"
          {...otherProps}
        />
        {!isEmpty(areaCodes) && (
          <CustomDropdown
            readonly={readonly}
            name={"location"}
            data={areaCodes}
            selectedItem={areaCode || areaCodes[0]}
            containerClass={"location_dropdown"}
            scrollbarMaxHeight={100}
            handleChange={area => this.onAreaChange(area, modifiedPhone)}
            scrollType={DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITH_INLINE_SCROLL}
          />
        )}

        {shouldIntlTelBeInitialized && (
          <div className="country_phone_select_wrapper">
            <IntlTelInputReact
              onChangeCountry={() => {
                const input = document.querySelector("#country-phone-select");
                const instance = window.intlTelInputGlobals.getInstance(input);
                const selectedCountryData = instance.getSelectedCountryData();
                if (
                  this.state.selectedCountry !==
                  selectedCountryData.iso2.toUpperCase()
                ) {
                  this.onSelectFlag(null, selectedCountryData);
                }
              }}
              initOptions={{
                initialCountry:
                  (defaultCountry && defaultCountry.toLocaleLowerCase()) || "",
                showSelectedDialCode: true,
                countrySearch: false,
                allowDropdown: withCountrySelection,
                fixDropdownWidth: false,
                ...intlTelInputProps
              }}
              inputProps={{
                id: "country-phone-select",
                disabled: !withCountrySelection,
                className: "country_flag_phone_select"
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  countriesMobileDetails: state.common.countriesMobileDetails,
  selectedCountryMobileDetails: state.common.selectedCountryMobileDetails,
  language: state.common.language,
  settings: state.common.settings
});
const mapDispatchToProps = {
  getMobileDetailsFromCountryList,
  setSelectedCountryMobileDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlInput);
