import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "clsx";

import { useAppSelector, useAppDispatch } from "@/redux/store";

import { useRouteSlug } from "@/hooks";

import { logOut } from "@/redux/actions/authConfig.action";
import { resetMyAccountDetails } from "@/redux/actions/myAccount.action";
import { resetCreditCards } from "@/redux/actions/checkout.action";
import { handleActionToSignPages } from "@/util/analyticsEvents";
import AuthAnaltyicsEvent from "@/services/analytics/main/authentication";
import CommonAnalyticsEvent from "@/services/analytics/main/common";

const AccountPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const routeSlug = useRouteSlug();

  const userName = useAppSelector(state => state.authReducer.firstname);
  const accessToken = useAppSelector(state => state.authReducer.accessToken);
  const lisaUrl = useAppSelector(
    state => state.common.configSettings?.lisaUrl?.url
  );

  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();

  const resetUserCredentials = () => {
    if (!accessToken) return;

    dispatch(logOut(true));
    dispatch(resetMyAccountDetails());
    dispatch(resetCreditCards());
    AuthAnaltyicsEvent.logout();
    handleAccountClick(t("header.signOut", { lng: "en" }));
  };

  const handleSignMethod = (toLogin = false) => {
    handleActionToSignPages(toLogin);
    handleAccountClick(
      t(toLogin ? "header.signIn" : "header.join", { lng: "en" })
    );
  };

  const handleAccountClick = linkText => {
    CommonAnalyticsEvent.accountOptions({
      optionLabel: linkText
    });
  };

  return (
    <div
      className={cn(
        "profile",
        isOpen && "dropdown_onlogin",
        accessToken && "user_after_login"
      )}
      onMouseEnter={() => {
        setIsOpen(true);
        CommonAnalyticsEvent.navClick({
          clickText: "profile icon",
          link: ""
        });
      }}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="header_dropdown">
        <div className="headerDropdownWrapper">
          <ul
            className={cn("user_profile_dropdown", {
              logged_in: accessToken
            })}
          >
            <li>
              <div>
                {accessToken ? (
                  <>
                    <a className="signed_in">
                      <div className="greet-user">
                        <span>{t("header.hello")} </span>
                        {userName?.replace("Welcome", "Hi")}{" "}
                      </div>
                    </a>

                    <a id="signUp" onClick={resetUserCredentials}>
                      <span>{t("header.signOut")}</span>
                    </a>
                  </>
                ) : (
                  <>
                    <Link
                      to={`/${routeSlug}/login/`}
                      className="user_greeting"
                      onClick={() => handleSignMethod(true)}
                    >
                      <span className="user_sign_in" id="signIn">
                        {t("header.signIn")}
                      </span>
                    </Link>

                    <Link
                      to={`/${routeSlug}/sign-up/`}
                      onClick={() => handleSignMethod(false)}
                    >
                      <span className="user_join">{t("header.join")}</span>
                    </Link>
                  </>
                )}
              </div>
            </li>
            <li>
              <Link
                to={
                  accessToken
                    ? `/${routeSlug}/my-account/account-overview/`
                    : `/${routeSlug}/login/?my-account/account-overview/`
                }
                onClick={() =>
                  handleAccountClick(t("header.myAccount", { lng: "en" }))
                }
              >
                {t("header.myAccount")}
              </Link>
            </li>
            <li>
              <Link
                to={
                  accessToken
                    ? `/${routeSlug}/my-account/my-orders/`
                    : `/${routeSlug}/login/?my-account/my-orders/`
                }
                onClick={() =>
                  handleAccountClick(t("header.myOrders", { lng: "en" }))
                }
              >
                {t("header.myOrders")}
              </Link>
            </li>
            <li>
              <Link
                to={
                  accessToken
                    ? `/${routeSlug}/my-account/my-orders/`
                    : `/${routeSlug}/track-order/`
                }
                onClick={() =>
                  handleAccountClick(t("header.trackOrders", { lng: "en" }))
                }
              >
                {t("header.trackOrders")}
              </Link>
            </li>
            {lisaUrl && (
              <li>
                <Link
                  to={`/${routeSlug}${lisaUrl}`}
                  onClick={() =>
                    handleAccountClick(t("header.liveShopping", { lng: "en" }))
                  }
                >
                  {t("header.liveShopping")}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccountPopup;
