import {
  GET_WISHLISTS,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_PAGINATION,
  SELECTED_SIZE,
  EMPTY_WISHLIST_CART
} from "../constants";

import { Wishlist, WishlistPagination } from "../../services/wishlistService";

type WishlistState = {
  wishList: Wishlist;
  wishListBaseData: any[];
  wishListBaseDataLoaded: boolean;
  length: number;
  pagination: Partial<WishlistPagination>;
  wishListAndCartGeneralLoaded: boolean;
  deleteWishListItemLoaded: boolean;
};
type PartialWishlistState = Partial<WishlistState>;

const initialState: PartialWishlistState = {
  wishList: [],
  wishListBaseData: [],
  wishListBaseDataLoaded: false,
  length: 0,
  pagination: {},
  wishListAndCartGeneralLoaded: false,
  deleteWishListItemLoaded: false
};

const getWishList = (state: PartialWishlistState, { wishList }) => ({
  ...state,
  wishList
});
const setWishlistLength = (state: PartialWishlistState, { length }) => ({
  ...state,
  length
});
const setWishlistPagination = (
  state: PartialWishlistState,
  { pagination }
) => ({
  ...state,
  pagination
});

const selectedSize = (
  state: PartialWishlistState,
  { selectedSizeValue, sizeId, sizeType, _id }
) => ({
  ...state,
  wishList: state.wishList.map(product => {
    if (product._id === _id) {
      return { ...product, size: selectedSizeValue, sizeId, sizeType };
    }
    return product;
  })
});

const clearWishlist = (): PartialWishlistState => {
  return {
    ...initialState
  };
};

const wishListReducer = (
  state: PartialWishlistState = initialState,
  action
): PartialWishlistState => {
  switch (action.type) {
    case GET_WISHLISTS:
      return getWishList(state, action);
    case SET_WISHLIST_LENGTH:
      return setWishlistLength(state, action);
    case SET_WISHLIST_PAGINATION:
      return setWishlistPagination(state, action);
    case SELECTED_SIZE:
      return selectedSize(state, action);
    case EMPTY_WISHLIST_CART:
      return clearWishlist();
    default:
      return state;
  }
};

export default wishListReducer;
