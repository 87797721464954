import cloneDeep from "lodash/cloneDeep";
import {
  STORE_LOCATOR_LIST,
  CHANGE_SELECTED_COUNTRY,
  CHANGE_SELECTED_SHOP
} from "../constants";

type StoreLocatorState = {
  storeCountry: string[];
  selectedCountry: string;
  selectedShop: Record<string, any>;
  locatorList: any[];
  cityList: any[];
  otherInfo: string;
};

const initialState: StoreLocatorState = {
  storeCountry: [],
  selectedCountry: "",
  selectedShop: {},
  locatorList: [],
  cityList: [],
  otherInfo: ""
};

const getSelectedShops = (locatorList, selectedCountry) =>
  locatorList.filter(item => item.country === selectedCountry);

const storeLocatorList = (
  state: StoreLocatorState,
  { locatorList, countryId, countryData }
) => {
  const newStoreCountry = new Set<string>();
  const newLocationList = cloneDeep(locatorList);
  let updatedLocatorList = newLocationList.map(item => {
    let itemCountryId = item.countryId;
    const countryDetails =
      countryData &&
      countryData.find(item => {
        return item.countryId === itemCountryId;
      });

    item.country = (countryDetails && countryDetails.countryName) || "";
    return item;
  });

  updatedLocatorList.forEach((item, i) => newStoreCountry.add(item.country));
  const _selectedShop =
    updatedLocatorList &&
    updatedLocatorList.filter(item => item.countryId === countryId);
  return {
    ...state,
    locatorList: updatedLocatorList,
    storeCountry: [...newStoreCountry],
    selectedCountry:
      _selectedShop && _selectedShop.length && _selectedShop[0].country,
    selectedShop: _selectedShop &&
      _selectedShop.length &&
      _selectedShop[0].shops && {
        ..._selectedShop[0].shops[0],
        station_id: _selectedShop && _selectedShop[0].shops[0].id,
        countryName: _selectedShop && _selectedShop[0].country
      },
    cityList: getSelectedShops(
      updatedLocatorList,
      _selectedShop && _selectedShop.length && _selectedShop[0].country
    )
  };
};

const changeSelectedCountry = (
  state: StoreLocatorState,
  { selectedCountry }
) => ({
  ...state,
  selectedCountry,
  cityList: getSelectedShops(state.locatorList, selectedCountry)
});

const changeSelectedShop = (state: StoreLocatorState, { selectedShop }) => ({
  ...state,
  selectedShop: { ...selectedShop, countryName: state.selectedCountry }
});

const storeLocatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_LOCATOR_LIST:
      return storeLocatorList(state, action);
    case CHANGE_SELECTED_COUNTRY:
      return changeSelectedCountry(state, action);
    case CHANGE_SELECTED_SHOP:
      return changeSelectedShop(state, action);
    default:
      return state;
  }
};

export default storeLocatorReducer;
