import isFunction from "lodash/isFunction";
import get from "lodash/get";
import { isServer } from "../util";

class SyteAISubscriptionService {
  // All the subscriptions should be added to SyteEvents array before the syte.ai script is loaded!
  syteEvents = [];

  verifyCallback(callback) {
    return callback && isFunction(callback);
  }

  setProductClickedSubscription(callback) {
    if (this.verifyCallback(callback)) {
      this.syteEvents.push({ name: "productClicked", callback });
    }
  }

  setViewSimilarClickedSubscription(callback) {
    if (this.verifyCallback(callback)) {
      this.syteEvents.push({ name: "discoveryButtonClick", callback });
    }
  }
  applySubscriptions() {
    if (!isServer && this.syteEvents.length) {
      //@ts-ignore
      window.SyteEvents = this.syteEvents;
    }
  }
}

const instance = new SyteAISubscriptionService();

export default instance;
