import GoogleAnalytics from "../../googleAnalytics";
import { AddProductToCart } from "../pdp/type";
import { AddToWishlistFromCart, RemoveFromCart, ViewCart } from "./types";

class CartAnalyticsService {
  addToWishlist(payload: AddToWishlistFromCart) {
    GoogleAnalytics.addToWishlist(payload, true);
  }
  viewCart(payload: ViewCart) {
    GoogleAnalytics.viewCart(payload);
  }
  addToCart(payload: AddProductToCart) {
    GoogleAnalytics.addToCart(payload);
  }
  removeFromCart(paylaod: RemoveFromCart) {
    GoogleAnalytics.removeFromCart(paylaod, true);
  }
}

const CartAnalyticsEvent = new CartAnalyticsService();
export default CartAnalyticsEvent;
