import CheckoutAnalyticService from "./CheckoutAnalyticService";
import CartAnalyticService from "./CartAnalyticService";
import CommonAnalyticService from "./CommonAnalyticService";
import AuthAnalyticService from "./AuthAnalyticService";
import SyteAnalyticService from "./SyteAnalyticService";
import ProductAnalyticService from "./ProductAnalyticService";
import OrderAnalyticService from "./OrderAnalyticService";
import BannerAnalyticService from "./BannerAnalyticService";
import CustomerAnalyticsService from "./CustomerAnalyticsService";

class AnalyticService {
  checkout = new CheckoutAnalyticService();
  cart = new CartAnalyticService();
  auth = new AuthAnalyticService();
  syte = new SyteAnalyticService();
  product = new ProductAnalyticService();
  order = new OrderAnalyticService();
  common = new CommonAnalyticService();
  banner = new BannerAnalyticService();
  customer = new CustomerAnalyticsService();
}

const instance = new AnalyticService();

export default instance;
