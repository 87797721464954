import AuthAnaltyicsEvent from "../../services/analytics/main/authentication";
import CommonAnalyticsEvent from "../../services/analytics/main/common";

export const handleActionToSignPages = (toLogin: boolean = false) => {
  AuthAnaltyicsEvent.actionToAuthPage({
    isLogin: !!toLogin
  });
};

export const handleOtpValidationResponse = (isValidOTP = null) => {
  if (isValidOTP) {
    CommonAnalyticsEvent.validatedOTP();
  }
};
