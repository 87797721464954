import React, { useEffect, useRef, useState } from "react";
import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import cn from "clsx";
import { useHistory } from "react-router-dom";
import UserDetailsForm from "./userDetailsForm";
import CommonService from "@/services/CommonService";
import "./style.scss";
import MobileSwipeableDrawer from "@/components/common/mobileSwipeableDrawer";
import DialogModal from "@/components/dialogModal";
import { useRouteSlug } from "@/hooks";

const DynamicQRVideo = ({ bannerData, generalData }) => {
  const [videoLoading, setVideoLoading] = useState(true);
  const [videoFinished, setVideoFinished] = useState(false);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showStartButton, setShowStartButton] = useState(true);
  const [message, setMessage] = useState("");
  const [alertOpened, setAlertOpened] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const videoWrapperRef = useRef(null);
  const videoRef = useRef(null);
  const history = useHistory();
  const routeSlug = useRouteSlug();
  const { t } = useTranslation("dynamicModules");
  const videoUrl = (find(bannerData, { key: "videoUrl" }) || {}).value;
  const videoId = (find(bannerData, { key: "videoId" }) || {}).value;

  useEffect(() => {
    const fetchVideoRecord = async () => {
      const res = await CommonService.getVideoRecord(videoId);
      setIsVideoWatched(res.isWatched);
      if (res.message) {
        setMessage(res.message);
      }
    };
    fetchVideoRecord();
  }, [videoId]);

  const onStartBtnClick = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setShowStartButton(false);
    setVideoFinished(false);
  };

  const onSubmitMobile = (message: string) => {
    setIsDrawerOpen(false);
    setAlertOpened(true);
    setMessage(message);
  };

  const onSubmitWeb = (message: string) => {
    setMessage(message);
  };

  const onVideoEnded = () => {
    setVideoFinished(true);
    setShowStartButton(true);
    if (isMobile) {
      setIsDrawerOpen(true);
    }
  };

  return (
    <div style={generalData.styles} className="dynamic-qr-video">
      <div className="video-wrapper">
        {message && isVideoWatched && (
          <div
            className={cn("messageContainer", {
              mobileMessageContainer: isMobile
            })}
          >
            <p className="message">{message}</p>
          </div>
        )}
        <div style={{ position: "relative" }} ref={videoWrapperRef}>
          <video
            ref={videoRef}
            muted
            playsInline
            width={isMobile ? "100%" : "60%"}
            onLoadedData={() => {
              setVideoLoading(false);
              videoWrapperRef.current.style.background = "none";
            }}
            onEnded={onVideoEnded}
          >
            <source src={`${videoUrl}#t=0.001`} type="video/mp4" />
          </video>
          {showStartButton && !videoLoading && (
            <div className="button_container">
              <button onClick={onStartBtnClick} className="play-button">
                <div className="play-icon"></div>
              </button>
            </div>
          )}
        </div>
        {message && !isMobile && !isVideoWatched && (
          <div className="messageContainer">
            <p className="message">{message}</p>
          </div>
        )}
      </div>
      {videoFinished &&
        !isVideoWatched &&
        (isMobile ? (
          <MobileSwipeableDrawer
            classname="qr_video_dropdown"
            head={
              <div className="head_mobile_text">
                <p className="standChance">{t("standChance")}</p>
                <p className="enterDetails">{t("enterDetails")}</p>
              </div>
            }
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <div className="form-wrapper mobile-form">
              <UserDetailsForm videoId={videoId} onSubmit={onSubmitMobile} />
            </div>
          </MobileSwipeableDrawer>
        ) : (
          !message && (
            <>
              <div className="form-wrapper web-form">
                <div className="head_web_text">
                  <p className="standChance">{t("standChance")}</p>
                  <p className="enterDetails">{t("enterDetails")}</p>
                </div>
                <UserDetailsForm videoId={videoId} onSubmit={onSubmitWeb} />
              </div>
            </>
          )
        ))}
      <DialogModal
        classname="qr_video_dialog_modal"
        showModal={alertOpened}
        bodyText={message}
        confirmButtonText={"Ok"}
        onConfirm={e => {
          e.stopPropagation();
          setAlertOpened(false);
          history.replace(`/${routeSlug}/`);
        }}
      />
    </div>
  );
};

export default DynamicQRVideo;
