import axios from "axios";
import _isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { ContextStorage } from "@/libs/contextStorage";

import {
  isServer,
  getDeviceType,
  getUniqueId,
  getVisitorId,
  getCountryId
} from "../util";
import { getCheckoutGuestToken } from "@/util/browserStorage";

function getConfig(
  accessToken,
  visitorId = getVisitorId() || "",
  language,
  countryId,
  storeId,
  defaultHeaders,
  isPrevMode,
  ipcountry
) {
  let cancel;
  const reduxState = ContextStorage.store.getState();
  const _countryId = get(
    reduxState,
    "common.settings.countryId",
    getCountryId()
  );
  const currencyCode = get(reduxState, "common.settings.currencyCode", "AED");
  const _language = get(reduxState, "common.language", "en");
  const deviceTypeSSR = get(reduxState, "common.deviceType", "");
  const tierToken = get(reduxState, "myAccountReducer.tierToken", "");
  const deviceType = deviceTypeSSR || getDeviceType();
  const checkoutGuestToken = getCheckoutGuestToken();
  const header = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/json",
    "x-access-token":
      checkoutGuestToken || _isEmpty(accessToken) ? "" : accessToken,
    "x-language": _language,
    "x-countryid": _countryId || 236,
    "x-currency-code": currencyCode || "AED",
    "x-platform": "web", //From mobile app it would be android | ios
    "x-device": deviceType,
    visitorid: visitorId,
    "x-uid": getUniqueId(),
    ...(tierToken && { "x-tier-token": tierToken }),
    ...(checkoutGuestToken && { "x-guest-token": checkoutGuestToken })
  };
  const defaultHeader = {
    "Content-Type": "application/json"
  };

  const requestHeaders = { ...(defaultHeaders ? defaultHeader : header) };

  if (isServer && ipcountry) {
    Object.assign(requestHeaders, { "user-ipcountry": ipcountry });
  }

  const config = {
    headers: requestHeaders
  };

  if (language) {
    config.headers = {
      ...config.headers,
      language: _language
    };
  }
  if (storeId) {
    config.headers = {
      ...config.headers,
      storeId: storeId
    };
  }

  if (isPrevMode) {
    config.headers = {
      ...config.headers,
      prevMode: true
    };
  }

  return {
    cancel,
    config
  };
}

export function webapiGet(
  accessToken,
  url,
  visitorId,
  language,
  countryId,
  storeId,
  defaultHeaders,
  isPrevMode,
  ipcountry
) {
  const config = getConfig(
    accessToken,
    visitorId,
    language,
    countryId,
    storeId,
    defaultHeaders,
    isPrevMode,
    ipcountry
  );
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return {
    request: axios.get(url, { ...config.config, cancelToken: source.token }),
    cancel: config.cancel || source.cancel
  };
}

export function webapiGetWithParams(accessToken, url, options, visitorId) {
  const config = getConfig(accessToken, visitorId);
  return {
    request: axios.get(url, { params: options }),
    cancel: config.cancel
  };
}
export function webapiPut(accessToken, url, options, visitorId) {
  const config = getConfig(accessToken, visitorId);
  return {
    request: axios.put(url, options, config.config),
    cancel: config.cancel
  };
}

export function webapiPatch(accessToken, url, options, visitorId) {
  const config = getConfig(accessToken, visitorId);

  return {
    request: axios.patch(url, options, config.config),
    cancel: config.cancel
  };
}

export function webapiPost(
  accessToken,
  url,
  options,
  visitorId,
  language,
  defaultHeaders,
  isPrevMode,
  ipcountry
) {
  const config = getConfig(
    accessToken,
    visitorId,
    language,
    defaultHeaders,
    null,
    null,
    isPrevMode,
    ipcountry
  );

  return {
    request: axios.post(url, options, config.config),
    cancel: config.cancel
  };
}

export function webapiDelete(accessToken, url, visitorId) {
  const config = getConfig(accessToken, visitorId);
  return {
    request: axios.delete(url, config.config),
    cancel: config.cancel
  };
}

export function webapiAuthPost(url, options) {
  return {
    request: axios.post(url, options)
  };
}
