import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "clsx";
import Headroom from "react-headroom";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { useIsRTL, useOutsideEventDetector, useRouteSlug } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setIsHeaderFixed } from "@/redux/actions/common.action";
import {
  headerBannerSectionsSelector,
  selectSyteSettings
} from "@/util/selectors";
import { isServer } from "@/util";
import useBodyScrollability from "@/hooks/useBodyScrollability";
import SyteSearchWithTooltip from "../syte/syteButton/syteSearchWithTooltip";
import DynamicBannersWrapper from "../dynamic/dynamicBanner";
import MobileMenu from "./mobile-menu";
import UserPreferencesModal from "../modal/modal";
import UserPreferences from "../userPreferences";
import ProfileButtons from "./profileButtons";
import { CartIconMobile } from "./profileButtons/cartPopup";
import Search from "../search";
import searchIcon from "@/images/search-icon.svg";

const MobileHeader = ({ showOnlyBanner }: { showOnlyBanner: boolean }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openUserPreferenceModal, setOpenUserPreferenceModal] = useState(false);
  const isRTL = useIsRTL();
  const slug = useRouteSlug();
  const { headerBottomSections } = useAppSelector(headerBannerSectionsSelector);
  const configSyteSettings = useAppSelector(selectSyteSettings);
  const { enableBodyScroll, disableBodyScroll } = useBodyScrollability();
  const isCameraSearchEnabled = configSyteSettings?.enableCameraSearch;
  const languageClassName = isRTL ? "arabic" : "english";
  const isHeaderFixed = useAppSelector(state => state.common.isHeaderFixed);
  const dispatch = useAppDispatch();

  const fixedHeadroomStyle = {
    position: "fixed",
    zIndex: 100
  };

  const clickOutsideDetectorRef =
    useOutsideEventDetector<HTMLHeadingElement>(() => {
      searchOpen && setSearchOpen(false);
    }, ["click"]);

  const handleMenuOpen = () => {
    setMenuOpen(true);
    disableBodyScroll();
  };

  const closeMenu = () => {
    setMenuOpen(false);
    enableBodyScroll();
  };
  useEffect(() => {
    dispatch(setIsHeaderFixed(searchOpen));
    return () => {
      dispatch(setIsHeaderFixed(false));
    };
  }, [searchOpen]);

  useEffect(() => {
    if (!isServer) {
      if (searchOpen) {
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    }
  }, [searchOpen]);

  const handleSyteSearch = () => CommonAnalyticsEvent.syteSearchInteraction();

  const closeModal = useCallback((e?) => {
    e?.stopPropagation();
    setOpenUserPreferenceModal(false);
  }, []);

  const handleTopNavClick = (name: string, url?: string) => {
    CommonAnalyticsEvent.navTopClick({
      name,
      url
    });
  };

  return (
    <>
      {showOnlyBanner && (
        <>
          <UserPreferencesModal
            modalClass={cn("generic_modal", "country_selection_modal", {
              arabic: isRTL
            })}
            showModal={openUserPreferenceModal}
            overlayClassName="countryOverlay"
            closeModal={closeModal}
          >
            <button className="cross_btn" onClick={closeModal}>
              &times;
            </button>
            {openUserPreferenceModal && (
              <UserPreferences
                // @ts-ignore
                closeModal={closeModal}
                zIndex={150}
              />
            )}
          </UserPreferencesModal>
          <div
            className={cn("header_wrap to_fixed", {
              arabic: isRTL
            })}
          >
            <Headroom
              disableInlineStyles
              disable={isHeaderFixed}
              {...(isHeaderFixed && { style: fixedHeadroomStyle })}
            >
              <header>
                <div
                  className={cn(
                    "m_head_wrap",
                    "m_header",
                    {
                      overlay_menu: menuOpen
                    },
                    languageClassName
                  )}
                >
                  <div className="mobile_header_wrap">
                    <div className="mobile_header">
                      <div className="mobile_logo">
                        <span
                          className="hamburger_menu"
                          onClick={handleMenuOpen}
                        />
                        <Link
                          to={`/${slug}/`}
                          className={cn(
                            "store_logo",
                            isRTL ? "arabicStoreLogo" : "engStoreLogo"
                          )}
                          onClick={() => handleTopNavClick("home", `/${slug}/`)}
                        />
                      </div>
                      <div className="user_settings">
                        {isCameraSearchEnabled && (
                          <SyteSearchWithTooltip
                            //@ts-ignore
                            mobile
                            handleClick={handleSyteSearch}
                          />
                        )}

                        <div
                          className="search_bar"
                          onClick={e => {
                            e.stopPropagation();
                            setSearchOpen(!searchOpen);
                            CommonAnalyticsEvent.inputSearchInteraction();
                          }}
                        />
                        {searchOpen && (
                          <div
                            className={cn(
                              "search_popup",
                              searchOpen ? "search_active" : "search_inactive"
                            )}
                            ref={clickOutsideDetectorRef}
                          >
                            <span
                              className="back_btn"
                              onClick={() => {
                                setSearchOpen(false);
                              }}
                            >
                              <img src={searchIcon} alt="arrow back" />
                            </span>
                            <Search
                              expandSection
                              onSearchApply={() => {
                                setSearchOpen(false);
                              }}
                              defaultSearchText=""
                              autoFocus
                            />
                            <span
                              className="cross-btn"
                              onClick={() => {
                                setSearchOpen(false);
                              }}
                            >
                              &times;
                            </span>
                          </div>
                        )}
                        <ProfileButtons CartPopup={CartIconMobile} />
                      </div>
                    </div>
                  </div>
                  <div className="menuOverlay" onClick={closeMenu} />
                  {menuOpen && (
                    <div
                      className={cn(
                        "slide_menu",
                        menuOpen ? "slide_true" : "slide_false",
                        languageClassName
                      )}
                    >
                      <span className="menuCross" onClick={closeMenu} />
                      <MobileMenu
                        closeMenu={closeMenu}
                        setOpenUserPreferenceModal={setOpenUserPreferenceModal}
                      />
                    </div>
                  )}
                </div>
              </header>
            </Headroom>
          </div>
        </>
      )}

      {headerBottomSections?.map((section, index) => (
        <DynamicBannersWrapper
          section={section}
          key={index}
          bannerPageTitle={section.sectionTitle}
        />
      ))}
    </>
  );
};

export default MobileHeader;
