import React, { useState } from "react";
import { useFormik } from "formik";
import cn from "clsx";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useFormActivityHandler from "@/services/analytics/hooks/useFormActivityHandler";
import CommonService, { CreateVideoRecord } from "@/services/CommonService";
import { emailValidationRegExp } from "@/constants";
import IntlTelInput from "@/components/intlTelInput/intlTelInput";
import { ButtonWithLoader } from "@/components/common/buttons";
import { useAppSelector } from "@/redux/store";
import {
  selectAcceptablePhoneLengths,
  selectCountryData,
  selectCountryShort,
  selectMobileLocalNumberLength,
  selectMobileCountryCode
} from "@/util/selectors";

const UserDetailsForm = ({
  videoId,
  onSubmit: onSubmitClick
}: {
  videoId: string;
  onSubmit?: (message?: string) => void;
}) => {
  const { t } = useTranslation(["login", "checkout"]);
  const [lastFocusedFormField, setLastFocusedFormField] = useState("");
  const acceptablePhoneLengths = useAppSelector(selectAcceptablePhoneLengths);
  const countryData = useAppSelector(selectCountryData);
  const countrySHORT = useAppSelector(selectCountryShort);
  const mobileLocalNumberLength = useAppSelector(selectMobileLocalNumberLength);
  const defaultAreaCode = useAppSelector(state =>
    state.common.settings?.mobileLocalCode?.split(",")
  );
  const mobileCountryCode = useAppSelector(selectMobileCountryCode);
  const formikState = useFormik({
    initialValues,
    onSubmit: async data => {
      const defaultArea = defaultAreaCode[0] || "";
      const payload: CreateVideoRecord = {
        name: data.firstName,
        mobileNumber: `${mobileCountryCode}${data.phoneArea || defaultArea}${
          data.phoneNumber
        }`,
        email: data.email,
        videoId
      };
      const res = await CommonService.createVideoRecord(payload);
      resetForm();
      onSubmitClick?.(res.message);
    },
    validationSchema: validationSchema(acceptablePhoneLengths)
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting
  } = formikState;

  useFormActivityHandler({
    formikState,
    formName: "qr video form",
    onErrors(errors) {
      console.error(errors);
    },
    lastFocusedFormField
  });

  const isInputInvalid = (valueKey: string) =>
    Boolean(touched[valueKey] && errors[valueKey]);

  const saveFocusedInputName = e => {
    setLastFocusedFormField(e.currentTarget.name);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className={cn("form-control", {
              borderRed: isInputInvalid("firstName")
            })}
            name="firstName"
            placeholder={t("checkout:firstName")}
            onChange={handleChange}
            value={values.firstName}
            aria-label="First name"
          />
          {isInputInvalid("firstName") && (
            <span className="error">{t("checkout:enterFirstName")}</span>
          )}
        </div>

        <div className="form-group">
          <input
            type="email"
            name="email"
            className={cn("form-control", {
              borderRed: isInputInvalid("email")
            })}
            placeholder={t("login:email")}
            value={values.email}
            onChange={handleChange}
            aria-label="Email"
            onFocus={saveFocusedInputName}
          />
          {isInputInvalid("email") && (
            <span className="error">{t("login:emailRequired")}</span>
          )}
        </div>
        <div className="form-group">
          <IntlTelInput
            valid={!isInputInvalid("phoneNumber")}
            phone={values.phoneNumber}
            areaCode={values.phoneArea}
            handleCountryChange={(e, phone) =>
              setFieldValue("phoneNumber", phone)
            }
            onSelectArea={area => setFieldValue("phoneArea", area)}
            defaultCountry={countrySHORT}
            countryData={countryData}
            mobileLocalNumberLength={mobileLocalNumberLength}
          />

          {isInputInvalid("phoneNumber") && (
            <span className="error">{t("checkout:enterValidPhoneNumber")}</span>
          )}
        </div>

        <ButtonWithLoader
          showLoader={isSubmitting}
          type="submit"
          className="btn btn-default round_btn form_black_btn record_video_btn"
        >
          {t("login:submit")}
        </ButtonWithLoader>
      </form>
    </>
  );
};

const initialValues = {
  firstName: "",
  phoneNumber: "",
  phoneArea: "",
  email: ""
};

const validationSchema = acceptablePhoneLengths =>
  Yup.object().shape({
    firstName: Yup.string().trim().min(1).required(),
    phoneNumber: Yup.string()
      .test(
        "is-valid-length",
        () =>
          `Phone number must be ${acceptablePhoneLengths?.join?.(
            " or "
          )} characters long.`,
        value => value?.length && acceptablePhoneLengths.includes(value.length)
      )
      .required(),
    phoneArea: Yup.number(),
    email: Yup.string().trim().matches(emailValidationRegExp).required()
  });

export default UserDetailsForm;
