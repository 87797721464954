import React, { FC } from "react";
import "./style.scss";

const PreviewMode: FC<{ exitPrevMode: () => void }> = ({ exitPrevMode }) => (
  <div className="container prev_mode_btn">
    &nbsp;
    <button onClick={exitPrevMode}>
      Preview Mode <span className="cross_icon">&times;</span>
    </button>
  </div>
);

export default PreviewMode;
