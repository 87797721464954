import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ReactCookieProps, withCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import debounce from "lodash/debounce";
import get from "lodash/get";
import cn from "clsx";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import {
  getSearchSuggestions,
  getSearchTrending
} from "@/redux/features/search";

import { getDataAsObject } from "../../util/common";

import SearchResults from "./searchResults";
import SyteSearchWithTooltip from "../syte/syteButton/syteSearchWithTooltip";
import { selectSyteSettings } from "../../util/selectors";
import { AnalyticService } from "@/services";
import CommonAnalyticsEvent from "../../services/analytics/main/common";
import { useLanguage, useOutsideEventDetector, useRouteSlug } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import RecommendedProducts from "./recommendedProducts";
import "./style.scss";

const searchUrl = "search";

const Search: FC<
  {
    expandSection: boolean;
    className?: string;
    onSearchApply?: (searchQuery: string) => void;
    defaultSearchText?: string;
    autoFocus?: boolean;
  } & ReactCookieProps
> = ({
  cookies,
  expandSection,
  className,
  onSearchApply,
  defaultSearchText,
  autoFocus
}) => {
  const { t } = useTranslation("userPreferences");
  const [displayResult, setDisplayResult] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const location = useLocation();
  const [searchText, setSearchText] = useState(
    defaultSearchText || (qs.parse(location.search).q as string) || ""
  );
  const language = useLanguage();
  const history = useHistory();
  const routeSlug = useRouteSlug();
  const trending = useAppSelector(state => state.search.trending) || [];
  const suggestions = useAppSelector(state => state.search.suggestions) || [];
  const queryId = useAppSelector(state => state.search.queryId);
  const clickOutsideDetectorRef =
    useOutsideEventDetector<HTMLHeadingElement>(() => {
      displayResult && setDisplayResult(false);
    }, ["click", "touchstart"]);

  const configSyteSettings = useAppSelector(selectSyteSettings);
  const dispatch = useAppDispatch();
  const searchInputRef = useRef(null);

  useEffect(() => {
    setSearchHistory(cookies.get("searchHistory") || []);
  }, []);

  useEffect(() => {
    setDisplayResult(expandSection);
    setSearchText(defaultSearchText);
  }, [expandSection]);

  useEffect(() => {
    const { q } = qs.parse(location.search);
    if (!q && searchText) {
      setSearchText("");
    }
  }, [location.search]);

  useEffect(() => {
    if (`/${routeSlug}/` === location.pathname) {
      setSearchText("");
    }
  }, [location.pathname]);

  const handleSearch = e => {
    e.preventDefault();
  };

  const setCookie = cookieData => {
    cookies.set("searchHistory", cookieData);
    setSearchHistory(cookieData);
  };

  const onSearchFocus = () => {
    CommonAnalyticsEvent.inputSearchInteraction();
    setDisplayResult(true);
    dispatch(getSearchTrending({ language }));
  };
  const debouncedHistoryUpdate = useCallback(
    debounce(url => {
      history.push(url);
    }, 400),
    []
  );

  const debouncedGetSuggestions = useCallback(
    debounce(singleSpacedValue => {
      dispatch(
        getSearchSuggestions({
          value: singleSpacedValue,
          language
        })
      );
    }, 400),
    []
  );

  const handleTextChange = e => {
    const targetValue = e.target.value;
    const singleSpacedValue = targetValue.replace(/  +/g, " ").trimStart();
    const searchValue = qs.parse(location.search).q as string;
    setSearchText(singleSpacedValue);
    if (!targetValue && searchValue) {
      history.push(`/${routeSlug}/`);
      searchInputRef.current.blur();
      return;
    }

    singleSpacedValue && debouncedGetSuggestions(singleSpacedValue);
  };

  const applySearch: (
    searchQuery: string,
    searchCategory: SEARCH_SECTION
  ) => void = (searchQuery, searchCategory) => {
    setSearchCookie(searchQuery);
    const url = qs.stringifyUrl(
      {
        url: `/${routeSlug}/${searchUrl}/`,
        query: { q: searchQuery }
      },
      { encode: false }
    );

    history.push(url, {
      fromSearch: true,
      searchPayload: {
        searchTerm: searchQuery,
        searchCategory,
        searchType: "text"
      }
    });
    CommonAnalyticsEvent.search({
      searchTerm: searchText,
      searchCategory: SEARCH_SECTION.KEYWORDS,
      clickText: searchQuery,
      searchResultCount: suggestions.length
    });
    AnalyticService.product.trackProductSearch({
      text: searchQuery || searchText,
      itemCount: suggestions.length
    });
    onSearchApply && onSearchApply(searchQuery);
    setDisplayResult(false);
  };

  const onSearchOptionClick = (
    searchQuery: string,
    section: SEARCH_SECTION
  ) => {
    CommonAnalyticsEvent.search({
      searchTerm: searchText,
      searchCategory: SEARCH_SECTION.KEYWORDS,
      clickText: searchQuery,
      searchResultCount: suggestions.length
    });
    AnalyticService.product.trackProductSearch({
      text: searchQuery || searchText,
      itemCount: suggestions.length
    });

    setSearchText(searchQuery);
    applySearch(searchQuery, section);
  };

  const handleKeyPress: (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void = e => {
    if (e.key === "Enter" && searchText) {
      applySearch(searchText, SEARCH_SECTION.DIRECT);
    }
  };

  const setSearchCookie = value => {
    if (!value.trim()) {
      return null;
    }

    let cookieData = [value];

    const cookiesStringify = JSON.stringify(cookies.get("searchHistory"));

    if (cookiesStringify) {
      const cookiesParsed =
        cookiesStringify && getDataAsObject(cookiesStringify);
      cookieData =
        cookiesParsed.indexOf(value) !== -1
          ? [...cookiesParsed]
          : [...cookiesParsed, value.replace(/<\/?[^>]+(>|$)/g, "")];
    }
    setCookie(cookieData);
  };

  const deleteHistory = (e, deleteItem) => {
    setCookie(searchHistory.filter(item => item !== deleteItem));

    if (e) e.stopPropagation();
  };

  const moveToProductDetail = item => {
    setDisplayResult(false);
    const queryIdUrl = queryId ? `?queryId=${queryId}` : "";
    const newUrl = `/${routeSlug}/${item.url}${queryIdUrl}`;

    history.push(newUrl);
    onSearchApply && onSearchApply(item.url);
    setDisplayResult(false);
    CommonAnalyticsEvent.recommendedProductSearch({
      searchTerm: searchText,
      searchCategory: SEARCH_SECTION.RECOMMENDED_PRODUCTS,
      clickText: item.title.defaultValue,
      searchResultCount: 1
    });
  };

  const handleSyteSearch = () => {
    CommonAnalyticsEvent.syteSearchInteraction();
  };

  const isCameraSearchEnabled = configSyteSettings?.enableCameraSearch;

  const onIconClick = e => {
    if (displayResult) {
      const { q } = qs.parse(location.search);
      if (q || searchText) {
        e.stopPropagation();
        setSearchText("");

        q && history.push(`/${routeSlug}/`);
      }
      setDisplayResult(false);
    } else {
      searchText && applySearch(searchText, SEARCH_SECTION.DIRECT);
    }
  };

  return (
    <div
      className={cn("search", displayResult && "expand_search", className)}
      ref={clickOutsideDetectorRef}
    >
      {isCameraSearchEnabled && (
        <SyteSearchWithTooltip handleClick={handleSyteSearch} mobile={false} />
      )}
      <form onSubmit={handleSearch}>
        <input
          ref={searchInputRef}
          id="search_input"
          type="text"
          onFocus={onSearchFocus}
          onChange={handleTextChange}
          onKeyDown={handleKeyPress}
          value={searchText}
          placeholder={t("searchPlaceHolder")}
          autoComplete="off"
          aria-label="Search"
          //autofocus was added for now, as IOS doesn't allow the use focus property on input on mobile devices
          autoFocus={autoFocus}
        />
        <span className="search_icon" onClick={onIconClick} />
      </form>
      {displayResult && (
        <div className="searchContainer">
          <div className="search_wrapper">
            <Scrollbars
              className="scroll_search"
              autoHeight
              autoHeightMin={215}
              autoHeightMax={445}
            >
              {searchText ? (
                <div className="search_matching_recommended">
                  <div className="search_matching">
                    <h5>{t("matchingKeywords")}</h5>
                    <ul>
                      {suggestions.map(item => (
                        <SearchResults
                          key={item.query.value}
                          item={item}
                          moveToProductListing={searchQuery =>
                            onSearchOptionClick(
                              searchQuery,
                              SEARCH_SECTION.KEYWORDS
                            )
                          }
                        />
                      ))}
                    </ul>
                  </div>
                  <RecommendedProducts
                    moveToProductDetail={moveToProductDetail}
                  />
                </div>
              ) : (
                <div>
                  {searchHistory && (
                    <div className="search_recent_wrap">
                      <h5>{t("recent")}</h5>
                      <ul className="search_trending search_recent">
                        {[...searchHistory].reverse().map(item => (
                          <li
                            key={item}
                            onClick={() => {
                              onSearchOptionClick(item, SEARCH_SECTION.RECENT);
                            }}
                          >
                            {item}
                            <button
                              className="cross_btn"
                              onClick={e => deleteHistory(e, item)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <h5>{t("trending")}</h5>
                  <ul className="search_trending">
                    {trending.map(item => (
                      <li
                        key={item.query.value}
                        onClick={() => {
                          onSearchOptionClick(
                            item.query.value,
                            SEARCH_SECTION.TRENDING
                          );
                        }}
                      >
                        {get(item, "query.value", "")}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Scrollbars>
          </div>
        </div>
      )}
    </div>
  );
};

enum SEARCH_SECTION {
  DIRECT = "direct",
  KEYWORDS = "keywords",
  RECOMMENDED_PRODUCTS = "recommended products",
  RECENT = "recent",
  TRENDING = "trending"
}

export default withCookies(Search);
