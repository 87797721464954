import { emailValidationRegExp } from "../../constants";

function getResult(arg) {
  return typeof arg === "function" ? arg() : arg;
}

export function getAccessToken(getState) {
  const state = getResult(getState);
  return (
    (state.authReducer && state.authReducer.accessToken) ||
    state.authReducer.accessGuestToken
  );
}

export function getGuestAccessToken(getState) {
  const state = getResult(getState);
  return state.authReducer && state.authReducer.accessGuestToken;
}
